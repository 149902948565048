.active-dashboard-wrap {
    background-color: #e7eaed;
    color: #1890ff;
    text-align: center;
    padding: 0px 20px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .dashboard-box {
    background-color: #fff;
    /* width: 250px; */
    min-height: 89px;
    box-shadow: 0px 5px 15px -4px #b7b7b7;
    padding: 10px;
  }
  
  .feedback-left-content {
    font-weight: 700;
  }
  
  .candidate-details {
    text-align: center;
    background-color: #e7eaed;
    padding: 10px;
    
  }
  
  .candidate-dropdown  > * {
    max-height: 50vh;
    overflow: scroll;
    cursor: pointer;
  }
  
  .candidate-pointer {
    cursor: pointer;
  }
  
  .screen-type {
    margin-left: 5px;
    border: 2px solid yellow;
    padding: 0px 10px;
  }
  
  .interview-type > * {
    border-radius: 10px;
  }
  
  .on-modal-loader {
    display: block;
    margin-bottom: 20px;
  }
  
  .booked-slot {
    background-color: #f6f7f8;
    border-radius: 10px;
    padding:10px;
    color: #787b83;
  }
  
  .confirm-slot {
    text-align: center;
  }
  
  .candidate-timeline {
    margin-top: 10px;
    width: 200px;
  }
  
  .view-pdf > .ant-modal-content > .ant-modal-body {
    height: 70vh;
    padding: 0px;
  }
  
  .view-pdf-iframe {
    height: 70vh;
    width: 100%;
  }
  
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .date-dropdown {
    left: -90%;
  }
  
  .available-slots {
    max-height: 20vh;
    overflow:scroll;
    overflow-x: hidden;
  }
  
  .available-slots > * {
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .text-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .candidate-modal-job-title-hover{
    background: #fff;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .candidate-modal-job-title-hover:hover{
    background: #e7eaed;
  }
  
  iframe{
    overflow:hidden;
  }
  
  .multiAgencySelect {
    max-height: 15vh;
    overflow-y: auto;
  }