.recovery{
    display: flex;
    height: 100vh;
}
.recovery>*:nth-child(1){
  flex: 1 1 60%;
}
.recovery>*:nth-child(2){
    flex: 1 1 60%;
    padding: 10%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .recovery>*:nth-child(2)>*:nth-child(2){
    background-color: #ffffff;
    outline: 1.2px solid rgb(188, 187, 187);
    padding: 2rem;
    border-radius: 5px;
  }
@media(max-width:700px){
    .recovery{
        flex-direction: column;
        padding: 0.6rem;
        background-color: #ffffff;
        height: 100%;
    }
    .recovery>*:nth-child(2){
        padding: 0;
        gap: 0rem;
      }
      .recovery>*:nth-child(2)>*:nth-child(2){
        outline: none;
        padding: 0;
        border-radius:0;
      }
    .recovery>*:nth-child(1){
     min-height: 15rem;
      }
      .recovery>*:nth-child(2)>*:nth-child(1){
        margin:1rem  auto;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 2rem;
      }
      .recovery>*:nth-child(2)>*:nth-child(1) img{
        width: 100%;
        height: 100%;
      }   
      .moveIcon{
        background-color: rgba(0, 102, 255, 0.829);
      }
}