.container{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    justify-content: center;
    gap: 0.5rem;
   
}

.container >*:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #EFEEF8;
    margin: auto;
}

.container >*:nth-child(1) img{
    width: 60%;
    height: 60%;
}

.container >*:nth-child(2){
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
}

.container >*:nth-child(3){
    text-align: center;
    font-weight: 600;
    color: #636363;
    font-size: 14px;
}

.container >*:nth-child(4){
    margin: 1rem 0;
}

.container >*:nth-child(4)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    gap:0.2rem;
    margin-bottom: 0.5rem;
}

.container >*:nth-child(4)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.container >*:nth-child(4)>*:nth-child(2){
    background-color: #FAFAFA;
    border: 1.2px solid #E4E4E4;
    box-shadow: none;
    height: 2.4rem;
}

.container >*:nth-child(5){
    border: 2px solid;
    text-align: center;
    margin: auto;
    background-color: #2580EC;
    color: #ffffff;
    width: 8rem;
    height: 2.3rem;
    font-weight: 600;
    border: 0;
}