.ant-modal-content {
  border-radius: 15px;
}

.create-modal-scroll{
  overflow: auto;
  max-height: 62vh;
  padding: 10px 20px 0 20px;
}


.create-modal-scroll::-webkit-scrollbar {
  width: 5px;
}

.create-modal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

.create-modal-scroll::-webkit-scrollbar-thumb {
  background: #fbdfdf; 
}

.create-modal-scroll::-webkit-scrollbar-thumb:hover {
  background: #fbdfdf; 
}



.form-heading-css div label{
  text-align: left;
  font: normal normal 550 11px/12px Open Sans !important;
  letter-spacing: 0px;
  color: #6F6F6F !important;
  text-transform: uppercase;
  opacity: 1;
}

.sort-popover{
  width: 250px  !important;
}

.float-right{
  float: right;
}

.sorting-list-css{
  list-style: none;
  padding : 10px 0px 0px 0px;
}

.sorting-list-css li{
  font: normal normal normal 18px/26px Open Sans;
  border-bottom: 3px solid #F8F8F8;
  padding : 6px;
}

.sorting-list-css li:hover{
  /* font-size: 20px;
  border-bottom: 3px solid #F8F8F8; */
  color: #0086ff;
}

.select-sorting-menu{
   color: #0086ff;
}

.sort-heading-css{
 text-align: left;
 font: normal normal bold 20px/27px Open Sans;
 letter-spacing: 0px;
 color: #000000;
 opacity: 1;
}

.sort-action .cancel{
   border : 1px solid #0086ff !important;
   color: #0086ff !important;
   font-weight: 550;
   font-size: 12px;
}

.sort-action .ok{
  background: #0086ff;
  color: white;
  font-size: 12px;
}

.check-icon .anticon.anticon-check-circle svg path{
  fill: #eee6e6 !important;
}

.d-none{
  display: none !important;
}
.ant-checkbox-inner.name {
position: relative;
top: 0;
left: 0;
display: block;
width: 16px;
height: 16px;
direction: ltr;
background-color: #fff;
border: 1px solid #d9d9d9;
border-radius: 50px;
border-collapse: separate;
transition: all 0.3s;}

/* .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%!important;
  border : 1px solid gray !important;
} */

.ant-list-item-meta-title{
  margin: 0px !important;
}

.user-profile-icon-sm{
  border-radius: 50%;
  /* border: 1px solid blueviolet; */
  padding: 4px;
  height: 70px;
  width: 70px;
  cursor: pointer;
}

.table-user-profile{
  width: 62%;
  text-align: center;
}

.head-icon{
  display: inline;
  padding: 4px;
  cursor: pointer;
}

.column-setting-list-scroller{
  max-height: 500px;
  overflow-y: scroll;
}

.custom-avtar div{
  border: 0px !important;
}

.custom-avtar .ant-upload.ant-upload-select.ant-upload-select-picture-card{
  border-radius: 50%;
  border: 1px solid white !important;
}

.sorting-radio-container{
  padding-top: 14px;
  padding-bottom: 9px;
  border-bottom: 1px solid #f5dada
}

.sorting-radio-container div{
  padding : 5px;
}

.candidate-modal .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}
.candidate-modal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}

.candidate-avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 5rem !important;
  height: 5rem !important;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 2px dashed #7c48d9 !important;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 50%;
}


