.container{
    display: flex;
    flex-direction: column;
    height:90vh;
    padding: 2%;
    overflow-y: auto;
}


.container::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .container::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .container::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 10px;
    height:0.5rem;
  }

body{
    background-color: #ffffff;
}
.container span,h3,h4,p{
    font-weight: 600;
    font-size: 0.9rem;
}

.header{
    width: 100%;
    display: flex;
    height: 5rem;
    background-color: #ffffff;
}

.title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.title h1{
    font-size: 1.5rem;
    font-weight: bold;

}

/* .title >*:nth-child(1)
{
    flex: 1 1 55%;
}

.title >*:nth-child(2)
{
    flex: 1 1 110%;
}
  */
.title div{
    display: flex;
    flex-direction: column; 
    /* margin-bottom: 0.1rem; */

} 

.title div div{
    display: flex;
    align-items: center;
    /* gap: 1%; */
    flex-direction: row;
    
}

.title div div span{
    text-align: right;
    
}
.title div div > *:nth-child(1){
  /* flex: 1 1 10%; */
  
}


.title div div > *:nth-child(2){
    /* flex: 1 1 20%; */
    color: #0086FF;
    font-weight: 700;
  }


.fiveItems{
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin: 0.4% 0 0 0;
}

.fiveItems >*{
    flex: 1 1 20%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
}

.fiveItems >* h2{
    font-weight: 700;
}

.scheduled{
    display: flex;
}

.sheduled h2{
    font-weight: 700;
    margin-top: 2%;
}

.sheduleDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

.sheduleDiv >*:nth-child(1)
{
    flex: 1 1 85%;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 60rem;
    gap:1.5%;
    overflow-x: auto;
    width:"90%";
    height: 23rem;
}

.sheduleDiv div{
    display: flex;
    flex-direction: row; 
    
}

.sheduleDiv div >*{
    flex: 1 1 33%; 
}

.sheduleDiv div div{
    display: flex;
    flex-direction: column;
   
}
 


.sheduleDiv >*:nth-child(2)
{
    flex: 1 1 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   font-size: 1.25rem;
   font-weight: 700;
   color: #000000;
   text-decoration: underline;
}


.feedbacks{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;   
}

.feedbacks >*:nth-child(1)
{
    flex: 1 1 85%;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 60rem;
    gap:1.5%;
    overflow-x: auto;
    width:"90%";
    height: 23rem;
}

.feedbacks div{
    display: flex;
    flex-direction: row; 
    
}

.feedbacks div >*{
    flex: 1 1 33%; 
}

.feedbacks div div{
    display: flex;
    flex-direction: column;
   
}
 


.feedbacks >*:nth-child(2)
{
    flex: 1 1 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   font-size: 1.25rem;
   font-weight: 700;
   color: #000000;
   text-decoration: underline;
}

.ant-carousel .slick-dots li button{
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: #b23434;
    font-size: 0;
    background: red !important;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}


@media(max-width:700px)
{
    .title{
        flex-direction: column;
        justify-content: left;
        /* border: 2px solid red; */
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .fiveItems{
     /* border: 2px solid red; */
     width: 90vw;
     overflow-y: auto;
     height: 8rem;
     min-height: 8rem;
     margin: 0 auto;
     gap: 1rem;
    }
    
    .fiveItems >*{
        flex: none;
       width: 15rem;
    }
 

.sheduleDiv{
    flex-direction: column;
    flex-wrap: wrap;  
    
}
.sheduleDiv>:nth-child(1){
    gap: 0.5rem;
}
.sheduleDiv>:nth-child(2){
    max-height: 3rem;
    margin-bottom: 1rem;
}

.feedbacks{
    flex-direction: column;
    flex-wrap: wrap;   
    margin-bottom: 3rem;
   
}

.feedbacks >*:nth-child(1)
{
    max-width: 100%;
    gap:1.5%;
    overflow-x: hidden;
    height: auto;
    overflow-y: auto;
}
}