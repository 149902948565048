.create-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-user > * {
  border-radius: 10px;
}
.editButton{
  color: #2b2837;
  background-color: #e7eaed;
  border: none;
  
}
.editButton:hover{
  background-color:#1890FF;
}
.editButton:focus{
  color: #2b2837;
  background-color: #e7eaed;
}
.editButton:focus:hover{
  background-color:#1890FF;
  color: white;
}