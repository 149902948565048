.container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 1rem 1.5rem 0 1.5rem;
  overflow-y: auto;
  width: 100%;
}
@media(max-width:700px){
  .container {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    overflow-y: auto;
    width: 100vw;
  }
  .container h1 {
    font-size: 0.8rem;
    font-weight: bold;
  }
}
.container h1 {
  font-size: 23px;
  font-weight: bold;
}

.tabSection {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  align-items: center;
}

.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
/* .tabSection > :nth-child(1)
{
    flex: 1 1 10%;
   
}

.tabSection > :nth-child(2)
{
    flex: 1 1 60%;
   
}
.tabSection > :nth-child(3)
{
    flex: 1 1 30%;
   
} */

.tabSection button {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}
.export{
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 0.5rem;
}

.export h2{
  margin: 0rem auto;
  font-weight: 700;
}

.export button{
  margin: 1rem auto;
  width: 6rem;
  font-weight: 600;
}

