.modalDesign>*{
    border-radius: 5px;
    background-color: #F8F8F8;
}

.modalView{
    display:flex;
    flex-direction: column;
    padding:2rem;
}

.modalView >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
}

.modalView >*:nth-child(2){
    max-height: 20vh;
    overflow-y: auto;
    margin-bottom: 1rem;
}

.modalView >*:nth-child(1) h2{
    font-weight: 700;
    color:#000000;

}


