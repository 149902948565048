/* .content{
    border:2px solid red;
} */

.container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-inline: 0.5rem;
}
.scrollbody {
  max-width: 100%;
  overflow-y: scroll;
  max-height: 73vh;
  border-radius: 0.5rem;
  margin-bottom: 4%;
}

.scrollbody::-webkit-scrollbar {
  width: 0.2rem;
}

.scrollbody::-webkit-scrollbar {
  height: 0.5rem;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
.scrollbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}

.scrollbody::-webkit-scrollbar-thumb {
  background-color: rgb(224, 220, 220);
  border-radius: 10px;
  height: 0.5rem;
}

.addProfileForm .ant-select{
  background: #fff;
  height: 2rem;
  box-shadow: 0px 1px 5px grey;
}

.scrollbody h3 {
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 2px solid rgb(124, 122, 122);
  margin-bottom: 1rem;
}

.scrollbody input {
  height: 2.3rem;
}

.scrollbody span {
  font-size: 12px;
}

.itemHeader {
  color: #161616;
  font-weight: 500;
}

.formHeader {
  display: flex;
  margin-top: 0.2rem;
  justify-content: left;
}

.formHeader h1 {
  font-weight: bold;
  font-size: 24px;
}

.jobRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.jobRow select {
  height: 1rem;
}
/* .jobRow span{
    font-size: 13px;
} */
.jobRow > * {
  flex: 1 1 50%;
  margin-top: "2px";
}

.select {
  height: 2.3rem;
  border-radius: 2px;
  border: 1.3px solid rgb(215, 211, 211);
}

.upload {
  display: flex;
  border: 1.3px solid rgb(214, 208, 208);
  border-radius: 3px;
  padding: 0.35rem;
}

.upload span {
  color: rgb(214, 208, 208);
  font-size: 1rem;
  font-weight: 400;
}

.resume {
  display: flex;
  width: 10rem;
  height: 2.45rem;
  border: 1.3px solid rgb(214, 208, 208);
  align-items: center;
  border-radius: 3px;
  margin-top: 1.4rem;
  cursor: pointer;
}

.curCom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
}

.curCom > * {
  flex: 1 1 100%;
}

.resume span {
  margin-left: 0.6rem;
  color: rgb(214, 208, 208);
  font-size: 1rem;
  font-weight: 400;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 40%;
  margin: 1rem auto;
  border: "1px solid black";
}

.buttonDiv > *:nth-child(1) {
  flex: 1 1 20%;
}

.buttonDiv > *:nth-child(2) {
  flex: 1 1 20%;
}

.buttonDiv button {
  width: 7rem;

  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.3rem;
  border: 2px solid #4daaff;
  color: #4daaff;
}

.buttonDiv .addButton {
  background-color: #4daaff;
  color: #ffffff;
}

.referedBy {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.referedBy > *:nth-child(1) {
  flex: 1 1 80%;
}
.referedBy span {
  font-size: 13px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin-top: 1.4rem;
}

.ant-select-selection-placeholder {
  width: 100%;
  /* margin-top: 0.5rem; */
  color: #7e7e7e !important;
  text-align: left;
  /* border: 1px solid black; */
}

.ant-select {
  background: #fff;
  height: 3rem;
  border-radius: 5px;
  /* box-shadow: 0px 1px 5px grey; */
}

.preferredLoc{
  height: 2rem;
  border-radius: 2px;
  border: 1.3px solid rgb(215, 211, 211);
}

.preferredLoc>*:nth-child(1) span{
  font-size: 0.88rem;
}
