.tableStyle{
    font-weight: 600;
}

.headerName{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
 align-items: center;
}

.headerName>*{
  margin: 0;
}



.headerName h1{
  font-size: 0.9rem;
  font-weight: bold;
  color: #6f6f6f;
}



.searchDiv2{
  /* outline: #d1cfcf; */
  border: 0;
  border-radius: 5px;
}
.searchDiv2 >*{
  /* background-color: hsl(0, 5%, 96%); */
}

.searchDiv2>*:nth-child(1)>*:nth-child(2){
  padding-left: 0.5rem;
  border-radius: 5px;
  max-height: 1.7rem;
} 

.addDiv{
  width: 20rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    gap:2rem;
  }
  
  .addDiv >*{
    flex:1 1 5%;
   
  }
  
  .addDiv >*:nth-child(7){
    flex:1 1 12%;
   
  }
  
  .addDiv button{
    background-color: #4daaff;
    width:10rem;
    height:3rem;
    border:0.4rem;
    color:#ffffff;
    font-size: 1rem;
  }
  
  .addDiv img{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

.table{
   font-weight: 600;
   display: flex;
   width: 100%;
    
  }
  
   /* .table::-webkit-scrollbar {
    display: none;
  } */


  .rowStyle{
    background-color: white;
   font-weight: 600;
  }
  
  .scroll{
    max-height: 685px;
    overflow-y: scroll;
  }
.scroller::-webkit-scrollbar {
    display: none;
}
.scroll::-webkit-scrollbar{
  display: none;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  width: "100%";
}
  /* .hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
} */

/* style={{overflowY:"scroll",height:"500px"}} */

.secModal{
  height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-inline: 0.5rem;
  margin-bottom: 1rem;
}

.secModal::-webkit-scrollbar {
  width: 0.3rem;
}

.secModal::-webkit-scrollbar {
  height: 0.3rem;
}
 
.secModal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}
 
.secModal::-webkit-scrollbar-thumb {
  background-color: rgb(104, 92, 92);
  border-radius: 10px;
  height:0.2rem;
}

.secDetailCard{
  display: grid;
  grid-auto-flow: row;
  gap: 0.7rem;
  padding: 1rem; 
  box-shadow: 0 2px 6px solid gray;
  outline: 1.3px solid rgb(215, 213, 213);
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 0.5rem;
}

.secDetailCard>*:nth-child(1){
  font-size: 1.2rem;
  font-weight: 700;
}

.secDetailCard>*:nth-child(2){
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1rem;
}

.secDetailCard>*:nth-child(2)>*{
  padding: 0.6rem;
  display: grid;
  grid-auto-flow: row;
  gap: 1.1rem;
justify-content: center;
align-content: center;
}

.secDetailCard>*:nth-child(2)>*>*{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.secDetailCard>*:nth-child(2)>*>*>*:nth-child(1){
  color:#BFBFBF;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.secDetailCard>*:nth-child(2)>*>*>*:nth-child(2){
  font-weight: bold;
}




