
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@500;700&display=swap');

body {
  margin: 0;
  font-family:"Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#ffffff,
}

code {
  font-family: "Open Sans";
}

h1,h2,h3,h4,h5,span,p{
  text-align: left;
}

#components-layout-demo-responsive .logo {
 
  background: rgba(255, 255, 255, 0.904);
}
.site-layout-sub-header-background {
  background: #fff;
}
.text-editor {
  width: 600px;
  
  border-radius: 2px;
}
.text-editor img{
  max-width: 100%;
}
.displayquestion img{
  max-width: 100%;
}
.displayanswer img{
  max-width: 70%;
 margin-left: 10%;
}
.ql-editor {
  min-height: 100px;
}
/* .ant-layout.ant-layout-has-sider{
  flex-direction: column !important;
}
.loader {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.ant-checkbox + span {
  
  font-size: 20px !important;
}

#root{
  height: 100%;
  display: block;
}

.page-layout{
  height: 100%;
  background: white !important;
}

.height-100{
  height: 100%!important;
} */

/* 
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar {
  height: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(23, 20, 20);
  outline: 1px solid rgb(13, 14, 15);
  border-radius: 10px;
  height:3rem;
} */

