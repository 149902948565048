.itemVal{
   list-style: none;
   display: flex;
   justify-content: left;
}

.itemVal{
    background-color: rgb(232, 235, 203);
    display: flex;
    justify-content: left;
    text-align: left;
    padding: 0.3rem 0.4rem 0.3rem 0.7rem;
    width: 10rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
}