.header{
  display: flex;
  align-items: center;
}

.header h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

  .container{
    display: flex;
    flex-direction: column;
    padding: 0% 2% 0% 2%;
  }

  .container>*:nth-child(1)
  {
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin-top:0.5rem;
    align-items: center;
    min-height: 2.7rem;
  }
  .searchDiv{
    border: 0;
    border-radius: 10px;
    height: 2.1rem;
  }
  .searchDiv >*{
    width: 18rem; 
    border-radius: 5px;
  }

  .searchDiv>*:nth-child(1)>*:nth-child(2){
    padding-left: 0.5rem;
    border-radius: 5px;
    /* max-height: 1.7rem; */
  }
  .searchDiv2{
  height: 2.2rem;
  width:18rem;
  border-radius: 4px;
  gap:0.3rem;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  /*border: 1.5px solid #e0e0e0;*/
  
  }
  
  .searchDiv2 input{
    width:30rem;
    min-height: 30px;
    border:0;
    color: #000000;
  }
  
  .searchDiv2 input:active,
  .searchDiv2 input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv2 img{
    width:20px;
    height:20px;
    margin-left: 1rem;
    opacity: 0.6;
  }
  .clearBtn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: #999;
  }
  
  .clearBtn:hover {
    color: #333;
  }
  

  .container>*:nth-child(1)>:nth-child(1){
    flex: 0 1 25%;
    display: flex;
  }

  .container>*:nth-child(1)> button{
    display: flex;
    justify-content: center;
    background-color: #0086ff;
    color: #ffffff;
    /* text-align: center;
    align-items: center; */
    font-weight: bold;
    /* height: 2.5rem; */
    border: 1px solid #0086ff;
    /* border-radius: 2px; */
    max-width: 9rem;
  }

  .container>*:nth-child(1)>:nth-child(2){
    display: flex;
    flex-direction: row;
    flex: 1 1 80%;
    gap:2%;
    align-items: center;
    justify-content: right;
    align-items: center;
    height: 2.5rem;
  }

  /* .container>*:nth-child(1)>:nth-child(4){
  } */
 
  .container>*:nth-child(1)>:nth-child(2)>*:nth-child(5){
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #0086ff;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #0086ff;
    /* border-radius: 2px;
    height: 2.2rem; */
    width: 9rem;
    /* border: 2px solid red; */
  }



  .tableStyle{
    font-size: 15px;
    font-weight: 700;
    margin-top: 1rem;
    /* overflow-x: auto;
    overflow-y: auto; */
  }


  .headerName{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  .headerName h1{
    font-size: 0.9rem;
    font-weight: bold;
    color: #6f6f6f;
  }

  .addButton{
    width: 7rem;
    height: 2.2rem;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    background-color: #0086ff;
    border: 1px solid #0086ff;
    border-radius: 2px;
  }


  .jobCard{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:1rem;
    flex: 1 1 70%;
    margin-right: 3%;
    min-width: 53vw;
    margin:0;
  }

  .jobCard>*{
    display: flex;
    flex-direction: column;
    flex: 1 1 33%;
  }

  .jobCard>*>*:nth-child(1){
    font-size: 1.1rem;
    font-weight: 690;
    text-align: center;
  }

  
  .jobCard>*>*:nth-child(2){
    max-height: 2.2rem;
    background-color: #ffffff;
    padding: 0.5rem;
    flex-grow: 1;
    border-radius: 3px;
    box-shadow: 2px 2px 5px #ACB1B75C;
    outline: 1px solid rgb(210, 209, 209);
  }

  .jobCard>*:nth-child(2)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    font-weight: 600;
    font-size: 14px;
  }

  .jobCard>*:nth-child(2)>*:nth-child(2)>*{
    min-width: 5.8rem;
   
  }

  .jobCard>*:nth-child(1)>*:nth-child(2){
    display: flex;
    color: #D3D3D3;
    font-weight: 600;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .jobCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color: #000000;
  }



  .jobCard>*:nth-child(3)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
  }

  .container>*:nth-child(1){
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .container>*:nth-child(1)>*:nth-child(2){
    display: flex;
    align-items: center;
    height: 2.6rem;
  }

  .container>*:nth-child(1)>*:nth-child(2) input{
    height: 2.5rem;
  }

  .mobJobCard{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    gap: 0.5rem;
  }
  .mobJobCard>*{
    display: flex;
    gap: 0.5rem;
  }
  .mobJobCard>*:nth-child(1){
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.8rem;
    border-bottom: 1.3px solid #CACACA;
  }
  .mobJobCard>*:nth-child(1)>:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
  }
  .mobJobCard>*:nth-child(n+2)>*{
    display: flex;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
  }
  .mobJobCard>*:nth-child(n+2)>*>:nth-child(1){
   font-size: 0.9rem;
   font-weight: 500;
   color: #808080;
  }
  .mobJobCard>*:nth-child(n+2)>*>:nth-child(2){
    font-size: 01rem;
    font-weight: 600;
   }
   .mobJobCard button{
    color: #fff;
    background-color: #1089FF;
    height: 2.4rem;
    outline: 0;
    border: 0;
    border-radius: 0.4rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-top: 0.4rem;
   }


 /* .jobFilter>*{
  flex: 1 1 49%
  display: flex;
  flex-direction: row;
  gap:0.3rem
 }

 .jobFilter>*>*
 }

 .jobFilter>*:nth-child(1)>*:nth-child(1){
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0086ff;
  margin-right: 1rem;

 } */

 

 @media(max-width:700px)
 {
  .header{
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }
  
  .header h1{
    font-size: 1.1rem;
   font-weight: 700;
    margin: 1% 0 0 2%;
  }
 }