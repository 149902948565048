.header{
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1% 0 -0.5% 2%;
}
.container{
   
    overflow-y: scroll;
    background-color: #F8F8F8;
    padding:0 2% 2% 2%;
    margin-top: 2%;
    width: 100%;
    box-sizing:border-box;
    height:80vh;
}

.container::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .container::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
    
   .container::-webkit-scrollbar-thumb {
    background-color: rgb(223, 221, 221);
    border-radius: 10px;
    height:0.5rem;
  } 
  
  .searchDiv{
    height: 2.3rem;
    width:17rem;
    border-radius: 4px;
    gap:0.3rem;
  }
  
  /* .searchDiv input{
    width:30rem;
    min-height: 30px;
    border:0;
  }
  
  .searchDiv input:active,
  .searchDiv input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv img{
    width:18px;
    height:18px;
    margin-left: 1rem;
  } */

.container >*:nth-child(2)
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    margin: 1rem 0;
}

.container >*:nth-child(2)>*:nth-child(1)
{
    flex: 1 1 15%;
}

.container >*:nth-child(2)>*:nth-child(2)
{
    flex: 1 1 15%;
}

.container >*:nth-child(2)>*:nth-last-child(1){
    display: flex;
    flex-direction: row;
    gap: 1.7rem;
}


.filter{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 500;
}

.filter>*{
    min-width: 14rem;
}

.filter>*:nth-child(1){
    min-width: 14rem;
    max-height: 250px;
    padding: 1rem;
    gap: 0.8rem;
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
    gap: 0.7rem;  
}

.filter>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}

.filter>*:nth-child(2)>*{
    flex: 1 1 50%;
    font-weight: 600;
}

.filter>*:nth-child(2)>*:nth-child(2)
{
    border: 0;
}

.export{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    gap: 0.5rem;
}

.export h2{
    margin: 0rem auto;
    font-weight: 700;
}

.export button{
    margin: 1rem auto;
    width: 6rem;
    font-weight: 600;
}