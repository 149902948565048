.duration{
    display: flex;
    flex-direction: row;  
    gap:1rem;
}

.duration > *{
    flex: 1 1 50%;
}
.assignSlot{
    display: flex;
   gap:0.5rem;
   min-height:7rem;
   max-height:10rem;
   flex-wrap: wrap;
   overflow-y: scroll;
   margin: 0.5rem auto 1rem auto;
   box-sizing:border-box;
}

.assignSlot::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .assignSlot::-webkit-scrollbar {
    height: 0.3rem;
  }
   
  .assignSlot::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .assignSlot::-webkit-scrollbar-thumb {
    background-color: rgb(153, 150, 150);
    border-radius: 10px;
    height:0.5rem;
  }

  .assignSlot div{
    min-width: 48.5%;
    box-sizing:border-box;
  }