.container{
    display: flex;
    flex-direction: column;
    margin:1.3rem;
    font-weight: 600;
    font-family: "Open Sans";
    color: #000000;
  

}

.container h1{
    display: flex;
    margin: 1rem auto;
    color: #161616;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Open Sans";
}

.container label{
    font-family: "Open Sans";
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
}

.slot{
    display: flex;
    gap:1rem;
    flex-wrap: wrap;
   
}
 .container .slot label{
    color:#6F6F6F;
    font-weight: 500;
} 
.slot > *{
    display: flex;
   min-width: 12rem;
  
    
} 

.timePicker{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: flex-end;

}

.timePicker >*{
    width: 100%;
}
.time{
    width:3rem;
}

.addButton{
    width:7rem;
    height:2.2rem;
    border:2px solid #0086FF;
    color: #ffffff;
    background-color: #0086FF;
    font-size: 1rem;
    font-family: "Open Sans";
    font-weight: 600;

}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.buttonDiv button{
    width:7rem;
    height:2.2rem;
    border:2px solid #0086FF;
    color: #0086FF;
    font-size: 1rem;
    font-weight: 600;
}



.buttonDiv .select{
    color: #ffffff;
    background-color: #0086FF;
    
}