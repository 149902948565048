.container{
   display: flex;
    max-height: 200px;
    overflow-y: scroll;
    flex-direction: column;
    margin:  1rem 0;
    font-weight: 600;
   
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: center;
    align-items: center;
}
.buttonDiv button{
    border: 2px solid #0086FF;
    color: #0086FF;
    font-weight: 700;
    border-radius: 1rem;
    width: 6rem;
}

.buttonDiv .apply{
    color:#ffffff;
    background-color:#0086FF ;
}







 .container::-webkit-scrollbar {
    width: 0.13rem;
    height: 0px;
  }
  
 
   
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(242, 240, 240, 0.3);
  }
   
  .container::-webkit-scrollbar-thumb {
    height: 56px;
    background-color: rgb(236, 230, 230);
    border-radius: 10px;
    
  }

