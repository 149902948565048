.jobCard{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:2rem;
    flex: 1 1 70%;
    margin-right: 3%;
    min-width: 35rem;
   
  }

  .jobCard>*{
    display: flex;
    flex-direction: column;
    flex: 0 0 35%;
  }

  .jobCard>*>*:nth-child(1){
    font-size: 1.1rem;
    font-weight: 690;
    text-align: center;
   
  }

  
  .jobCard>*>*:nth-child(2){
    min-height: 2rem;
    background-color: #ffffff;
    padding: 0.5rem;
    flex-grow: 1;
    border-radius: 8px;
    box-shadow: 0px 1px 5px grey;
    min-width: 5rem;
  }

  .jobCard>*:nth-child(2)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    font-weight: 600;
    font-size: 14px;
  }

  .jobCard>*:nth-child(2)>*:nth-child(2)>*{
    min-width: 5.8rem;
   
  }

  .jobCard>*:nth-child(1)>*:nth-child(2){
    display: flex;
    color: #D3D3D3;
    font-weight: 600;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .jobCard>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color: #000000;
  }



  .jobCard>*:nth-child(3)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
  }

  
  .searchDiv{
    /* outline: #d1cfcf; */
    border: 0;
    border-radius: 5px;
  }
  .searchDiv >*{
    /* background-color: hsl(0, 5%, 96%); */
    width: 18rem;
    height: 2.1rem;
  }

  .searchDiv>*:nth-child(1)>*:nth-child(2){
    padding-left: 0.5rem;
    border-radius: 5px;
    max-height: 1.7rem;
  }
