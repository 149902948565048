.feedback-form {
  margin-bottom: 5%;
  /* margin-top:5%; */
}

.feedback-left-affix {
  position: fixed;
  left:0%; 
  width: 50%; 
  text-align: center;
  padding: -10px;
  margin-top:0%;
}

.feedback-left-affix > * {
  margin: 20px;
  border: 2px solid #e7eaed;
  border-radius: 10px;
}

/* space between candidate Name and round */
.feedback-left-affix > * > * > * {
  padding: 5px 0px;
}

.feedback-left-content {
  font-weight: 700;
}

/* space between each value */
.feedback-left-content > * { 
  padding: 0px 0px;
}

.feedback-right-affix {
  position: fixed;
  right:0%; 
  width: 22.5%; 
  text-align: center;
  margin-top:3%;
}

/* space between previous round details */
.feedback-affix > * > * {
  padding: 2px 0px;
}

.feedback-affix {
  background-color: #fff;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 1% 22.5% 1% 22.5%;
  margin-bottom: 20px;
  height: 67%;
  overflow: hidden;
}

.feedback-form-item {
  border: 2px solid #e7eaed;
  margin: 2% 22.5%;
  padding: 20px;
  border-radius: 10px;
}

.feedback-SI {
  border-radius: 50%;
  background-color: #e7eaed;
  color: #1890ff;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.5em;
  text-align: center;
}

.feedback-input {
  outline: 0;
  border-bottom: 1px solid #b7b7b7;
}
.feedback-input:focus {
  border-bottom: 1px solid #1890ff;
}

.feedback-size {
  transform: scale(1.2);
  padding-left: 2%;
}

.on-page-loader {
  display: block;
  margin-top: 15%;
}

.submitted-page {
  padding-top: 10%;
  text-align: center;
}

.submitted-page > * {
  padding: 20px
}

.feedback-info {
  color: #1890ff
}
.ant-modal.Modalcontent {
  margin-left:10px;
  margin-right:10px;
  margin-top:0;
  width:90vw !important;
  height:90vh !important;
  padding-bottom: 100px !important;
} 
 .loading {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
} 
 .ant-modal.interview
{
  margin-left:10px;
  margin-right:10px;
  margin-top:0;
  width:90vw !important;
  height:90vh !important;
  padding-bottom: 100px !important;
}