.engGraph{
    display: flex;
    height: 100%;
    flex: 1 1 100%;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;

}
.engGraph>:nth-child(1){
    font-size: 1.1rem;
}
.engGraph>:nth-child(2){
    font-size: 0.8rem;
}
.graphPart{
    display: flex;
    height: 100%;
    margin-top: 0.5rem;
    position: relative;
}
.graphPart>:nth-child(1){
    flex: 1 1 25%;
}
.graphPart>:nth-child(2){
    flex: 1 1 75%;
}
.xAxis{
    display: flex;
}
.xAxis>*{
    width: 20%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.xAxis>*>*:nth-child(1){
    border-left: 1.5px solid #CACACA;
    border-left-style:dashed;
    height: calc(100% - 1.5rem);
}
.xAxis>*>*:nth-child(2)
{
    height: 1.5rem;
}
.yAxis{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: space-evenly;
    padding-bottom: 1rem;
}
.yAxis>*{
    display: flex;
}
.yAxis>*>*:nth-child(1){
    display: flex;
    width: 25%;
    color: #5E5E5E;
}
.yAxis>*>*:nth-child(2){
    width: 61%;
    display: flex;
    align-items: center;
  
}
