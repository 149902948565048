
.ant-tabs-tab-btn{
    font-size: 1rem;
    font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-size: 1rem;
    font-weight: 600;
    text-shadow: 0 0 0.25px currentcolor;   
}


.ant-table-thead{
   
    .ant-table-cell {
        
        font-weight: 500;
        background-color: #ffffff;
        height: 3rem;
        color: #6F6F6F;
         border-bottom: 1.5px solid #E4E4E4;
         border-bottom: 1.5px solid #f9f5f5;
    }

}

.ant-table-tbody{
    .ant-table-cell {
        
        font-weight: 600;
        background-color: #ffffff;
       font-size: 14px;
        border-bottom: 1.5px solid #f9f5f5;
    }

}



    .ant-table-body{
        overflow: scroll;
    }
// .ant-table-body::-webkit-scrollbar {
//     width: 0.3rem;
//   }
  
//   .ant-table-body::-webkit-scrollbar {
//     height: 0.3rem;
//   }
   
//   .ant-table-body::-webkit-scrollbar-track {
   
//     background-color: #e0e1de;
   
//   }
   
//   .ant-table-body::-webkit-scrollbar-thumb {
//     background-color: #ffffff;
//     border-radius: 10px;
//   }








  


