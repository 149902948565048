.storeGraph{
    border-radius: 1rem;
    height: 100%;
    background-color: #fff;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    /* width: max-content; */
 
}
*{
    box-sizing: border-box;
}
h2,h3,h4,h1,span{
    padding: 0;
    margin: 0;
    text-align: left;
}
.storeGraph>*{
    color: #161616;
}
.chartDesc{
    display: flex;
   gap: 2rem;
   margin-top: 1rem;
}
.chartDesc>*{
    display: flex;
    gap: 0.4rem;
    align-items: center;
}
.chartDesc>*>*:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.chartDesc>*:nth-child(2)>:nth-child(1){
    background-color: #FED330;
}
.chartDesc>*>*:nth-child(2){
    color: #5E5E5E;
    font-weight: 500;
    font-size: 0.8rem;
}
.graph{
    display: flex;
    height: 100%;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-x: auto; */
    width:100%;
    position: relative;
}
.yAxis{
    display: flex;
    gap: 0.5rem;
    min-width: max-content;
    min-width: 100%;
}
.yAxis>:nth-child(2)
{
    border-bottom: 1.5px solid #CACACA;
    border-bottom-style: dashed;
     width: 100%;
}
.xAxis{
    display: flex;
    margin-left: 8vw;
    /* gap: 5%; */
    width:auto;
    position: absolute;
    bottom: -1.5rem;
    height: calc(100% + 1.5rem);
    /* border: 2px solid red; */
    
   
  
}
.xAxis>*{

    width: 8vw;
    height: 100%;
}
.bar>*:nth-child(1){
    display: flex;
    height: calc(100% - 1.5rem);
    /* margin-left: 0.3rem; */
}
.eachBar{
    /* width:100%; */
    display: flex;
   align-items: self-end;
   margin-top: 1.1rem;
}
.eachBar>*{
    width: 1rem;
    height: 100%;
    border-radius: 1rem;
}

.bar>:nth-child(2){
    display: flex;
}
.tooltip{
    display: flex;
    flex-direction: column;
}
.tooltip>*{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
.tooltip>*>*:nth-child(1){
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
.tooltip>*>*:nth-child(1)>*:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
}
