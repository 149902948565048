.container{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    font-weight: 600;
    color: #161616;
    overflow: auto;
}


.container h1{
    margin: 0 auto 0.3rem auto;
    font-weight: bold;
}

.container>*:nth-child(2){
    height:80vh;
    margin-bottom: 1rem;
    overflow-y: auto;
    /* border: 2px solid; */
}


.container>*:nth-child(2)::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .container>*:nth-child(2)::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .container>*:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .container>*:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: rgb(223, 221, 221);
    border-radius: 10px;
    height:0.5rem;
  }


.mode{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:1rem;
}

.mode > *{
    width: 50%;
}

.picker{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:1rem;
}

.picker > *{
    width: 100%;
}

.checkSlot{
    display: flex;
    justify-content: right;
}

.checkSlot button{
    color:#1188f8  ;
    font-size: 1rem;
    font-weight: 700;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.buttonDiv button{
    width:7.2rem;
    height:2.2;
    border:1.5px solid #1188f8 ;
    border-radius: 2px;
    color: #1188f8  ;
    font-weight: 600;
}

.buttonDiv .schedule{
    color: #ffffff;
    background-color: #1188f8  ;
}