.targetModal{
    display:flex;
    flex-direction: column;
    padding:1.2rem;
}
.targetModal >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}
.targetModal >*:nth-child(2){
    max-height: 40vh;
    /* overflow-y: auto; */
    margin-bottom: 1rem;
}
.targetModal >*:nth-child(1) h2{
    font-weight: bold;
}
.header{
    font-size: 14px;
    font-weight: 700;
    color:#000000;
}

.modal>*{
    border-radius: 5px;
    background-color: #F8F8F8;
}