.container{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 0.5rem;
}

.container >*:nth-child(1){
   width: 6rem;
   height: 6rem;
    margin: auto;
}

.container >*:nth-child(1) img{
    width: 100%;
    height: 100%;
}

.container >*:nth-child(2){
   margin: auto;
    font-size: 1.4rem;
    font-weight: 700;
}

.container >*:nth-child(3){
    margin:auto;
    font-weight: 600;
    font-size: 14px;
    color: #636363;
}

.container >*:nth-child(4){
  /* margin: 0.5rem 0 0 0; */
  padding: 0;
}

.container >*:nth-child(4)>*:nth-child(2){
    display: flex;
    flex: 1 1 100%;
    margin: 0;
   
}

.container >*:nth-child(4)>*:nth-child(2)>*:nth-child(1){
    flex-grow: 1;
    border: 0;
    margin-bottom: 0;
    
}

.container >*:nth-child(4)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    gap:0.2rem;
    margin-bottom: 0.5rem; 
    
}

.container >*:nth-child(4)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.container input{
    background-color: #FAFAFA;
    border: 1.2px solid #E4E4E4;
    outline: none;
    height: 2.4rem;
    box-shadow: none;
    width: 100%;
}



.container>*:nth-child(5)>*:nth-child(1)>*>*>*>*>*{
    gap:2rem;
    display: flex;
}

.container>*:nth-child(5)>*:nth-child(1)>*>*>*>*>*>*>*:nth-child(1)>*{
    border:1.4px solid rgb(196, 191, 191);
    width: 1.2rem;
    height: 1.2rem;
}

.container >*:nth-child(6){
    margin:0 0 0.5rem 0;
}

.container >*:nth-child(6)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    gap:0.2rem;
    margin-bottom: 0.5rem;
}

.container >*:nth-child(6)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.container >*:nth-child(7){
    border: 2px solid;
    text-align: center;
    margin: auto;
    background-color: #2580EC;
    color: #ffffff;
    width: 8rem;
    height: 2.3rem;
    font-weight: 600;
    border: 0;
}




