.container{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    width: 30rem;
    height: 30rem;
}
.container img{
    width: 100%;
    height: 100%;
}