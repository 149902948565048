.container{
    display: flex;
    justify-content: left;
}

.container>*{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 15%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    padding: 1%;
}

.container>* h3{
    font-size: 1.3rem;
    font-weight: bold;
}

.container>* span{
    font-weight: 600;
    font-size: 1.3rem;
}