.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
  width: 100%;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
.container > * {
  flex: 1 1 40%;
}
.container::-webkit-scrollbar{
  display: none;
}
@media(max-width:700px){
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0.5rem 0;
    border: 2px solid;
    width: 100%;
  }
  .ListBodyContainer::-webkit-scrollbar{
    display: none;
  }
  .container > * {
  }
}
/* .container > :nth-child(5)
{
    background-color: #ffffff;
  
}

.container > :nth-child(6)
{
    background-color: #ffffff;
  
}

.container > :nth-child(4)
{
    background-color: #ffffff;
  
} */

.container h3 {
  font-weight: bold;
}

.container h4 {
  font-weight: 700;
}

.jobTitleSelect>*:nth-child(1)>*:nth-child(1){
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 10rem;
  overflow-x:auto;
}
