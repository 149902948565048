.active-dashboard-wrap {
  background-color: #e7eaed;
  color: #1890ff;
  text-align: center;
  padding: 0px 20px;
  border-radius: 10px;
  margin-left: 10px;
}

.dashboard-candidate-box {
  /* background-color: #fff;
  width: 250px;
  min-height: 89px;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 10px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feedback-left-content {
  font-weight: 700;
}

.candidate-details {
  text-align: center;
  background-color: #e7eaed;
  padding: 10px;
  
}

.candidate-dropdown  > * {
  max-height: 50vh;
  overflow: scroll;
  cursor: pointer;
}

.candidate-pointer {
  cursor: pointer;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
.screen-type {
  margin-left: 5px;
  border: 2px solid yellow;
  padding: 0px 10px;
}

.interview-type > * {
  border-radius: 10px;
}

.on-modal-loader {
  display: block;
  margin-bottom: 20px;
}

.booked-slot {
  background-color: #f6f7f8;
  border-radius: 10px;
  padding:10px;
  color: #787b83;
}

.confirm-slot {
  text-align: center;
}

.candidate-timeline {
  margin-top: 10px;
  width: 200px;
}

.view-pdf > .ant-modal-content > .ant-modal-body {
  height: 90vh;
  padding: 0px;
}

.view-pdf-iframe {
  height: 95vh;
  width: 100%;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-dropdown {
  left: -90%;
}

.available-slots {
  max-height: 20vh;
  overflow:scroll;
  overflow-x: hidden;
}

.available-slots > * {
  margin-bottom: 10px;
  margin-left: 10px;
}
.text-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.candidate-modal-job-title-hover{
  background: #fff;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.candidate-modal-job-title-hover:hover{
  background: #e7eaed;
}

iframe{
  overflow:hidden;
}

.multiAgencySelect {
  max-height: 15vh;
  overflow-y: auto;
}

.candidate-title {
  /* width: 27.5rem; */
  height: 100%;
  /* padding: 5px; */
  margin-top: 0.5rem;
  width: 100%;
  /*margin-right: 0.5rem; */
  display: flex;
  flex-direction: column;
}

.dashboard-candidate-box .ant-modal-content {
  /* border-bottom-left-radius: 1%;
  border-bottom-right-radius: 1%; */
  border-radius: 0.5%;
}



.ant-modal-content>*{
  border-radius: 7px;
  background-color: #ffffff;
}

.dashboard-candidate-box .ant-modal-header{
  border-radius: 2%;
} */

.dashboard-candidate-box  .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 20% ;
}

.dashboard-candidate-box .ant-modal-close{
  margin: 1.2rem;
}

.dashboard-candidate-box-jobtitle .ant-select-selection-placeholder{
  width: 100%;
  /* margin-top: 0.5rem; */
  color: #000000 !important;
  text-align: left;
  font-size: smaller;
}
.dashboard-candidate-box-jobtitle .ant-select{
  background: #fff;
  height: 2rem;
  box-shadow: 0px 1px 3px grey;
}

.dashboard-candidate-box .ant-select-selection-placeholder{
  width: 100%;
  margin-top: 0.2rem;
  color: #d6d8da;
  text-align: left;
  font-size: smaller;
  /* border: 1px solid black; */
}

.dashboard-candidate-box .ant-select{
  background: #fff;
  height: 2rem;
  border-radius: 2px;
  box-shadow: 0px 1px 3px grey;
}

.sendInvititaion .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}
.sendInvititaion .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.candidateViewModal .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}
/* .candidateViewModal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
} */

.addCandidate .ant-modal-body{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.addCandidate .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}

.downloadModal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.downloadModal .ant-modal-body{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 0 0 5px 5px;
}
.sendInvititaion ::-webkit-scrollbar{
  width: 0.2rem;
  height: 0.1rem;

}

.sendInvititaion ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(234, 227, 227, 0.3);
}

.sendInvititaion ::-webkit-scrollbar-thumb {
  background-color: rgb(224, 220, 220);
  border-radius: 10px;
  height:0.5rem;
}
.question2{
  /* display: flex;
  flex-direction: column;
  font-weight: 600; */
  margin: 1% 3% 3% 3%;
  padding-left: 0.2rem;
  height: 60vh;
  overflow-y: scroll;
  border: 2px solid;
}

.question2::-webkit-scrollbar {
  /* width: 0.3rem; */
  display: none;
}

.question2::-webkit-scrollbar {
  height: 0.5rem;
  display: none;
}
 
.question2::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
}
 
.question2::-webkit-scrollbar-thumb {
  /* background-color: rgb(23, 20, 20); */
  /* border-radius: 10px;
  height:0.5rem; */
}
.jdPdf{
  position: relative;
  width: 100%;
  padding-right: 4px;
  display: flex;
  align-self: center;
  background-color:#fff;
  overflow-y: scroll;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
 margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
  display: none !important;

}