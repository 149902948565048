
.modalDesign>*{
    border-radius: 5px;
    background-color: #F8F8F8;
  }

  .modalView{
    display:flex;
    flex-direction: column;
    padding: 1.2em;
  }
  
  .modalView >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .modalView >*:nth-child(1) h2{
    font-weight: bold;
  }