.tableStyle{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 15px;
}

.headerName{
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  /* align-items:center; */
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:space-between;
}

.nameMargin{
  margin-left: 0.9rem;
}

.headerName span{
  font-size: 1rem;
  font-weight: bold;
  color: #161616;
}

.searchDiv{
  display: flex;
  flex-direction: row;
  min-height: 48px;
  background-color: #ffffff;
  width:18%;
  align-items: center;
  border-radius: 4px;
  gap:0.3rem;
}

.searchDiv input{
  width:20rem;
  min-height: 48px;
  border:0;
}

.searchDiv input:active,
.searchDiv input:focus{
 outline: none;
 border:0;
}

.searchDiv img{
  width:15px;
  height:15px;
  margin-left: 1rem;
}

.table{
    font-weight: 500;
    font-size: 16px;
  }
  
   


  .rowStyle{
    background-color: white;
    font-size: 15px;
  }

  .headIcon{
    display: inline;
    padding: 4px;
    cursor: pointer;
  }

  .ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    overflow: hidden;
    outline: 0;
}

.container{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  flex-grow: 1;
}

.container >*{
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
}

.container >*:nth-child(2){
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap:0.5rem;
}

.description>*{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description>*:nth-child(1){
  margin-top: 0.3rem;
  gap:0.3rem;
  padding: 0;
}

.description>*:nth-child(1) span{
  color:#696969;
  font-weight: 500;

}

.description>*:nth-child(2){
  margin-top: 0.3rem;
  gap:0.3rem;
  padding: 0;
  color: #000000;
  font-weight: 700;
}


    .question{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin-left: 2.5%;
      /* margin-top: 1%; */
      margin-bottom: 3%;
      margin-right: 2%;
      height: 55vh;
      overflow-y: auto;
      border: 1px solid #D3D3D3;
    }
    .question::-webkit-scrollbar {
      width: 0.1rem;
      display: none;
    }
    
    .question::-webkit-scrollbar {
      max-height: 10%;
      display: none;
    }
    .question::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .question::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    .question h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .question span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     /* padding-left: 0.7rem; */
    }

    .question2{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin-left: 2.5%;
      /* margin-top: 1%; */
      margin-bottom: 3%;
      margin-right: 2%;
      height: 48vh;
      overflow-y: auto;
      border: 1px solid #D3D3D3;
    }

    .question2::-webkit-scrollbar {
      width: 0.1rem;
      display: none;
    }
    
    .question2::-webkit-scrollbar {
      max-height: 10%;
      display: none;
    }
     
    .question2::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .question2::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    
    .question2 h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .question2 span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     /* padding-left: 0.7rem; */
    }

    .questionsDiv{
      height: 56vh;
      overflow-y: scroll;
      margin-bottom: 0.7rem;
    }

    @media screen and (width < 700px){
      .question{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-left: -0.1%;
        /* margin-top: 1%; */
        margin-bottom: 0;
        margin-right: -0.1%;
        height: 55vh;
        overflow-y: auto;
        border: none;
      }
      .question2{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-left: -0.1%;
        /* margin-top: 1%; */
        margin-bottom: 0;
        margin-right: -0.1%;
        height: 50vh;
        overflow-y: auto;
        border: none;
      }
      .question h3{
        background-color:#FAFAFA;
        font-weight: 600;
        font-size: 1rem;
        padding: 0;
      }
      .question2 h3{
        background-color:#FAFAFA;
        font-weight: 600;
        font-size: 1rem;
        padding: 0;
      }
      .question span{
        font-weight: bold; 
        margin-bottom: 0.5rem;
      }
      .question2 span{
        font-weight: bold; 
        margin-bottom: 0.5rem;
      }
    } 


    .defaultQues{
      display: flex;
      flex-direction: column;
      justify-content: left;
    }

    .mobFeedCard{
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      gap: 0.8rem;
    }
    .mobFeedCard>:nth-child(n+2){
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .mobFeedCard>:nth-child(1){
      display: flex;
      flex-direction: column;
      border-bottom: 1.3px solid #CACACA;
      padding-bottom: 0.8rem;
    }
    .mobFeedCard>:nth-child(1)>:nth-child(2){
      font-size: 0.85rem;
      color: #808080;
    }
     .mobFeedCard>:nth-child(n+2)>*{
      flex:1 1 50%;
      display: flex;
      flex-direction: column;
    }
    .mobFeedCard>:nth-child(n+2)>*>*:nth-child(1){
      color: #808080;
      font-size: 0.85rem;
    }
    .mobFeedCard>:nth-child(n+2)>*>*:nth-child(2)
    {
      font-size: 1rem;
    }
    .mobFeedCard>:nth-last-child(1)>*>*>*{
      font-size: 1rem;
    }

   
  