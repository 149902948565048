.candidateTable {
  width: 100%; 
  display: flex;
  flex-direction: column;
}

.candidateTable ::-webkit-scrollbar{
    width: 0.2rem;
    height: 0.2rem;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}

.candidateTable ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }

  .candidateTable ::-webkit-scrollbar-thumb {
    background-color: rgb(224, 220, 220);
    border-radius: 10px;
    height:0.5rem;
  }