.container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-weight: bold;
}

.container h1{
    font-size: 1.3rem;
    margin:0.1rem auto;
    font-weight: bold;
    font-family: "Open Sans";
}

.container h2{
    font-size: 1.1rem;
    font-weight: bold;
    font-family: "Open Sans";
    color: #000000;
    margin:1rem auto;
}


.jdRow{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}

.jdRow > *{
    flex: 1 1 100%;
}

.buttonDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:0.3rem;

}

.buttonDiv button{
    height:2.2rem;
    width:8rem;
    border-radius: 0.1rem;
    border:1.3px solid #4DAAFF;
    color: #4DAAFF;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 1rem;
}

.buttonDiv .create{
   background-color: #4DAAFF;
   color: #ffffff;
   border:2px solid #4DAAFF;

}
