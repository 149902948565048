.title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  /* gap:1rem; */

}
.title h3{
  font-weight: 750;
  font-size: 17px;
}
/* .title span{
  margin-top: 0.3rem;
} */

.selectRow{
   display: flex;
   gap:1rem;
   flex-direction: row;
   justify-content:space-between;
   width:98%;

}

.selectDiv{
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    width:18rem;
    align-items: center;
    font-size: 14px;
    border: 0.1px solid #D9D9D9;
  }

  .jobTitle{
    font-weight:bold;
    font-size:15px;
    margin-top:-0.4rem;
    margin-left: 0.5rem;
  }

  .cardsHead{
    display:flex;
    flex-direction:row;
    gap:2rem;
    flex-wrap:nowrap;
    margin-top:0.5rem;
  }

.cards{
    display:flex;
    flex-direction:row;
    width:14.5rem;
    gap:2rem;
    margin-bottom:0.2rem;
    height:5rem;
    background-color:  #FCFCFC;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.cardDiv{
   display:flex;
   flex-direction:row;
   align-items:center;
   text-align:center;
   gap:1rem;
   flex-wrap:nowrap;

}

.gridStyle{
    width: 3.4rem;
    height:3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    background-color:#E8EBFF;
  };

  .cardSide1{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
  }

  .cardSide2{
    display: flex;
    justify-content: right;
    align-items: right;
    flex-direction: column;
    text-align: right;
  }
  .cardSide2 p{
    color: #A7A7A7;
    font-size: 11px;
  }

  .cardSide2 h2{
    font-size: 1rem;
  }

  .cardSide2 span{
    color:#161616;
    font-size: 12px;
    font-weight: bold;
    font-style: normal normal normal 12px/22px Open Sans;
  }

  .table{
    overflow: scroll;
    font-family: "Open Sans";
    font-size: 14px;
    
  }
  
  .table::-webkit-scrollbar {
    display: none;
  }

  .rowStyle{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 14px;
  
  }
  .rows{
   /* color: #CCCCCC;
   display: flex;
   flex-direction: row;
   /* gap: 10rem; */
   /* margin-left: 0.5rem;
   margin-top: 0.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    gap: 5%; 
    width: 100%;
    color: #CCCCCC;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .rows p{
    width: 13rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #CCCCCC;
  }

  .rows2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    width: 100%;
    gap: 21.5%; 
   }
   .rows2 h3{
    font-weight: bold;
   }

   .rows2>*:nth-child(1){
    display: flex;
    flex-direction: column;
    width: 10rem;
   }

   .rows2>*:nth-child(2){
    display: flex;
    flex-direction: column;
    width: 10rem;
   }

   .secondRows{
    color: #CCCCCC;
    display: flex;
    flex-direction: row;
    /* gap: 9rem; */
    justify-content: left;
    justify-content: space-between;
    width: 80%;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
   }

   .secondRows2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    width: 95%;
   }

   .scroll{
    overflow-y: auto;
    max-height: 10rem;
    padding-right: 3.5rem;
    overflow-x: none;
   }

   .scroll>*{
      padding: 0.5rem;
      border-radius: 3px;
   }
   .scroll>*:hover{
    background-color: #F8F8F8;
   }
   .scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  .scroll::-webkit-scrollbar-track {
    background: #F8F8F8;
  }
   
  .scroll::-webkit-scrollbar-thumb {
    background:#8B8B8B14; 
  }
  
  .scroll::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }

  .scroll2{
    overflow-y: auto;
    max-height: 10rem;
    padding-right: 8.3rem;
   }

   .scroll2>*{
      padding: 0.5rem;
      border-radius: 3px;
   }
   .scroll2>*:hover{
    background-color: #F8F8F8;
   }
   .scroll2::-webkit-scrollbar {
    width: 5px;
  }
  
  .scroll2::-webkit-scrollbar-track {
    background: #F8F8F8; 
  }
   
  .scroll2::-webkit-scrollbar-thumb {
    background: #8B8B8B14;
  }
  
  .scroll2::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }

  .summary{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:2.5rem;
    width:99%;
    margin-top: 1.2rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    max-height: 10.5rem;
    overflow-y: auto;
}

.summary>*{
    width: 15%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.summary>*>:nth-child(1){
    flex: 1 1 62%;
    display: flex;
    background-color: #FCFCFC;
    outline: 1.5px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.8rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
    /* border: 2px solid red; */
}

.summary>*>:nth-child(2){
    box-shadow: 0 1px 2.5px gray;
    border-radius: 50%;
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 92%;
    background-color: #ffffff;
    z-index: 1;
    /* border: 2px solid red; */
}

.summary>*>:nth-child(2) h4{
    font-size: 1rem;
    font-weight: 700;  
}

.roundSummary{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:2rem;
  width:98%;
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-bottom: 1.2rem;
}

.roundSummary>*{
  width: 19%; 
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  background-color: #FCFCFC;
  box-shadow: 0px 1px 2px grey;

}

.roundSummary>*>:nth-child(1){
  display: flex;
  outline: 1.5px solid rgb(241, 238, 238);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: -1rem;
  height: 2.3rem;
  width: 8.5rem;
  color: #161616;
  font-size: 14px;
  font-weight: 600;
}

.roundSummary>*>:nth-child(2){
  /* margin-left: 1rem; */
  font-size: 1rem;
  font-weight: 600;
}

.roundSummary>*>:nth-child(3){
  /* margin-left: 1rem; */
  font-size: 1rem;
  font-weight: 600;  
  margin-bottom: 2rem;
}

.conditionsum{
  font-size:  11px;
  margin-left: 43%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.conditionsum2{
  font-size:  11px;
  margin-left: 35%;
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

.modalDesign>*{
  border-radius: 5px;
  background-color: #F8F8F8;
}

.modalView{
  display:flex;
  flex-direction: column;
  padding: 1rem;
}

.modalView >*:nth-child(1){
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modalView >*:nth-child(1) h2{
  font-weight: bold;
}


