.container{
    
    background-color: #ffffff;
    width: 20rem;
    font-size:14px;
    font-weight: 500;
    max-height: 100vh;
}

.container>*:nth-child(1)>*:nth-child(2){
    max-height: 83vh;
    overflow-y: scroll;
}




.container>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .container>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar {
    height: 0.2rem;
  }
   
  .container>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .container>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: rgb(214, 242, 243);
    border-radius: 10px;
    height:0.2rem;
  }

  .logout{
    display: flex;
    flex-direction: row;
    justify-content: right;
    /* border: 2px solid red; */
  }

  .module{
    padding: 0.3rem 0.5rem 0.3rem 0;
    margin: 0.2rem 0;
    cursor: pointer;
    justify-content: center;
  }
  .module li{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    gap:0.5rem;
    color: rgb(106, 106, 106);
  }

