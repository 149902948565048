.attachment-link{
    padding: 5px 10px 5px 10px;
    color: #0086FF;
    background: #EEF7FF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin : 10px;
}

.email-modal .ant-modal-content{
    border-radius: 0px !important;
}

.quill-editor-menu{
    position: relative !important;
    display: flex !important;
    flex-direction: column-reverse  !important;
}

.quill-editor-menu div{
  border :0px !important;
}

.to-select-border .ant-select-selector{
    border: 0px !important;
}
.ql-editor a {
  margin-left: 10px; 
}

.subject-input-border{
    border: 0px !important;
}
.ql-custom-link {
  margin-left: 50px;
}
.custom-link {
  margin-left: 100px;
}

.vertical-align-middle{
    vertical-align: -webkit-baseline-middle;
    letter-spacing: 0px;
    color: #6F6F6F;
}

.custom-row-border{
    border-bottom: 1px solid #d0cfcf  !important;
}

.d-flow-root{
    display: flow-root;
}

.email-template-page-body{
  position: relative;
}

.email-template-page-body-scroller{
    max-height: 815px;
    overflow-y: scroll;
  }
  .email-template-page-body-scroller::-webkit-scrollbar {
    display: none;
}

.feedback-page-body-scroller{
  max-height: 780px;
  overflow-y: scroll;
}
.feedback-page-body-scroller::-webkit-scrollbar {
  display: none;
}

.card-subtitle{
 letter-spacing: 0px;
 color: #6F6F6F;
 opacity: 1;
 font: normal normal normal 14px/20px Open Sans;
}


embed::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
embed::-webkit-scrollbar-track {
    background: #f1f1f1  !important; 
  }
   
  /* Handle */
embed::-webkit-scrollbar-thumb {
    background: #fbdfdf; 
  }
  
  /* Handle on hover */
embed::-webkit-scrollbar-thumb:hover {
    background: #fbdfdf; 
  }

  embed::scrollbar {
    background: #fbdfdf; 
  }


  .d-none{
    display: none;
  }

.page-header{
  height: 90px !important;
}

.page {
  height: 100% !important;
  margin: 0px 45px 39px 22px  !important;
}

.cursor-pointer{
  cursor: pointer;
}

.custom-card .ant-card-body {
  padding: 0px !important;
}

.custom-card-footer{
  min-height: 93px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 11px;
}

.custom-card-bg{
  background: #ffe0e0;
}

.cross-css{
  height: 10px;
  width: 10px;
  vertical-align: super;
}
