.header{
    display: flex;
    border:2px solid red;
    gap:1rem;
    flex-direction: row;
    
    align-items: center;
}

.header img{
    width:60px;
    height:60px;
}

.header h1{
    font-size: 1.5rem;
    font-weight: bold;
}


.formControl{
    margin:0;
    padding: 0 0.8rem;
    height:50vh;
    overflow-y:scroll;
}


.formControl::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .formControl::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .formControl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .formControl::-webkit-scrollbar-thumb {
    background-color: rgb(215, 212, 212);
    border-radius: 10px;
    height:0.5rem;
  }
  .ListBodyContainer::-webkit-scrollbar{
    display: none;
  }

.scrollTab::-webkit-scrollbar{
  display: none;
}

.nameDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    border:2px solid red;
    gap:1rem;
}

.nameDiv span{
    font-weight: 600;
}

.edit{
    display: flex;
    flex-direction: row;
    gap:1rem;
    font-weight: 600;
}