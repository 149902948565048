.scrollItem{
    overflow-y: scroll;
    max-height: 29vh;
    margin-bottom: 4%;
  }

   .scrollItem::-webkit-scrollbar {
    width: 5px;
  }
  
  .scrollItem::-webkit-scrollbar-track {
    background: #F8F8F8; 
  }
   
  .scrollItem::-webkit-scrollbar-thumb {
    background: #8B8B8B14;
  }
  
  .scrollItem::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }