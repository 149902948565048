

.ant-modal-content {
    border-radius: 15px;
  }
  
  .college-modal-field div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 10px/14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }
  
  .sub-heading{
    text-align: left;
    font: normal normal bold 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #161616;
    opacity: 1;
    margin-left: 12px;
  }
  
  .college-modal-scroll{
    overflow-y: "scroll";
    max-height: 400px;
  }
  
  .donwload-icon-css{
    height: 25px;
    width: 25px;
  }
  
  .template-list-scroller{
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .selected_template{
    border: 2px solid blue;
  }
  
  .cursor-pointer{
    cursor: pointer;
  }
  
  .custom-card .ant-card-body {
    padding: 0px !important;
  }
  
  .custom-card-footer{
    min-height: 93px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 11px;
  }
  
  .custom-card-bg{
    background: #ffe0e0;
  }
  
  #components-dropdown-demo-arrow .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 8px;
  }