.dashboard-box {
  background-color: #fff;
  min-height: 89px;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 10px;
  margin-bottom: 1.2rem;
  /* min-width: 16.5rem; */
  border-radius: 5px;
}

.profile-modal-view {
  text-align: center;
  border-radius: 10px;
  padding: 10px 30px;
}

.profile-modal-view > * {
  padding: 10px 0px;
}

.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.avatar-uploader-edit > .ant-upload {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
