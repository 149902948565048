.searchDiv{
    width:18rem;
    height: 2.1rem;
    border-radius: 4px;
}
.searchDiv input{
    height: 1.4rem;
    border:0;
} 
.searchDiv input:active,
.searchDiv input:focus{
   outline: none;
   border:0;
}
.scrollDiv{
    /* border: 2px solid red; */
    overflow-y: scroll;
    max-height: 80vh;
}
.scrollDiv::-webkit-scrollbar {
    display: none;
  }