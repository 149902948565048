.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.container img{
    max-height: 15rem;
}

.container h1{
    font-weight: 800;
    color:#161616;
    font-size: 1.3rem;
    margin:1rem auto 1.5rem auto;
}

.container h2{
    font-weight: bold;
    color:#161616;
    font-size: 1rem;
    margin-top: 1.3rem;
}

.container span{
    font-weight: 500;
    color:#161616;
    font-size: 0.9rem;
  
}
.container p{
    color: #0086FF;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.container button{
    color:#ffffff;
   margin-top: 0.5rem;
   width: 9rem;
}

.container button:focus,
.container button:active,
.container button:hover{
    background-color: #0086FF;
    outline: none;
}

.inputDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:1rem;
    max-width: 15rem;
    margin: 1.4rem auto;
}

.inputDiv input{
  width: 3rem;
  padding-left: 1.1rem;
  color: #000000;
  font-size: 1rem;
  border: 0;
  border-bottom: 3px solid #4DAAFF;
}

.inputDiv input:focus,
.inputDiv input:active{
   outline: none;
    border: 0;
    border-bottom: 3px solid #4DAAFF;
  }

  @media (max-width: 996px){

    .inputDiv{
        gap:0.3rem;
    }

    .inputDiv input{
        width: 1.5rem;
        padding-left: 0;
        margin: 0;
        text-align: center;
        border-bottom: 1.5px solid #4DAAFF;
      }
      .inputDiv input:focus,
.inputDiv input:active{
   outline: none;
    border-bottom:1.5px solid rgb(14, 94, 168) ;
  }
      .container{
        max-height: 400px;
        overflow-y: auto;
        padding: 2rem 0 2rem 0;
        margin:1rem 0 0 0;  
      }
      .container::-webkit-scrollbar{
        display: none;
      }
      .container h1{
        font-weight: 700;
        font-size: 1.2rem;
        margin:2rem auto 1.5rem auto;
    }

    .container h2{
        font-weight: 700;
        font-size: 0.9rem;
        margin: 1.3rem 0 0.5rem 0;
        text-align: center;
    }
    
    .container span{
        font-weight: 500;
        font-size: 0.8rem;
        margin: 0;
        text-align: center;
      
    }

    .container button{
        width:8rem;
        border-radius: 2px;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0.3px;
        height: 2rem;
    }

  }
