.pageControl{
    max-height:22rem;
    overflow-y: auto;
    /* padding: 2.5rem; */
    width: 97%;
    /* height:80vh !important; */
    /* margin: 1rem auto; */
    margin: 1.5rem;
}

.pageControl::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
   
.pageControl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
.pageControl::-webkit-scrollbar-thumb {
    background-color: rgb(206, 205, 205);
    border-radius: 10px;
    height:0.2rem;
  }