.bounce {
	/* position: absolute;
	bottom: 30px;
	left: 50% ;
	width: 70%;
	height: 60px ;
	margin-left:-30px;
	border: 2px solid #990000;
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	-ms-border-radius:50%;
	border-radius:50%; */
	animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	-moz-animation: bounce 3s infinite;
	-o-animation: bounce 3s infinite;
}
 
@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-40px);}
	60% {-webkit-transform: translateY(-20px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-40px);}
	60% {-moz-transform: translateY(-20px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-40px);}
	60% {-o-transform: translateY(-20px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-40px);}
	60% {transform: translateY(-20px);}
}
.rotate90 {
    -webkit-transform: rotate(180deg);
    /* -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg); */
    transform: rotateY(180deg);
}