/* .app{
    height: 100%;
    background-color: gainsboro;
}

.app-header{
    height: 50px;
}

.app-body{
    display: flex;
    flex-wrap: nowrap;
    height: calc(100% - 40px);
}

.app-nav{
    width: 142px;
    background: #a6a6b3;
    height: 100%;
}


.app-page{
    width: calc(100% - 142px);
    background : red;
    height: 100%;
} */

#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /* background: rgba(255, 255, 255, 0.3); */
  }
  
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-nav-background {
    background: #FFFFFF;
  }

  .ant-layout-header {
    height: 50px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #F8F8F8  !important;
}

.app{
   height: 100%  !important; 
}

.app-logo{
  width: 100%;
  text-align: center;
  background-color: white;
  height: 68px;
  padding: 14px;
}



.app-logout{
  position: absolute;
  right: 9%;
  margin-top: 2.5%;
}


.siderIcon{
  width:15px;
  height:15px;
}
.jdPdf{
  position: relative;
  width: 100%;
  padding-right: 4px;
  display: flex;
  align-self: center;
  background-color:#fff;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
 margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
  display: none !important;

}