.title{
    display: flex;
    flex-direction: row;
    /* gap:1rem; */
    justify-content: space-between;
    width: 96.5%;
    margin-left: 1rem;
  }
  .title h3{
    font-weight: 750;
    font-size: 17px;
  }
  /* .title span{
    font-weight: 490;
    font-style: italic;
    color: #B7B7B7;
    font-size: 13px;
    margin-top: 0.3rem;
  } */

  .radio{
    margin-top: 0.9rem;

  }

  .radio>*>:nth-child(1){
    font-weight: 600;
  }
  .radio>*>:nth-child(2){
    font-weight: 600;
  }
  .selectDiv{
    background-color: #ffffff;
    width:18rem;
    height: 2.1rem;
    align-items: center;
    font-size: 14px;
    border: 0.1px solid #D9D9D9;
    margin-top: 0.2rem;
  }

  .jobTitle{
    font-weight:bold;
    font-size:15px;
    /* margin-top:-0.4rem; */
    margin-left: 0.5rem;
    /* margin-bottom: 2rem; */
  }

  .summary{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:2.5rem;
    /* width:95%; */
    margin-top: 1.2rem;
    /* margin-bottom: 1.5rem; */
    overflow-y: auto;
    max-height: 8rem;
}

.summary>*{
    width: 18%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2rem;
}

.summary>*>:nth-child(1){
    flex: 1 1 60%;
    display: flex;
    background-color: #FCFCFC;
    outline: 1.5px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.5rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
}

.summary>*>:nth-child(2){
    box-shadow: 0 1px 2.5px gray;
    border-radius: 50%;
    width: 3.3rem;
    height: 3.3rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 90%;
    background-color: #ffffff;
    z-index: 1;
}

.summary>*>:nth-child(2) h4{
    font-size: 1.3rem;
    font-weight: 700;  
}


.searchDiv{
    display: flex;
    flex-direction: row;
    min-height: 2.3rem;
    background-color: #ffffff;
    width:31%;
    align-items: center;
    border-radius: 4px;
    gap:0.3rem;
  }
  
  .searchDiv input{
    width:20rem;
    min-height: 2.3rem;
    border:0;
  }
  
  .searchDiv input:active,
  .searchDiv input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv img{
    width:15px;
    height:15px;
    margin-left: 1rem;
  }

.headerName{
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-size: 16px;
}

.headerName span{
  font-size: 0.9rem;
  font-weight: bold;
}

.piechart{
  display:flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  max-height: 8.5rem;
  /* border: 2px solid; */
  /* margin-right; */
}


