@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.duration{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    gap:1rem;
    margin-bottom: 4rem;
    background-color: #FFFFFF;
    /* margin-right: -60px; */
}

.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid red;
}

.duration div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-left: 20px;
}

.duration div p{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    color:#191919;
}

.duration span{
    font-size: 0.9rem;
    font-family: 'Open Sans';
    color:#636363;
    margin-top: 10px;
}

/* .buttonDiv{
    gap:3rem;
    border: 10px solid red;
} */

.buttonDiv div{
    height:2.9rem;
    width:6.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.button1{
    border:3px solid #66ff66;
    background-color:#E7FDF1 ;
}

.button2{
    border:3px solid #FF0D3B;
    background-color:#FFE7EB ;
}

.saveButton{
    background-color: #0086FF;
    width:10rem;
    height:2.5rem;
    border-radius:0.7rem;
    border:1px solid #0086FF;
    color:#ffffff;
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 800;
  }

 

  .bodyscr{
    display: flex;
    flex-direction: column;
    height: 85vh;
    overflow-y: scroll;
    background-color: #ececec;

  }

  
  .bodyscr::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  .bodyscr::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .bodyscr::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(179, 167, 167, 0.3);
  }
   
  .bodyscr::-webkit-scrollbar-thumb {
    background-color: rgb(119, 112, 112);
    border-radius: 1rem;
    height:0.5rem;
  }


  .sectionDetails{
    display: flex;
    flex-direction: row;
    gap:0.1rem;
    overflow-x: scroll;
    max-width: 97%;
    margin:1% 2.3%;
  }

  .sectionDetails::-webkit-scrollbar {
    width: 0.1rem;
  }
  
  .sectionDetails::-webkit-scrollbar {
    height: 0.2rem;
  }
   
  .sectionDetails::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .sectionDetails::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 0;
    height:0.2rem;
  }

  

  .sectionDetails button{
    min-width: 30%;
    height: 2.7rem;
    background-color: #ffffff;
    border:1px solid #0086FF;
    color: #0086FF;
    font-weight: 700;
    font-size: 1.2rem;
  }

  /* .sectionDetails button:hover{
    background-color: #4daaff;
    border:1px solid #4daaff;
    color: #FFFFFF;
  } */

  .sectionDetails .onClick{
    background-color: #0086FF;
    border:1px solid #0086FF;
    color: #FFFFFF;
  }


  .marks{
    display: flex;
    flex-direction: row;
    gap:3%;
    justify-content: space-between;
  }

  .marks >*{
    flex: 1 1 48%;
  }

  .name{
    display: flex;
    flex-direction: column;
    justify-content: left;
   margin-bottom: 1.5%;
    width: 100%;
  }

  .name >*{
    flex: 1 1 100%;
  }



  .addQuesModal>*>*:nth-child(4){
    margin: 2rem 2.5rem;
    padding: 0 1rem 1rem auto;
  }

  .addQuesModal>*>*:nth-child(4)>*{
    width: 8rem;
  }


