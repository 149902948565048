.tableStyle{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.headerName{
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  /* align-items:center;
  display:flex;
  flex-direction:row; */
  justify-content:space-between;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}

.nameMargin{
  margin-left: 0.9rem;
}

.headerName span{
  font-size: 1rem;
  font-weight: bold;
  color: #161616;
}


.table{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    /* margin-top:0.5rem; */
  }
  
   .table::-webkit-scrollbar {
    display: none;
  }


  .rowStyle{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
    border-bottom-color: #161616;
  }

  .others{
    display: flex;
    
    width: 100%;
    justify-content: center;
  }

  .others > *{
    flex: 1 1 100%;
    border: 2px solid red;
  }
  