.container{
    display: flex;
    flex-direction: column;
    margin: 2%;
}

.container h1,h2,h3,span,h4,p{
    padding: 0;
    margin:0;
}

.container span{
    color: #938c8c;
    font-size: 15px;
    font-weight: 500;
}

.container>*{
    display: flex;
    flex-direction: row;
    gap: 2%;
    margin-bottom: 1rem;
}

.container>*>*{
    flex: 1 1 50%;
}

.container>*:nth-child(6){
    display: flex;
    flex-direction: column;
   
}

.container>*:nth-child(1){
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    /* align-items: center; */
}

.container>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap:1%; 
}

.container>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color:#0086ff;
    font-size: 1.2rem;
    font-weight: bold;
}


.container>*:nth-child(1)>*:nth-child(1){
    font-size: 0.7rem;
    font-weight: 700;
    color: #000000;
    /* flex: 1 1 3%; */
}

.container >*:nth-child(5){
    display: flex;
    flex-direction: column;
}

.container >*:nth-child(5)>*:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.4rem;
    
}

.container >*:nth-child(5)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap:10%;
}

.container >*:nth-child(5)>*:nth-child(2)>*{
    display: flex;
    flex-direction: row;
}