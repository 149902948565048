
.container{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap:1rem;
}

.container>*{
    flex: 1 1 100%;
}

.container input{
    height: 2.5rem;
    margin-top: 1rem;
    border: 1.3px solid rgb(212, 210, 210);
    background-color: rgb(255, 255, 255);
}

.container>*:nth-child(1)
{
    display: flex;
    flex-direction: column;
    gap:0.8rem;
}

.container>*:nth-child(1)>*:nth-child(2)
{
    width: 7rem;
    margin-bottom: 1rem;
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    border: 1.5px solid #1890FF;
}

.container>*:nth-child(1)>*:nth-child(3)
{
    height: 10rem;
    border: 1.2px solid rgb(194, 189, 189);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: left;
    padding: 0.5rem;
    overflow-y:auto;
    font-weight: 600;
    font-size: 1rem;
    gap:0.6rem;
}

.container>*:nth-child(1)>*:nth-child(3)>*>*{
    border:0;
    width: 3rem;
    height: 1.8rem;
    font-weight: 700;
}

.container>*:nth-child(1)>*:nth-child(3)>*>*>*{
    font-weight: bold;
    color: red;
    width: 1rem;
    height: 1rem;
}

.container>*:nth-child(2) button{
    background-color:#1890FF;
    color: #ffffff;
    border: 0;
    font-weight: 600;
    font-size: 1rem;
}

.container>*:nth-child(2){
    display: flex;
    justify-content: right;
    align-items: center;
}