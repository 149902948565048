.mainHeader{
    display: flex;
    background-color: #FFFFFF;
    height: 5rem;
    align-items: center;
}

.mainHeader img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

body{
    background-color: #F8F8F8;
}

.wrapper{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1% auto;
    box-shadow: 0px 1px 7px rgb(200, 197, 197);
}

.wrapper>*:nth-child(1){
flex: 1 1 25%;
}

.wrapper>*:nth-child(1) img{
    height: 100%;
    width: 100%;
}

.wrapper>*:nth-child(2){
    flex: 1 1 45%;
    background-color: #ffffff;
    }

.container{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 710px;
    overflow-y: scroll;
    padding: 1% 2%;
}

.container::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .container::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .container::-webkit-scrollbar-thumb {
    background-color: rgb(157, 151, 151);
    border-radius: 10px;
    height:0.5rem;
  }

.container  h3{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.container >*:nth-child(1){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1%;
}

.container >*:nth-child(1) h1{
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.container >*:nth-child(1) span{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: #636363;
}

.container >*:nth-child(2){
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}


.uploadPhoto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    font-weight: 650;
}

.uploadPhoto>*>*:nth-child(1)>*:nth-child(1)>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
}

.profileImg{ 
    width: 11rem;
    height: 11rem;
    border: 2px solid #2580EC;
    border-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #EAF5FF;
}

.profileImg img{
    width: 95%;
    height: 95%;
    border-radius: 50%;
}



.uploadPhoto>*>*:nth-child(1)>*:nth-child(1)>* span{
    margin-top: -2rem;
}

.uploadPhoto>*>*:nth-child(1) button{
    display: flex;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left:30%;
    justify-content: center;
    bottom: 25%;
    border: 0;
    align-items: center;
    box-shadow: 0 1px 5px gray;
}

.uploadPhoto>*>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.attachResume{
    display: flex;
    border: 1.2px solid #9c979729;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    background-color: #EAF5FF;
}
.attachResume>*:nth-child(1){
   height: 5rem;
}

.drag{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;   
}

.attachResume span{
    text-align: center;
    font-size: 13px;
    font-weight: 680;
}

.attachResume button{
    background-color: #4DAAFF;
    color: #ffffff;
    width: 6.8rem;
    height: 1.8rem;
    border-radius: 1rem;
    border: 0;
    font-size: 13px;
    box-shadow: none;
}

.container >*:nth-child(4){
    display: flex;
    flex-direction: column;
}

.container >*:nth-child(4) span{
    font-weight: 600;
}

.container >*:nth-child(4) input {
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-shadow: none;
}

.container >*:nth-child(4)>*:nth-child(17)>*>*:nth-child(2)>*>*>*{
  
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-shadow: none;
}

.container >*:nth-child(4)>*:nth-child(16){
   display: flex;
   flex-direction: row;
   gap: 0.5rem;
   margin-bottom: 1rem;
   align-items: center;
}

.container >*:nth-child(4)>*:nth-child(17)>*:nth-child(1)>*>*{
    border: 1.5px solid #E4E4E4;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 1 4px solid gray;
}


.container >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.container >*:nth-child(4)>*:nth-child(3)>*:nth-child(2){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
}

.container >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 14px;
}

.container >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



.upload{
    display: flex;
    flex-direction: column;
}

.upload>*:nth-child(1){
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: #EFEEF8;
    display: flex;
    justify-content: center;
    align-items: center;
   z-index: 0;
   box-sizing: content-box;
   border: 1.5px solid #2580EC ;
}

.upload>*:nth-child(1) >*:nth-child(1)
{
    z-index: 0;
    margin: 3 auto;
    margin: 0 0 0 25%;
}

.upload>*:nth-child(1) button{
    z-index: 10;
    position: relative;
   right: 18%;
   top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    width: 2.2rem;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;  
}

.upload>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.upload>*:nth-child(2){
    margin-top: 5%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.upload>*:nth-child(2)>*:nth-child(1){
    font-weight: bold;
}

.upload>*:nth-child(2)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
}

.upload>*:nth-child(2)>*:nth-child(2) span{
    color:#2580EC ;
    font-weight: 700;
}

.submitBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.submitBtn button{
    background-color: #2580EC;
    font-weight:700 ;
    width: 7.5rem;
    border-radius: 1rem;
    height: 2.2rem;
    color: #ffffff;
    border: 0;
    margin: 1rem 0;
    box-shadow: none;
}

.submitBtn button:hover{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.submitBtn button:active{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.submitBtn button:focus{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.container >*:nth-child(4)>*:nth-child(6) input{
    border: none;
    height: 2.8rem;
}

.container >*:nth-child(4)>*:nth-child(6) button{
    color:#1089FF ;
    font-style: italic;
}

.container >*:nth-child(4)>*:nth-child(6) {
 margin-bottom: 0.3rem;
}

.container >*:nth-child(4)>*:nth-child(7) {
    margin-bottom: 1rem;
    color: gray;
    font-size: 14px;
    font-weight: 500;
   }

.container >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    height: 3rem;
    flex-wrap: nowrap;
    gap: 0.5rem;
    align-items: center;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-sizing: border-box;
 }


 .verified{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.5rem;
}

.verified>*:nth-child(1){
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

@media (max-width: 996px){
    .wrapper{
        width: 100%;
        box-shadow: none;
    }
    .container >*:nth-child(4)>*:nth-child(6) input{
        height: 1.8rem;
    }
    .container >*:nth-child(4) input {
        height: 2rem;
    }  
.container >*:nth-child(4)>*:nth-child(3)>*:nth-child(2){
    height: 2rem;
}
.container >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.container >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.container >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 2rem;
    gap: 0.5rem;
    box-sizing: border-box;
 }
 .container >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
}