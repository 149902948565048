.container {
    display: flex;
    flex-direction: column;
    padding: 5%;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    min-width: 15rem;
    margin:10px

}
.container a{
    text-align: left;
    margin-bottom: 2%;
}

.container h4{
    font-size: 0.7rem;
    color: #BFBFBF;
    margin-top: 1%;
}

.container button{
    background-color: #0086FF;
    color: #ffffff;
    font-weight: bold;
    border:1 px solid #0086FF;
    max-height: 2.3rem;
}

.container button:hover{
    background-color: #0086FF;
    color: #ffffff;
    border:1 px solid #0086FF;
}


