.container {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 0 1.6rem;
  height: 80vh;
}

.innerContainer {
  width: 100%;
  height: 78vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.navBar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 100%;
}

.searchDiv {
  width: 18rem;
  height: 2rem;
  justify-self: flex-end;
  margin-right: 1.5rem;
  height: 2.2rem;
}

.firstPageCard {
  background: #ffffff;
  border-radius: 10px;
  margin: 2rem;
  width: 50rem;
  height: 70vh;
  box-shadow: 0 0.5rem 1rem #00000029;
}
.firstPageCard h1 {
  margin-left: 7rem;
  margin-top: 1.5rem;
  font-weight: 900;
  font-size: 2rem;
}

/* .createFeedback .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}

.createFeedback .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
} */
.createFeedback .ant-modal-content{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
}



/* .searchDiv{
    display: flex;
    flex-direction: row;
    gap:2%;
    margin-bottom: 1.2rem;
}

.searchDiv >*:nth-child(1){
    flex: 1 1 20%;
    height: 2.3rem;
}
.searchDiv >*:nth-child(2){
    flex: 1 1 70%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    justify-content: right;

} */

.searchDiv > *:nth-child(2) button {
  width: 10rem;
  max-height: 2.5rem;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.searchDiv > *:nth-child(2) .deleteBtn {
  color: #ff9797;
  border: 1px solid #ff9797;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  color: #161616;
  margin: 1.5rem;
}

.modalContainer h1 {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.feedback-page-body-scroller {
  max-height: 815px;
  overflow-y: scroll;
}
.feedback-page-body-scroller::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
}

.header h1 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 1.7rem;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2.2rem;
  margin-bottom: 0.4rem;
  justify-content: center;
}

.feedbackScroll {
  height: 700px;
  display: flex;
}

.feedbackScroll::-webkit-scrollbar {
  display: none;
}

.buttonDiv button {
  border-radius: 0.5rem;
  width: 8rem;
  height: 2.5rem;
  border: 1.5px solid #4daaff;
  color: #4daaff;
  font-weight: bold;
  font-size: 1rem;
}

.buttonDiv .createButton {
  background-color: #4daaff;
  border: 1.5px solid #4daaff;
  color: #ffffff;
}

.creatFeedbackCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 1%;
  gap: 0.4rem;
  border-radius: 5px;
}

.creatFeedbackCard > * {
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
/* 
.creatFeedbackCard > *:nth-child(1) {
  flex: 1 1 45%;
}

.creatFeedbackCard > *:nth-child(1) img {
  width: 28%;
  height: 70%;
}
.creatFeedbackCard > *:nth-child(2) {
  flex: 1 1 17%;
  font-weight: bold;
}
.creatFeedbackCard > *:nth-child(3) {
  flex: 1 1 20%;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}
.creatFeedbackCard > *:nth-child(4) {
  flex: 1 1 18%;
} */

.creatFeedbackCard > *:nth-child(4) button {
  width: 40%;
  height: 90%;
  font-weight: bold;
}

.feedbackDetailsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* max-height: 60vh; */
  border-radius: 5px;
  padding-top: 0;
}

.feedbackDetailsCard > *:nth-child(1) {
  width: 95%;
  display: flex;
  flex-direction: column;
  height: 30vh;
  /* border:1px solid black */
}

.feedbackDetailsCard > *:nth-child(1) > *:nth-child(1) {
  /* flex: 1 1 30%; */
  display: flex;
  justify-content: right;
  padding: 1% 2% 0 0;
  width: 100%;
}
.feedbackDetailsCard > *:nth-child(1) > *:nth-child(2) {
  /* flex: 1 1 45%; */
  display: flex;
  height: 25vh;
  justify-content: center;
  align-items: center;
}

/* .feedbackDetailsCard > *:nth-child(1) > *:nth-child(2) img {
  width: 25vh;
  height: 20vh;
} */

.feedbackDetailsCard > *:nth-child(2) {
  flex: 1 1 28%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.feedbackDetailsCard > *:nth-child(2) Button {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  text-align: center;
}

.feedbackDetailsCard > *:nth-child(2) h5 {
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-between; */
  max-height: 75vh;
  flex: 1 1 100%;
  overflow-y: scroll;
  gap: 1rem;
}

.cardContainer > * {
  flex: 0 1 32%;
  /* width: 20rem; */
  margin-bottom: 0.5rem;
  height: 50vh;
}

.cardContainer::-webkit-scrollbar {
  width: 0.3rem;
}


.cardContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0);
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: rgb(234, 227, 227, 0);
  border-radius: 10px;
  height: 0.5rem;
}

@media (max-width: 996px) {
  /* .creatFeedbackCard{
          width: 213px;
           height: 213px;
      }
      
      .feedbackDetailsCard
  {
      width: 213px;
      height: 213px;   
  } */

  .creatFeedbackCard h2 {
    font-size: 1.1rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 997px) and (max-width: 1196px) {
  /* .creatFeedbackCard{
          width: 203.5px;
           height: 203.5px;
      }
      
      .feedbackDetailsCard
  {
      width: 203.5px;
      height: 203.5px;  
  } */

  .creatFeedbackCard h2 {
    font-size: 1.1rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 1196px) and (max-width: 1493px) {
  /* .creatFeedbackCard{
        width: 242.5px;
         height: 242.5px;

    }
    
    .feedbackDetailsCard
{
    width: 242.5px;
    height: 242.5px;  
} */

  .creatFeedbackCard h2 {
    font-size: 1.2rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.7rem;
    margin: 0;
  }
}

/* @media (min-width:1494px) and (max-width:1659px)
{

    .creatFeedbackCard{
        width: 275px;
         height: 275px;
         

    }
    .feedbackDetailsCard
    {
        width: 275px;
        height: 275px;
    }
} */

@media (min-width: 1660px) and (max-width: 1867px) {
  /* .creatFeedbackCard{
          width: 315px;
           height: 315px;
      }
      
      .feedbackDetailsCard
  {
      width: 315px;
      height: 315px;  
  } */

  .creatFeedbackCard h2 {
    font-size: 1.4rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}

@media (min-width: 1867px) {
  /* .creatFeedbackCard{
        width: 364.7px;
         height: 364.7px;
    }
    
    .feedbackDetailsCard
{
    width: 364.7px;
    height: 364.7px;  
} */

  .creatFeedbackCard h2 {
    font-size: 1.4rem;
  }

  .creatFeedbackCard > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .creatFeedbackCard > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .feedbackDetailsCard > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .feedbackDetailsCard > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}
