.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% auto;
    padding: 5%;
    background-color: #ffffff;
    box-shadow: 2px 2px 24px #ACB1B75C;
    border-radius: 0.8rem;
}
.wrapper>*{
    text-align: center;
}

@media (max-width:800px){
    .wrapper{
       margin: 0.5rem;
        background-color: #ffffff;
        box-shadow:none;
    }

}