.wrapper{
    display: flex;
    height: 100vh;

}

.container{
    display:flex;
    flex-direction: row;
    width: 78%;
    height: 92vh;
    margin: auto;
    border: 1.5px solid #E4E4E4;
}

.container span{
    font-weight: 700;
}

.container>*:nth-child(1){
    display: flex;
    flex:1 1 45%;
    flex-direction: column;
}

.container>*:nth-child(1) img{
    width: 100%;
    height:100%;
}

.container>*:nth-child(2){
    display: flex;
    flex:1 1 55%;
    flex-direction: column;
    padding: 2rem;
}

.uploadPhoto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
}

.uploadPhoto>*>*:nth-child(1)>*:nth-child(1)>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
}

.uploadPhoto>*>*:nth-child(1)>*:nth-child(1)>* div{
    
    width: 11rem;
    height: 11rem;
    border: 1.5px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.uploadPhoto>*>*:nth-child(1)>*:nth-child(1)>* span{
    margin-top: -2rem;
}

.uploadPhoto>*>*:nth-child(1) button{
    display: flex;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left:30%;
    justify-content: center;
    bottom: 25%;
    border: 0;
    align-items: center;
}

.uploadPhoto>*>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.mobileDiv{
    border: 1.3px solid #E4E4E4;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-inline: 1rem;
    gap: 0.5rem;
    height: 4rem;
    align-items: center;
}

.mobileDiv button{
    width: 8rem;
    height: 2.4rem;
    background-color: #2580EC;
    border:1px solid #2580EC;
    font-weight: bold;
    color:#ffffff;
}

.mobileDiv button:hover{
    background-color: #2580EC;
    border:1px solid #2580EC;
    color:#ffffff;
}

.mobileDiv input{
    height: 2.4rem;
}

.resumeUpload{
    display: flex;
    flex-direction: column;
    border: 1.5px solid #E4E4E4;
    justify-content: center;
    border-radius: 4px;
    height: 12rem;
    align-items: center;
    background-color: #FAFAFA;
    
}

.resumeUpload>*{
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    margin: 0;
   
}

.resumeUpload>*>*:nth-child(1)>*>*{
    height: 90%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    border:0;

}

.resumeUpload>*>*:nth-child(1)>*>* h4{
    font-size: 12px;
    font-weight: 700;
    margin-top: 0.5rem;
}

.resumeUpload>*>*:nth-child(1)>*>* span{
    font-size: 12px;
    font-weight: 500;
    color:#6F6F6F;
   
}

.submitButton{
    display: flex;
}
.submitButton button{
    width: 9rem;
    height: 2.5rem;
    border-radius: 1rem;
    margin: 0.5rem auto;
    background-color: #2580EC;
    color: #ffffff;
}

.submitButton button:hover {
    background-color: #2169c1;
    color: #ffffff;
}

.verified{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.verified>*:nth-child(1){
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

.note{
    color: gray;
    font-size: 14px;
    font-weight: 400;
    
}
  
