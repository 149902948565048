
.mainHeader{
    display: flex;
    background-color: #FFFFFF;
    height: 6rem;
    align-items: center;
}

.mainHeader img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

.wrapper{
    display: flex;
    max-width: 1491px;
    /* max-height: 90%; */
    margin:2% auto auto auto;
    background-color:#ffffff;
    flex-direction: column;
    border-radius: 0.8rem;

}

.form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:3rem;
}

/* .container > *{
    border:1px solid red;
    width:90%;
    margin:1rem auto;
} */

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem auto;
    margin: 2rem auto 3rem auto;
    line-height: 1;
}

.header h1{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Open Sans';
    color:#161616;
}

.header span{
    color:#6F6F6F;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: 400;
}

.eachRow{
    display: flex;
    flex-direction: row;
    width: 95%;
    gap:2rem;
    /* border: 2px solid; */
    /* padding-top: 2rem; */
}

.eachRow > *{
    flex: 1 1 50%;
}

.eachRow input {
    height:3.5rem;
}

body{
    background-color: #f8f8f8;
}

.rightCol{
    display: flex;
    flex-direction: row;
    gap:2rem;
    align-items: center;
    /* border: 2px solid; */

    /* justify-content: center; */
}



.rightCol >*:nth-child(1){
    flex:1 1 70%;
  

}

.rightCol >*:nth-child(2){
    flex:1 1 30%;
    

}
.buttonDiv{
    display: flex;
    height: 7rem;
    overflow-x: auto;
    /* overflow-y: auto; */
    width: 14rem;
    /* border: 2px solid red; */
    /* align-items: center; */
    padding-top: 2rem;
    margin-top: 1.6rem;
    
}
.buttonDiv::-webkit-scrollbar{
    /* display: none; */
    width: 0.1rem;
    height: 0.1rem;
}
.rightCol button{
    width:14rem;
    height:3rem;
    border-radius:0.5rem;
    /* background-color: #4DAAFF; */
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.nextButton button{

    width:9.5rem;
    height:3rem;
    border-radius:0.5rem;
    background-color: #4DAAFF;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Open Sans';
    margin-top: 1.8rem;

}
.dashedBorder{
    display: flex;
    flex-direction: row;
    border:2px solid #4DAAFF;
    border-style: dashed;
    align-items: center;
    gap:2rem;
    padding: 1.5rem;
    height: 9rem;
}

.dashedBorder button{
    border:none;
    outline: none;
}

.dashedBorder button:hover,
.dashedBorder button:active,
.dashedBorderbutton:focus{
    border:none;
    outline: none;

}

.container{
    display: flex;
   align-items: center;
   gap: 0.5vw;
   border: 1px solid #CACACA;
   border-radius: 0.8vh;
   padding-left: 0.5vw;
   height: 3.5rem;
}
.container:active, .container:focus{
    border: 1px solid #0086ff;
}
.code{
    display: flex;
    align-items: center;
    gap: 0.3vw;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
}

.dropdown>:nth-child(1){
    padding-left: 0.2vw;
}
.dropdown>:nth-child(1)>*{
  margin:1vh 0;
  width: 95%;
  height: 2.5rem;

}
.disabled{
    opacity: 1;
    background-color: #0000000a;
    pointer-events: none;
}

@media screen and (width>=700px) {
    .dropdown{
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
        height: 15vw;
        overflow-y: auto;
        width: 20vw;  
    } 
}
@media screen and (width<700px) {
    .dropdown{
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
        height: 20vh;
        overflow-y: auto;
        width: 55vw;  
    }
}