.overAllDashboard{
    display: flex;
    flex-direction: column;
    padding: 1.4% 2% 2% 2%;
    height: 89vh;
}

.overAllDashboard h1{
    font-weight: 700;
    font-size: 1.5rem;
}

