src/pages/college-dashboard/style.css.ant-modal-content {
    border-radius: 15px;
  }
  
  .college-modal-field div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 10px/14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  .college-modal-field{
    padding: 10px 0 0 !important;
    margin: 0px;
  }

  .college-modal-field-sm div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  /* .college-modal-field-sm div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  } */

  .college-modal-field-sm .ant-form-item-label{
    padding: 10px 0 0 !important;
  }

  .college-modal-field-sm{
    margin: 0px  !important;
  }
  
  .sub-heading{
    text-align: left;
    font: normal normal bold 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #161616;
    opacity: 1;
    margin-left: 12px;
  }
  
  .college-modal-scroll{
    overflow: auto;
    min-height: 500px;
  }
  
  /* ::-webkit-scrollbar {
    width: 5px;
    scrollbar-width : 5px !important;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #fbdfdf; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #fbdfdf; 
  } */

  div.horizotal-scroll-container::-webkit-scrollbar{
    width: 5px !important;
    scrollbar-width : 10px !important;
    height: 8px !important;
  }

  div.horizotal-scroll-container::-webkit-scrollbar-thumb{
    background: black !important; 
  }



  div.horizotal-scroll-container {
    background-color: white !important;
    overflow: auto !important; 
    white-space: nowrap  !important;
    display: flex;
  }
    
  /* div.scrollmenu a:hover {
    background-color: #777;
  } */

  .number-container-css{
    padding: 6px;
    font-size: 9px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px;
  }

  .number-container-lable-css{
    height: 12px;
    width: 12px;
    display: inline-block;
    text-align: center;
  }

  .active-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid black;
    margin: 7px;
    border-radius: 50%;
  }


  .inactive-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid #acacb7;
    margin: 7px;
    border-radius: 50%;
  }



  .other-label-container-css{
    padding: 2px 10px 2px 10px;
    font-size: 13px;
    border-radius: 9px;
    border: 2px solid #dbb5b5;
    display: inline-block;
    margin: 10px;
  }

  #components-page-header-demo-content .image {
    display: flex;
    align-items: center;
    margin: 0 0 0 60px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .image {
    margin: 0 60px 0 0;
  }
  
  #components-page-header-demo-content .example-link {
    margin-right: 16px;
    line-height: 24px;
  }
  [data-theme='compact'] #components-page-header-demo-content .example-link {
    line-height: 20px;
  }
  #components-page-header-demo-content .example-link-icon {
    margin-right: 8px;
  }
  
  [data-theme='compact'] #components-page-header-demo-content .example-link-icon {
    width: 20px;
    height: 20px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .example-link {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  #components-page-header-demo-content .ant-page-header-rtl .example-link-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  
  @media (max-width: 768px) {
    #components-page-header-demo-content .image {
      flex: 100%;
      margin: 24px 0 0;
    }
  }


  .page-header{
    height: 140px;
  }

  .page-body{
    height: 100%;
    background: white !important;
  }

  .page{
    height: 100% !important;
    margin: 35px 46px 50px 10px;
  }

  .pdf-logo{
    height: 30px;
    width: 30px;
  }



  /* .ant-form-item .ant-upload.ant-upload-drag{
    background: #fafafa;
    width: fit-content;
  } */

  .file-upload-dragger span{
    padding: 5px !important ;
    margin-left: 25px  !important;
  }

  .file-upload div.ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content{
    display: block !important;
    flex: unset !important;
  }


  .pdf-icon-container{
    cursor: pointer;
    background: #E6F3FF;
    padding: 12px;
    border-radius: 13px;
  } 

  .pdf-icon-container div span{
    cursor: pointer;
  } 

  .text-center{
    text-align: center;
  }

  .text-align-end{
    text-align: end;
  }

  .ant-upload.ant-upload-select-picture-card{
    width: 135px !important;
    height: 157px !important;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 2px dashed #7c48d9 !important;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }

  .anticon-plus-circle svg{
    height: 30px !important;
    width: 30px !important;
  }

  .custom-search-icon svg{
    height: 20px;
    width: 20px;
    color: #9b9be5;
  }

  .tab{
    padding: 0px 10px 10px 10px;
    font: normal normal bold 18px/26px Open Sans;
    display: inline-block  !important;
    color : #6f6f6f;
    cursor: pointer;
  }

  .active{
    border-bottom : 2px solid #0086ff  !important;
    color : #0086ff !important;
  }

  .jd-custom-modal .ant-modal-content{
    border-radius: 4px;
    height:700px
  }
  .jdPdf{
    position: relative;
    width: 100%;
    padding-right: 4px;
    display: flex;
    align-self: center;
    background-color:#fff;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
   margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
    display: none !important;

}