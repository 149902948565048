.container{
    display: flex;
    flex-direction: column;
    margin:2rem auto;
    width: 60%;
}

body{
    background-color: #FFFFFF;
}



.mainHeader{
    display: flex;
    background-color: #FFFFFF;
    height: 6rem;
    align-items: center;
}

.header{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 1rem;
    margin:0 2%;
    padding: 2rem;
}

.userProfile{
    display: flex;
    flex-direction: row;
    gap:2rem;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 3px solid #e5e5e5;
    margin-bottom: 2rem;
    justify-content: space-between;
}
.userProfile div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:1rem;
}

.userProfile div div{
    display: flex;
    flex-direction: column;
    gap:0;
}

.userProfile img{
    width:69px;
    height:69px;
    border-radius: 50%;
}

.userProfile span{
    color: #6F6F6F;
    font-size: 16px;
}

.userProfile h1{
    font-size: 32px;
    font-weight: bold;
}

.basicInfo{
    display: flex;
    flex-direction: row;
    gap:1rem;
    flex-wrap: nowrap;
    justify-content: space-between;
 
}


.basicInfo h4{
    font-size: 16px;
    font-weight: 700;
}

.basicInfo span{
    font-size: 16px;
    font-weight: 500;
}

.basicInfo div div{
    display: flex;
    flex-direction: row;
    gap:0.7rem;
}

.basicInfo div div div{
    gap:0.9rem;
}

.mainHeader img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

.formControl{
    max-height:600px;
    overflow-y: auto;
    padding: 0;
    width: 97%;
    height:80vh !important;
    margin: 1rem auto;
}

.formControl::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
   
  .formControl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .formControl::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 10px;
    height:0.2rem;
  }

/* .formControl::-webkit-scrollbar{
    display: none;
} */

.wrapper{
    display: flex;
    flex-direction: column;
   
    padding: 2rem;
    border-radius: 1rem;
    background-color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
}

.wrapper input{
    height:2.5rem;
    border-radius: 0.75rem;
    border: 2px solid #e5e5e5;
}

.buttonDiv{
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    gap:1rem;
}

.buttonDiv button{
    width:9rem;
    height:3rem;
    border-radius: 0.25rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    background-color: #4DAAFF;
    border-radius: 8px;
}

.attachButton{
    width:10rem;
    height:3rem;
    border-radius: 0.75rem;
    border: 2px solid #e5e5e5;
   margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;   
}

.options{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.options div{
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
   height: 2.5rem;
    border:2px solid #e5e5e5;
    border-radius: 0.75rem;
}

.rate{
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    height: 2.5rem;
    border:2px solid #e5e5e5;
   border-radius: 0.75rem;
}


.canFeedback{
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    margin-inline: 1rem;
    gap:2rem;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 2px 2px 24px #ACB1B75C;;
  }

  .canFeedback>*:nth-child(1){
    display: flex;
    padding: 1rem;
    flex: 1 1 30%;
  }
  .canFeedback>*:nth-child(1) img{
    width: 100%;
    height: 100%;
  }
 
    

  .canFeedback>*:nth-child(2) h1{
    font-weight: bold;
    font-size: 1.5rem;
  }

  .canFeedback>*:nth-child(2) span{
    font-weight: 600;
    color: gray;
  }

  .canFeedback>*:nth-child(2)
  {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }






@media (max-width: 1000px) {
    body {
      background-color:#ffffff;
    }

    .formControl::-webkit-scrollbar {
        width: 0.2rem;
       
      }
       
      .formControl::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
      }
       
      .formControl::-webkit-scrollbar-thumb {
        background-color: rgb(199, 197, 197);
        border-radius: 10px;
        max-height: 10px;
        
      }
      .formControl{
        overflow-y:auto
      }


    .mainHeader{
        display: flex;
        background-color: #FFFFFF;
        height: 3rem;
        align-items: center;
        margin:0;
    }

    .header{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin:0 auto;
        width: 100%;
        padding:0.5rem;
        border-radius: 0;
    }
    
    .mainHeader img{
        width: 5.5rem;
        height:1.5rem;
        margin-left: 2rem;
    }

    .userProfile{
        display: flex;
        flex-direction: row;
        gap:2rem;
        align-items: center;
        padding-bottom: 0.3rem;
        border-bottom: 1.5px solid #e5e5e5;
        margin-bottom: 0.3rem;
        justify-content: space-between;
    }
    .userProfile div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap:0.5rem;
    }
    
    .userProfile div div{
        display: flex;
        flex-direction: column;
        gap:0;
        height: 3.5rem;
    }
   
    
    .userProfile h1{
        font-size: 20px;
        font-weight: 700;
        font-family: "Open Sans";
        margin: 0;
        padding: 0;
    }
    
    .userProfile img{
        width:3rem;
        height:3rem;
        border-radius: 50%;
    }
 
    
    .userProfile span{
        color: #6F6F6F;
        font-size: 12px;
    }

    .progess{
        width: 40px;
        height: 40px;
        margin-right: 3rem;
    }

    .basicInfo{
        gap:0;
        display: flex;
        padding: 0.5rem;
        overflow-x: scroll;
        flex-direction: column;
        max-width: 95%;
       margin: 0 0 0 0.5rem;
    }
  
    .basicInfo h4{
        font-size: 14px;
        font-weight: 700;
        color: #000000;
    }

    .basicInfo span{
        font-size: 14px;
    }
    .selectIcon{
        width:0.9rem;
        height:0.9rem;
        margin: 0 auto;
    }

    .canFeedback{
        margin-inline:0.5rem;
        gap: 1rem;
    }

    .canFeedback>*:nth-child(2) h1{
        font-size: 1.2rem;
        font-weight: 700;
    }

    .canFeedback>*:nth-child(2) span{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .container{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin:0.5rem auto;
        font-size: 14px;
        font-weight: 500;
        color: #000000;  
    }

    .options div{
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
       height: 2rem;
        border:1px solid #e5e5e5;
        border-radius: 0.5rem;
    }

    .rate{
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        height: 2rem;
        border:1px solid #e5e5e5;
        border-radius: 0.5rem;
    }

    .attachButton{
        width:8rem;
        height:2rem;
        border-radius: 0.25rem;
       margin-top: 1rem;
        font-size: 14px;
        font-weight: 400;   
        margin-bottom: 1rem;
    }

    .wrapper input{
        height:2rem;
        border-radius: 0.5rem;
    }

    .buttonDiv button{
        width:6rem;
        height:2.1rem;
        border-radius: 1rem;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        background-color: #4DAAFF;
    }

    .wrapper{
        display: flex;
        flex-direction: column;
        margin:0;
        padding: 0 0.5rem;
        border-radius: 0;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
    }
  }
