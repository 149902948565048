.head-icon{
    display: inline;
    padding: 4px;
    cursor: pointer;
  }

  .ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    overflow: hidden;
    outline: 0;
}

.description{
  font-weight: 600;
  display: flex;
  flex-direction: column;
}
.description h4{
  color:#696969;
  font-weight: 600;
  margin-bottom: 0.37rem;
}

.description span{
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.4rem;
}
    .question{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin: 3%;
      height: 51vh;
      /* margin-bottom: 3%; */
      overflow-y: scroll;
      border: 1px solid #D3D3D3;
    }

    .question::-webkit-scrollbar {
      /* width: 0.3rem; */
      width: 0.1rem;
      display: none;
    }
    
    .question::-webkit-scrollbar {
      height: 0.5rem;
      width: 0.1rem;
      display: none;
    }
     
    .question::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
      width: 0.1rem;
    }
     
    .question::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    .question h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .question span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     padding-left: 0.7rem;
    }
    .question h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
    }

    /* .question span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
    } */

    .questionsDiv{
      height: 51vh;
      overflow-y: scroll;
      margin-bottom: 0.7rem;
    }

    .question2{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin: 1% 3% 3% 3%;
      height: 40vh;
      overflow-y: scroll;
      border: 1px solid #D3D3D3;
    }

    .question2::-webkit-scrollbar {
      /* width: 0.3rem; */
      display: none;
    }
    
    .question2::-webkit-scrollbar {
      height: 0.5rem;
      display: none;
    }
     
    .question2::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .question2::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }

    .question2 h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
    }

    .question2 span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
    }