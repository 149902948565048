.firstRow{
    display: flex;
    flex-direction: row;
    gap:1rem;
    margin-top: 1rem;

}

.firstRow >*{
    flex:1 1 100%;
}

