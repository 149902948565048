.scrollbody {
    max-width: 100%;
    overflow-y: scroll;
    max-height: 65.5vh;
    border-radius: 0.5rem;
    margin-top: -0.4rem;
  }
  
  .scrollbody::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .scrollbody::-webkit-scrollbar {
    height: 0.5rem;
  }
  
  .scrollbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
  
  .scrollbody::-webkit-scrollbar-thumb {
    background-color: rgb(224, 220, 220);
    border-radius: 10px;
    height: 0.5rem;
  }

  .scrollbody h3 {
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 2px solid rgb(124, 122, 122);
    /* margin-bottom: 1rem; */
  }
  
  .scrollbody input {
    height: 2.3rem;
  }
  
  .scrollbody span {
    font-size: 12px;
  }