.canProfile{
    display: flex;
    flex-direction: column;
    height: 80vh;
    gap: 3%;
}

.canProfile>*:nth-child(2){
    padding: 0 2% 0 3%;
}

.canProfile>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 2rem;
  
}

.canProfile>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    padding: 1% 2% 4% 2%;
    gap: 0.7rem;
    background-color: #ffffff;
    border-radius: 5px;
}



.canProfile>*:nth-child(2)>*:nth-child(1) img{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.3rem;
    /* border: 2px solid; */
    /* border-radius: 50%; */
}



.canProfile>*:nth-child(2)>*:nth-child(1) h4{
    font-size: 13px;
    font-weight: 620;
}

.canProfile>*:nth-child(2)>*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:1.2rem;
}

.canProfile>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(2){
    display: flex;
    justify-content: left;
    flex-direction: column;
    gap: 0.2rem;
}

.canProfile>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(2) span{
    font-weight: 640;
}

.canProfile>*:nth-child(2)>*:nth-child(1)>*:nth-child(8) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 3% auto;
}

.canProfile>*:nth-child(2)>*:nth-child(1)>*:nth-child(8) button{
    width: 7rem;
    font-weight: 700;
    border-radius: 3px;
    background-color: #0086FF;
    border: none;
    color: #ffffff;
    letter-spacing: 0.4px;
}

.canProfile>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 63%;
    padding:1% 3% 1% 3%;
    /* background-color: #ffffff; */
    border-radius: 5px;
    /* box-shadow: 0 1px 5px gray; */
    gap: 2%;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0 3% 0;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1) h3{
    font-weight: 800;
    font-size: 1.4rem;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    flex: 1 1 55%;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2){
    flex: 1 1 36%;
    display: flex;
    flex-direction: row;
   background-color: #EAF5FF;
   padding-left: 1%;
   gap: 0.5rem;
   align-items: center;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 52%;
    font-size: 1.1rem;
    font-weight: 700;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 48%;
    width: 100%;
    background-color: #EAF5FF;
    font-weight: bold;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(2)>*>*{
    font-weight: 600;
    font-size: 1rem;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1){
    background-color: #EAF5FF;
    padding: 0.5rem ;
    display: flex;
    gap: 1rem;
    font-size: 1rem;
    border-radius: 2px;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1)>*:nth-child(1){
    color:rgb(91, 91, 244);
    display: flex;
    gap:0.5rem;
    align-items: center;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1) span{
    font-weight: 700;
}

.canProfile>*:nth-child(2)>*:nth-child(2)>*:nth-child(3) button{
    font-weight: 700;
    border-radius: 3px;
    background-color: #0086FF;
    border: none;
    color: #ffffff;
    letter-spacing: 0.4px;
}





.hackathonContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.hackathonTable{
    display: flex;
    flex-direction: row;
    width: 100%;
    border:1px solid #D3D3D3;
    flex-direction: column;
    font-family: "Open Sans";
    font-weight: 400;
    font-size:1rem ;
}

.round{
    display: flex;
    width: 99%;
    height: 16.1rem;
     overflow-y: scroll;
    flex-wrap: wrap;
    margin: 1rem auto;
   gap:1rem;
  
}

.round::-webkit-scrollbar{
    display: none;
}



  .content{
    display: flex;
    flex-direction: column;
    gap: 5%;
    padding: 1rem;
    border-radius: 0.2rem;
    background-color: #F2F7F2;
    width: 48%;
}

.content>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.content>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap:4%;
}

.content>*:nth-child(2){
    display: flex;
    flex-direction: column;
}

.content>*:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content>*:nth-child(3)>*{
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.conBack{
    background-color: #FAFAFA;
}

.content span{
    color: #6F6F6F;
    font-size: 14px;
    font-weight: 620;
}

.content p{
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
}

.hackathonButton{
    display: flex;
    flex-direction: row;
    gap :1.5rem;
    margin-top: 2rem;   
}

.hackathonButton button{
    height:3rem;
    width:10rem;
    border-radius: 0.5rem;
    border: 2px solid #D3D3D3;
    font-size: 1rem;
    font-weight: 600;
}

.hackathonButton .addScore{
    background-color: #4DAAFF;
    border: 2px solid #4DAAFF;
    color: #ffffff;
}

.changeStatus{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.4rem;
}

.scheduleButton{
    display: flex;
    flex-direction: row;
    gap :1rem; 
}

.scheduleButton button{
    height:2.3rem;
    width:7rem;
    border-radius: 0.2rem;
    border: 1.3px solid #D3D3D3;
    background-color: #D3D3D3;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Open Sans";

}

.upload{
    width:7rem;
    height:7rem;
    border-radius: 50%;
}

.editProfileImg{
    border: 2px solid red;
}

