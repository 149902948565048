.mainHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4% 0  2%; 
    background-color: #ffffff;
    height: 9vh;
}

.mainHeader>*:nth-child(1){
    flex: 1 1 70%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.mainHeader>*:nth-child(2){
    flex: 1 1 30%;
     display: flex;
     justify-content: right;
     align-items: center;
 }

.mainHeader h1{
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
}