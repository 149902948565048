.container{
    display: flex;
    flex-direction: column;
    color: #161616;
    justify-content: left;
    margin-right: 2%;
}

.spinStyle{
  margin: 15% auto;
}

.container h1{
    font-size: 2rem;
    font-weight: bold;
    margin:1.3% 0 1% 0;
    padding-left: 2.2%;
}

.header{
  display: flex;
  align-items: center;
}

.header h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

.searchDiv{
  display: flex;
  flex-direction: row;
  gap:1%;
  margin-bottom: 1%;
  height: 2.2rem;
  flex: 1 1 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}
/* .searchDiv >*:nth-child(1){
  border: 2px solid red;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 40rem;
}
.searchDiv >*:nth-child(1) input{
  width: 10rem;
} */

.searchDiv >*:nth-child(2)
{
  display: flex;
  flex-direction: row;
  gap: 2%;
  justify-content: right;
}
/* .searchDiv >*:nth-child(3)
{
  flex: 1 1 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
} */
/* .searchDiv >*:nth-child(3) img{
  width: 1.3rem;
  height: 1.3rem;
} */

.headerName{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  .headerName h1{
    font-size: 1rem;
    font-weight: bold;
    color: #6f6f6f;
  }

  .tableStyle{
    font-weight: 600;
    /* margin-top: 0.1rem; */
  }
  
