.primaryButton {
  border-radius: 10px;
}

.secondaryButton {
  border-radius: 10px;
  background-color: #f6f7f8;
}

.modalButton {
  font-size: 1em;
  padding-left: 40px;
  padding-right: 40px;
}

.modal-popup {
  text-align: center;
  border-radius: 10px;
  margin-top: 3%;
}

.editIcon {
  color: #2b2837;
  background-color: #e7eaed;
  border: none;
}

.deleteIcon {
  background-color: #e7eaed;
  color: #F64A3F;
  border: none;
}

.int-range-divider {
  padding-left: 5px;
  padding-right: 5px;
  border-left: 0;
  border-right: 0;
  pointer-events: none;
}

.modal-scroll {
  max-height: 35vh;
  margin-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

.form-item {
  margin-bottom: 12px;
}

.no-data {
  margin-top: 100px;
  color: black;
}

.no-data-loader {
  margin-top: 300px
}

/* common add and search part */
.panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.panel > * {
  border-radius: 10px;
  margin-left: 10px;
}

.search-bar {
  margin-right: 20px;
  size: large;
  background-color: #f6f7f8;
  width:300px;
}

.search-bar > .ant-input-wrapper > .ant-input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-input-group-addon > * {
  border-radius: 10px;
}

.data-row {
  font-size: 16px;
  color: #787b83;
}

/* modification in ant css */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
  padding-left: 20px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0px;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-radio-button-wrapper {
  margin-right: 10px;
  /* border-radius: 10px; */
}

.ant-page-header {
  padding: 0;
  padding-bottom: 10px;
}

.ant-page-header-heading-title {
  font-size: 24px;
}

.ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-table-thead {
  font-size: 16px;
}

.ant-modal-header {
  border: none;
}

.ant-modal-title {
  color: #1890ff;
}

.ant-modal-body {
  padding: 12px 24px 6px 24px;
}

.ant-modal-footer {
  padding: 0px 16px 10px 0px;
  border: none;
}

.ant-modal-confirm-body > * {
    padding: 5px 0px;
}

.ant-modal-title {
  font-size: 18px;
}

.ant-layout-header {
  padding: 0px 40px;
}

.ant-tabs-tabpane {
  text-align: center;
}

.ant-layout-content {
  text-align: center;
}

.ant-timeline {
  margin-bottom: -30px;
}

.ant-timeline-item {
  margin-bottom: 10px;
}

.ant-table-tbody > tr > td{
  border-bottom: 0px;
}

