.container{
    display: flex;
    flex-direction: column;
    margin: 2rem;
}

.section{
    display: flex;
    flex-direction: row;
    gap:1rem;
}
.formItem{
    width:50%;
}
.section input{
    height:3rem;
    width: 100%;
}
.section button{
    height:3rem;
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 0.8rem;
    color:#969191;
}

.section label{
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
}
.addScore{
    display: flex;
    flex-direction: row;
    gap :1.5rem;
    margin: 2rem auto;
    justify-content: center;
    
    
}
.addScore button{
    height:3rem;
    width:10rem;
    border-radius: 0.5rem;
    border: 2px solid #4DAAFF;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Open Sans";

}

 .add{
    background-color: #4DAAFF;
    border: 2px solid #4DAAFF;
    color: #ffffff;
}