.mainpage{
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    gap: 1rem;
    /* border: 2px solid red; */
}

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header>*:nth-child(1){
   display: flex;
   flex-direction: column;
}
.header>*:nth-child(1)>*:nth-child(1){
    font-size: 1.3rem;
    font-weight: 700;
}

.store{
    display: flex;
    gap: 1.5rem;
}
.store>*:nth-child(1){
    flex: 1 1 20%;
}
.store>*:nth-child(2){
    flex: 1 1 40%;
}
.store>*:nth-child(3){
    flex: 1 1 40%;
}
.store>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}
.store>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.store>*:nth-child(1)>*>*:nth-child(1){
    display: flex;
    gap: 0.4rem;
    align-items: center;
}
.store>*:nth-child(1)>*>*:nth-child(1)>:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.store>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    background-color: #FED330;
}
.store>*:nth-child(1)>*>*:nth-child(1)>:nth-child(2){
    font-size: 1.5rem;
    font-weight: 700;
}
.store>*:nth-child(1)>*>*:nth-child(2){
   font-weight: 700;
}
.store>*:nth-child(1)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .store>*:nth-child(1)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .store>*:nth-child(1)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }

 .playStore{
    display: flex;
    /* border: 2px solid red; */
    overflow-x: auto;
    border-radius: 0.5rem;
 }
 .appStore{
    display: flex;
    /* border: 2px solid red; */
    overflow-x: auto;
    border-radius: 0.5rem;
 }


 .users{
    display: flex;
    gap: 1.5rem;
}
.users>*:nth-child(1){
    flex: 1 1 40%;
}
.users>*:nth-child(2){
    flex: 1 1 20%;
}
.users>*:nth-child(3){
    flex: 1 1 40%;
}
.users>*:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}
.users>*:nth-child(2)>*{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.users>*:nth-child(2)>*>*:nth-child(1){
    display: flex;
    gap: 0.4rem;
    align-items: center;
}
.users>*:nth-child(2)>*>*:nth-child(1)>:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.users>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    background-color: #FED330;
}
.users>*:nth-child(2)>*>*:nth-child(1)>:nth-child(2){
    font-size: 1.5rem;
    font-weight: 700;
}
.users>*:nth-child(2)>*>*:nth-child(2){
   font-weight: 700;
}
.users>*:nth-child(2)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .users>*:nth-child(2)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .users>*:nth-child(2)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }


 
 .overview{
    display: flex;
    gap: 1.5rem;
}
.overview>*:nth-child(1){
    flex: 1 1 20%;
}
.overview>*:nth-child(2){
    flex: 1 1 40%;
}
.overview>*:nth-child(3){
    flex: 1 1 40%;
}
.overview>*:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}
.overview>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.overview>*:nth-child(1)>*>*:nth-child(1){
    display: flex;
    gap: 0.4rem;
    align-items: self-end;
}
.overview>*:nth-child(1)>*>*:nth-child(1)>:nth-child(1){
   font-size: 1.5rem;
   font-weight: 700;
}

.overview>*:nth-child(1)>*>*:nth-child(1)>:nth-child(2){
    font-size: 0.8rem;
    font-weight: 500;
}
.overview>*:nth-child(1)>*>*:nth-child(2){
   font-weight: 700;
}
.overview>*:nth-child(1)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .overview>*:nth-child(1)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .overview>*:nth-child(1)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }