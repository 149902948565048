.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5%;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    min-width: 15rem;
    
}

.container h4{
    font-size: 0.7rem;
    color: #BFBFBF;
    margin-top: 1%;
}

.container a{
    text-align: left;
}