.header{
  display: flex;
  align-items: center;
}

.header h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

.container{
  display: flex;
  flex-direction: column;
  height: 82vh;
  gap: 3%;
  padding: 0.7% 2% 2% 2%;
}

.container > *:nth-child(1)
{
  flex:1 1 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 5%;
  gap: 64%;
}

.container > *:nth-child(1) >*:nth-child(1)
{
  flex: 1 1 26%;
  height: 2.1rem;
  width: 18.5rem;
}

.container > *:nth-child(1) >*:nth-child(2)
{
  flex: 1 1 12%;
  height: 100%;
  display: flex;
}

.container > *:nth-child(1) >*:nth-child(2) button{
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 4px;
}

.container > *:nth-child(2)
{
  flex:1 1 94%;
  display: grid;
  grid-gap: 1.1rem;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: 20rem;
  overflow: auto;
  padding: 0.1rem;
}

.container > *:nth-child(2)::-webkit-scrollbar {
  width: 0.3rem;
}

.container > *:nth-child(2)::-webkit-scrollbar {
  height: 0.3rem;
}
 
.container > *:nth-child(2)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}
 
.container > *:nth-child(2)::-webkit-scrollbar-thumb {
  background-color: rgb(174, 170, 170);
  border-radius: 10px;
  height:0.3rem;
}

.container > *:nth-child(2)>*{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;  
   box-shadow: 2px 2px 24px #ACB1B75C;
   outline: 1px solid rgba(227, 224, 224, 0.2);
  padding: 1%;
  cursor: pointer;
  border-radius: 5px;
  min-width: 20rem;
  border: 0;
}

.container > *:nth-child(2)>*:hover{
  background-color: rgb(247, 245, 245);
}

.createCard > *:nth-child(1) img{
 margin: 2.1rem auto 1rem auto;
}
.createCard > *:nth-child(2) h3{
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  margin: 0.5rem auto;
  
}

.createCard > *:nth-child(2) span{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.createCard > *:nth-child(3){
 font-weight: 800;
 margin-top: 1rem ;
}

.createCard > *:nth-child(3) button{
  width: 7rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
  height: 2.3rem;
 }


.spinStyle{
  margin: 20% auto;
}

.deleteModal{
  display: flex;
  flex-direction: column;
  margin: 3%;
  justify-content: center;
  gap: 10%;
}

.deleteModal >*:nth-child(1){
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3%;
}

.deleteModal >*:nth-child(2){
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  width: 50%;
}

.deleteModal >*:nth-child(2) button{
  width: 100%;
  font-weight: 700;
}

.deleteModal >*:nth-child(2)>*:nth-child(2){
  border: 1.5px solid red;
  color: red;
}