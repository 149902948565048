@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

body {
  margin: 0;
  font-family:"Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#ffffff,
}

code {
  font-family: "Open Sans";
}

h1,h2,h3,h4,h5,span,p{
  text-align: left;
}

#components-layout-demo-responsive .logo {
 
  background: rgba(255, 255, 255, 0.904);
}
.site-layout-sub-header-background {
  background: #fff;
}
.text-editor {
  width: 600px;
  
  border-radius: 2px;
}
.text-editor img{
  max-width: 100%;
}
.displayquestion img{
  max-width: 100%;
}
.displayanswer img{
  max-width: 70%;
 margin-left: 10%;
}
.ql-editor {
  min-height: 100px;
}
/* .ant-layout.ant-layout-has-sider{
  flex-direction: column !important;
}
.loader {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.ant-checkbox + span {
  
  font-size: 20px !important;
}

#root{
  height: 100%;
  display: block;
}

.page-layout{
  height: 100%;
  background: white !important;
}

.height-100{
  height: 100%!important;
} */

/* 
body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar {
  height: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(23, 20, 20);
  outline: 1px solid rgb(13, 14, 15);
  border-radius: 10px;
  height:3rem;
} */



.candidateInfo_mainHeader__1PWW3{
    display: flex;
    background-color: #FFFFFF;
    height: 6rem;
    align-items: center;
}

.candidateInfo_mainHeader__1PWW3 img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

.candidateInfo_wrapper__2In3G{
    display: flex;
    max-width: 1491px;
    /* max-height: 90%; */
    margin:2% auto auto auto;
    background-color:#ffffff;
    flex-direction: column;
    border-radius: 0.8rem;

}

.candidateInfo_form__10m4Z{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap:3rem;
    gap:3rem;
}

/* .container > *{
    border:1px solid red;
    width:90%;
    margin:1rem auto;
} */

.candidateInfo_header__ArPrN{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem auto;
    margin: 2rem auto 3rem auto;
    line-height: 1;
}

.candidateInfo_header__ArPrN h1{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Open Sans';
    color:#161616;
}

.candidateInfo_header__ArPrN span{
    color:#6F6F6F;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: 400;
}

.candidateInfo_eachRow__dr0im{
    display: flex;
    flex-direction: row;
    width: 95%;
    grid-gap:2rem;
    gap:2rem;
    /* border: 2px solid; */
    /* padding-top: 2rem; */
}

.candidateInfo_eachRow__dr0im > *{
    flex: 1 1 50%;
}

.candidateInfo_eachRow__dr0im input {
    height:3.5rem;
}

body{
    background-color: #f8f8f8;
}

.candidateInfo_rightCol__1EwFX{
    display: flex;
    flex-direction: row;
    grid-gap:2rem;
    gap:2rem;
    align-items: center;
    /* border: 2px solid; */

    /* justify-content: center; */
}



.candidateInfo_rightCol__1EwFX >*:nth-child(1){
    flex:1 1 70%;
  

}

.candidateInfo_rightCol__1EwFX >*:nth-child(2){
    flex:1 1 30%;
    

}
.candidateInfo_buttonDiv__2DUDz{
    display: flex;
    height: 7rem;
    overflow-x: auto;
    /* overflow-y: auto; */
    width: 14rem;
    /* border: 2px solid red; */
    /* align-items: center; */
    padding-top: 2rem;
    margin-top: 1.6rem;
    
}
.candidateInfo_buttonDiv__2DUDz::-webkit-scrollbar{
    /* display: none; */
    width: 0.1rem;
    height: 0.1rem;
}
.candidateInfo_rightCol__1EwFX button{
    width:14rem;
    height:3rem;
    border-radius:0.5rem;
    /* background-color: #4DAAFF; */
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.candidateInfo_nextButton__nOulL button{

    width:9.5rem;
    height:3rem;
    border-radius:0.5rem;
    background-color: #4DAAFF;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Open Sans';
    margin-top: 1.8rem;

}
.candidateInfo_dashedBorder__2Gb6n{
    display: flex;
    flex-direction: row;
    border:2px solid #4DAAFF;
    border-style: dashed;
    align-items: center;
    grid-gap:2rem;
    gap:2rem;
    padding: 1.5rem;
    height: 9rem;
}

.candidateInfo_dashedBorder__2Gb6n button{
    border:none;
    outline: none;
}

.candidateInfo_dashedBorder__2Gb6n button:hover,
.candidateInfo_dashedBorder__2Gb6n button:active,
.candidateInfo_dashedBorderbutton__3lSpq:focus{
    border:none;
    outline: none;

}

.candidateInfo_container__3Glqs{
    display: flex;
   align-items: center;
   grid-gap: 0.5vw;
   gap: 0.5vw;
   border: 1px solid #CACACA;
   border-radius: 0.8vh;
   padding-left: 0.5vw;
   height: 3.5rem;
}
.candidateInfo_container__3Glqs:active, .candidateInfo_container__3Glqs:focus{
    border: 1px solid #0086ff;
}
.candidateInfo_code__jMdew{
    display: flex;
    align-items: center;
    grid-gap: 0.3vw;
    gap: 0.3vw;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
}

.candidateInfo_dropdown__24UTE>:nth-child(1){
    padding-left: 0.2vw;
}
.candidateInfo_dropdown__24UTE>:nth-child(1)>*{
  margin:1vh 0;
  width: 95%;
  height: 2.5rem;

}
.candidateInfo_disabled__2G_jB{
    opacity: 1;
    background-color: #0000000a;
    pointer-events: none;
}

@media screen and (min-width: 700px) {
    .candidateInfo_dropdown__24UTE{
        display: flex;
        flex-direction: column;
        grid-gap: 0.5vh;
        gap: 0.5vh;
        height: 15vw;
        overflow-y: auto;
        width: 20vw;  
    } 
}
@media screen and (max-width: 699px) {
    .candidateInfo_dropdown__24UTE{
        display: flex;
        flex-direction: column;
        grid-gap: 0.5vh;
        gap: 0.5vh;
        height: 20vh;
        overflow-y: auto;
        width: 55vw;  
    }
}
.OTPModal_container__2adVF{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.OTPModal_container__2adVF img{
    max-height: 15rem;
}

.OTPModal_container__2adVF h1{
    font-weight: 800;
    color:#161616;
    font-size: 1.3rem;
    margin:1rem auto 1.5rem auto;
}

.OTPModal_container__2adVF h2{
    font-weight: bold;
    color:#161616;
    font-size: 1rem;
    margin-top: 1.3rem;
}

.OTPModal_container__2adVF span{
    font-weight: 500;
    color:#161616;
    font-size: 0.9rem;
  
}
.OTPModal_container__2adVF p{
    color: #0086FF;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.OTPModal_container__2adVF button{
    color:#ffffff;
   margin-top: 0.5rem;
   width: 9rem;
}

.OTPModal_container__2adVF button:focus,
.OTPModal_container__2adVF button:active,
.OTPModal_container__2adVF button:hover{
    background-color: #0086FF;
    outline: none;
}

.OTPModal_inputDiv__3lYxT{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap:1rem;
    gap:1rem;
    max-width: 15rem;
    margin: 1.4rem auto;
}

.OTPModal_inputDiv__3lYxT input{
  width: 3rem;
  padding-left: 1.1rem;
  color: #000000;
  font-size: 1rem;
  border: 0;
  border-bottom: 3px solid #4DAAFF;
}

.OTPModal_inputDiv__3lYxT input:focus,
.OTPModal_inputDiv__3lYxT input:active{
   outline: none;
    border: 0;
    border-bottom: 3px solid #4DAAFF;
  }

  @media (max-width: 996px){

    .OTPModal_inputDiv__3lYxT{
        grid-gap:0.3rem;
        gap:0.3rem;
    }

    .OTPModal_inputDiv__3lYxT input{
        width: 1.5rem;
        padding-left: 0;
        margin: 0;
        text-align: center;
        border-bottom: 1.5px solid #4DAAFF;
      }
      .OTPModal_inputDiv__3lYxT input:focus,
.OTPModal_inputDiv__3lYxT input:active{
   outline: none;
    border-bottom:1.5px solid rgb(14, 94, 168) ;
  }
      .OTPModal_container__2adVF{
        max-height: 400px;
        overflow-y: auto;
        padding: 2rem 0 2rem 0;
        margin:1rem 0 0 0;  
      }
      .OTPModal_container__2adVF::-webkit-scrollbar{
        display: none;
      }
      .OTPModal_container__2adVF h1{
        font-weight: 700;
        font-size: 1.2rem;
        margin:2rem auto 1.5rem auto;
    }

    .OTPModal_container__2adVF h2{
        font-weight: 700;
        font-size: 0.9rem;
        margin: 1.3rem 0 0.5rem 0;
        text-align: center;
    }
    
    .OTPModal_container__2adVF span{
        font-weight: 500;
        font-size: 0.8rem;
        margin: 0;
        text-align: center;
      
    }

    .OTPModal_container__2adVF button{
        width:8rem;
        border-radius: 2px;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0.3px;
        height: 2rem;
    }

  }

.bounce {
	/* position: absolute;
	bottom: 30px;
	left: 50% ;
	width: 70%;
	height: 60px ;
	margin-left:-30px;
	border: 2px solid #990000;
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	-ms-border-radius:50%;
	border-radius:50%; */
	animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	-moz-animation: bounce 3s infinite;
	-o-animation: bounce 3s infinite;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-40px);}
	60% {transform: translateY(-20px);}
}
.rotate90 {
    /* -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg); */
    transform: rotateY(180deg);
}
.ant-modal-content {
  border-radius: 15px;
}

.create-modal-scroll{
  overflow: auto;
  max-height: 62vh;
  padding: 10px 20px 0 20px;
}


.create-modal-scroll::-webkit-scrollbar {
  width: 5px;
}

.create-modal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

.create-modal-scroll::-webkit-scrollbar-thumb {
  background: #fbdfdf; 
}

.create-modal-scroll::-webkit-scrollbar-thumb:hover {
  background: #fbdfdf; 
}



.form-heading-css div label{
  text-align: left;
  font: normal normal 550 11px/12px Open Sans !important;
  letter-spacing: 0px;
  color: #6F6F6F !important;
  text-transform: uppercase;
  opacity: 1;
}

.sort-popover{
  width: 250px  !important;
}

.float-right{
  float: right;
}

.sorting-list-css{
  list-style: none;
  padding : 10px 0px 0px 0px;
}

.sorting-list-css li{
  font: normal normal normal 18px/26px Open Sans;
  border-bottom: 3px solid #F8F8F8;
  padding : 6px;
}

.sorting-list-css li:hover{
  /* font-size: 20px;
  border-bottom: 3px solid #F8F8F8; */
  color: #0086ff;
}

.select-sorting-menu{
   color: #0086ff;
}

.sort-heading-css{
 text-align: left;
 font: normal normal bold 20px/27px Open Sans;
 letter-spacing: 0px;
 color: #000000;
 opacity: 1;
}

.sort-action .cancel{
   border : 1px solid #0086ff !important;
   color: #0086ff !important;
   font-weight: 550;
   font-size: 12px;
}

.sort-action .ok{
  background: #0086ff;
  color: white;
  font-size: 12px;
}

.check-icon .anticon.anticon-check-circle svg path{
  fill: #eee6e6 !important;
}

.d-none{
  display: none !important;
}
.ant-checkbox-inner.name {
position: relative;
top: 0;
left: 0;
display: block;
width: 16px;
height: 16px;
direction: ltr;
background-color: #fff;
border: 1px solid #d9d9d9;
border-radius: 50px;
border-collapse: separate;
transition: all 0.3s;}

/* .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%!important;
  border : 1px solid gray !important;
} */

.ant-list-item-meta-title{
  margin: 0px !important;
}

.user-profile-icon-sm{
  border-radius: 50%;
  /* border: 1px solid blueviolet; */
  padding: 4px;
  height: 70px;
  width: 70px;
  cursor: pointer;
}

.table-user-profile{
  width: 62%;
  text-align: center;
}

.head-icon{
  display: inline;
  padding: 4px;
  cursor: pointer;
}

.column-setting-list-scroller{
  max-height: 500px;
  overflow-y: scroll;
}

.custom-avtar div{
  border: 0px !important;
}

.custom-avtar .ant-upload.ant-upload-select.ant-upload-select-picture-card{
  border-radius: 50%;
  border: 1px solid white !important;
}

.sorting-radio-container{
  padding-top: 14px;
  padding-bottom: 9px;
  border-bottom: 1px solid #f5dada
}

.sorting-radio-container div{
  padding : 5px;
}

.candidate-modal .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 0 0 5px 5px;
}
.candidate-modal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}

.candidate-avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 5rem !important;
  height: 5rem !important;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 2px dashed #7c48d9 !important;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 50%;
}



.primaryButton {
  border-radius: 10px;
}

.secondaryButton {
  border-radius: 10px;
  background-color: #f6f7f8;
}

.modalButton {
  font-size: 1em;
  padding-left: 40px;
  padding-right: 40px;
}

.modal-popup {
  text-align: center;
  border-radius: 10px;
  margin-top: 3%;
}

.editIcon {
  color: #2b2837;
  background-color: #e7eaed;
  border: none;
}

.deleteIcon {
  background-color: #e7eaed;
  color: #F64A3F;
  border: none;
}

.int-range-divider {
  padding-left: 5px;
  padding-right: 5px;
  border-left: 0;
  border-right: 0;
  pointer-events: none;
}

.modal-scroll {
  max-height: 35vh;
  margin-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

.form-item {
  margin-bottom: 12px;
}

.no-data {
  margin-top: 100px;
  color: black;
}

.no-data-loader {
  margin-top: 300px
}

/* common add and search part */
.panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.panel > * {
  border-radius: 10px;
  margin-left: 10px;
}

.search-bar {
  margin-right: 20px;
  size: large;
  background-color: #f6f7f8;
  width:300px;
}

.search-bar > .ant-input-wrapper > .ant-input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-input-group-addon > * {
  border-radius: 10px;
}

.data-row {
  font-size: 16px;
  color: #787b83;
}

/* modification in ant css */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
  padding-left: 20px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0px;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-radio-button-wrapper {
  margin-right: 10px;
  /* border-radius: 10px; */
}

.ant-page-header {
  padding: 0;
  padding-bottom: 10px;
}

.ant-page-header-heading-title {
  font-size: 24px;
}

.ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-table-thead {
  font-size: 16px;
}

.ant-modal-header {
  border: none;
}

.ant-modal-title {
  color: #1890ff;
}

.ant-modal-body {
  padding: 12px 24px 6px 24px;
}

.ant-modal-footer {
  padding: 0px 16px 10px 0px;
  border: none;
}

.ant-modal-confirm-body > * {
    padding: 5px 0px;
}

.ant-modal-title {
  font-size: 18px;
}

.ant-layout-header {
  padding: 0px 40px;
}

.ant-tabs-tabpane {
  text-align: center;
}

.ant-layout-content {
  text-align: center;
}

.ant-timeline {
  margin-bottom: -30px;
}

.ant-timeline-item {
  margin-bottom: 10px;
}

.ant-table-tbody > tr > td{
  border-bottom: 0px;
}


.sendEmailModal_templateCard__mPrZx{
    display: grid;
    grid-gap: 1rem;
  grid-template-columns: repeat(3,1fr);
}
.candidateProfile_canProfile__3G547{
    display: flex;
    flex-direction: column;
    height: 80vh;
    grid-gap: 3%;
    gap: 3%;
}

.candidateProfile_canProfile__3G547>*:nth-child(2){
    padding: 0 2% 0 3%;
}

.candidateProfile_canProfile__3G547>*:nth-child(2){
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
  
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    padding: 1% 2% 4% 2%;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    background-color: #ffffff;
    border-radius: 5px;
}



.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1) img{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.3rem;
    /* border: 2px solid; */
    /* border-radius: 50%; */
}



.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1) h4{
    font-size: 13px;
    font-weight: 620;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap:1.2rem;
    gap:1.2rem;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(2){
    display: flex;
    justify-content: left;
    flex-direction: column;
    grid-gap: 0.2rem;
    gap: 0.2rem;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(2) span{
    font-weight: 640;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1)>*:nth-child(8) {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 3% auto;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(1)>*:nth-child(8) button{
    width: 7rem;
    font-weight: 700;
    border-radius: 3px;
    background-color: #0086FF;
    border: none;
    color: #ffffff;
    letter-spacing: 0.4px;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 63%;
    padding:1% 3% 1% 3%;
    /* background-color: #ffffff; */
    border-radius: 5px;
    /* box-shadow: 0 1px 5px gray; */
    grid-gap: 2%;
    gap: 2%;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0 3% 0;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1) h3{
    font-weight: 800;
    font-size: 1.4rem;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    flex: 1 1 55%;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2){
    flex: 1 1 36%;
    display: flex;
    flex-direction: row;
   background-color: #EAF5FF;
   padding-left: 1%;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   align-items: center;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 52%;
    font-size: 1.1rem;
    font-weight: 700;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 48%;
    width: 100%;
    background-color: #EAF5FF;
    font-weight: bold;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(2)>*:nth-child(2)>*>*{
    font-weight: 600;
    font-size: 1rem;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1){
    background-color: #EAF5FF;
    padding: 0.5rem ;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 1rem;
    border-radius: 2px;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1)>*:nth-child(1){
    color:rgb(91, 91, 244);
    display: flex;
    grid-gap:0.5rem;
    gap:0.5rem;
    align-items: center;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(3)>*:nth-child(1) span{
    font-weight: 700;
}

.candidateProfile_canProfile__3G547>*:nth-child(2)>*:nth-child(2)>*:nth-child(3) button{
    font-weight: 700;
    border-radius: 3px;
    background-color: #0086FF;
    border: none;
    color: #ffffff;
    letter-spacing: 0.4px;
}





.candidateProfile_hackathonContainer__1LCL7{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.candidateProfile_hackathonTable__34qlU{
    display: flex;
    flex-direction: row;
    width: 100%;
    border:1px solid #D3D3D3;
    flex-direction: column;
    font-family: "Open Sans";
    font-weight: 400;
    font-size:1rem ;
}

.candidateProfile_round__3xNpL{
    display: flex;
    width: 99%;
    height: 16.1rem;
     overflow-y: scroll;
    flex-wrap: wrap;
    margin: 1rem auto;
   grid-gap:1rem;
   gap:1rem;
  
}

.candidateProfile_round__3xNpL::-webkit-scrollbar{
    display: none;
}



  .candidateProfile_content__3pg2f{
    display: flex;
    flex-direction: column;
    grid-gap: 5%;
    gap: 5%;
    padding: 1rem;
    border-radius: 0.2rem;
    background-color: #F2F7F2;
    width: 48%;
}

.candidateProfile_content__3pg2f>*:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.candidateProfile_content__3pg2f>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    grid-gap:4%;
    gap:4%;
}

.candidateProfile_content__3pg2f>*:nth-child(2){
    display: flex;
    flex-direction: column;
}

.candidateProfile_content__3pg2f>*:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.candidateProfile_content__3pg2f>*:nth-child(3)>*{
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.candidateProfile_conBack__3UrxE{
    background-color: #FAFAFA;
}

.candidateProfile_content__3pg2f span{
    color: #6F6F6F;
    font-size: 14px;
    font-weight: 620;
}

.candidateProfile_content__3pg2f p{
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
}

.candidateProfile_hackathonButton__27SWZ{
    display: flex;
    flex-direction: row;
    grid-gap :1.5rem;
    gap :1.5rem;
    margin-top: 2rem;   
}

.candidateProfile_hackathonButton__27SWZ button{
    height:3rem;
    width:10rem;
    border-radius: 0.5rem;
    border: 2px solid #D3D3D3;
    font-size: 1rem;
    font-weight: 600;
}

.candidateProfile_hackathonButton__27SWZ .candidateProfile_addScore__2zbyC{
    background-color: #4DAAFF;
    border: 2px solid #4DAAFF;
    color: #ffffff;
}

.candidateProfile_changeStatus__4fnBG{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.4rem;
}

.candidateProfile_scheduleButton__JEJai{
    display: flex;
    flex-direction: row;
    grid-gap :1rem;
    gap :1rem; 
}

.candidateProfile_scheduleButton__JEJai button{
    height:2.3rem;
    width:7rem;
    border-radius: 0.2rem;
    border: 1.3px solid #D3D3D3;
    background-color: #D3D3D3;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Open Sans";

}

.candidateProfile_upload__2421l{
    width:7rem;
    height:7rem;
    border-radius: 50%;
}

.candidateProfile_editProfileImg__1MikJ{
    border: 2px solid red;
}


.addScoreModal_container__1iryA{
    display: flex;
    flex-direction: column;
    margin: 2rem;
}

.addScoreModal_section__bQjWx{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
}
.addScoreModal_formItem__2d83m{
    width:50%;
}
.addScoreModal_section__bQjWx input{
    height:3rem;
    width: 100%;
}
.addScoreModal_section__bQjWx button{
    height:3rem;
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 0.8rem;
    color:#969191;
}

.addScoreModal_section__bQjWx label{
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
}
.addScoreModal_addScore__26m4D{
    display: flex;
    flex-direction: row;
    grid-gap :1.5rem;
    gap :1.5rem;
    margin: 2rem auto;
    justify-content: center;
    
    
}
.addScoreModal_addScore__26m4D button{
    height:3rem;
    width:10rem;
    border-radius: 0.5rem;
    border: 2px solid #4DAAFF;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Open Sans";

}

 .addScoreModal_add__my5uY{
    background-color: #4DAAFF;
    border: 2px solid #4DAAFF;
    color: #ffffff;
}

.itemValue_itemVal__3mj8Z{
   list-style: none;
   display: flex;
   justify-content: left;
}

.itemValue_itemVal__3mj8Z{
    background-color: rgb(232, 235, 203);
    display: flex;
    justify-content: left;
    text-align: left;
    padding: 0.3rem 0.4rem 0.3rem 0.7rem;
    width: 10rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
}
.layout_container__X7T42{
    
    background-color: #ffffff;
    width: 20rem;
    font-size:14px;
    font-weight: 500;
    max-height: 100vh;
}

.layout_container__X7T42>*:nth-child(1)>*:nth-child(2){
    max-height: 83vh;
    overflow-y: scroll;
}




.layout_container__X7T42>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .layout_container__X7T42>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar {
    height: 0.2rem;
  }
   
  .layout_container__X7T42>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .layout_container__X7T42>*:nth-child(1)>*:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: rgb(214, 242, 243);
    border-radius: 10px;
    height:0.2rem;
  }

  .layout_logout__3604n{
    display: flex;
    flex-direction: row;
    justify-content: right;
    /* border: 2px solid red; */
  }

  .layout_module__2nkQx{
    padding: 0.3rem 0.5rem 0.3rem 0;
    margin: 0.2rem 0;
    cursor: pointer;
    justify-content: center;
  }
  .layout_module__2nkQx li{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    grid-gap:0.5rem;
    gap:0.5rem;
    color: rgb(106, 106, 106);
  }


/* .app{
    height: 100%;
    background-color: gainsboro;
}

.app-header{
    height: 50px;
}

.app-body{
    display: flex;
    flex-wrap: nowrap;
    height: calc(100% - 40px);
}

.app-nav{
    width: 142px;
    background: #a6a6b3;
    height: 100%;
}


.app-page{
    width: calc(100% - 142px);
    background : red;
    height: 100%;
} */

#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    /* background: rgba(255, 255, 255, 0.3); */
  }
  
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-nav-background {
    background: #FFFFFF;
  }

  .ant-layout-header {
    height: 50px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #F8F8F8  !important;
}

.app{
   height: 100%  !important; 
}

.app-logo{
  width: 100%;
  text-align: center;
  background-color: white;
  height: 68px;
  padding: 14px;
}



.app-logout{
  position: absolute;
  right: 9%;
  margin-top: 2.5%;
}


.siderIcon{
  width:15px;
  height:15px;
}
.jdPdf{
  position: relative;
  width: 100%;
  padding-right: 4px;
  display: flex;
  align-self: center;
  background-color:#fff;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
 margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
  display: none !important;

}
.create-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-user > * {
  border-radius: 10px;
}
.editButton{
  color: #2b2837;
  background-color: #e7eaed;
  border: none;
  
}
.editButton:hover{
  background-color:#1890FF;
}
.editButton:focus{
  color: #2b2837;
  background-color: #e7eaed;
}
.editButton:focus:hover{
  background-color:#1890FF;
  color: white;
}
.mainHeader_mainHeader__199zy{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4% 0  2%; 
    background-color: #ffffff;
    height: 9vh;
}

.mainHeader_mainHeader__199zy>*:nth-child(1){
    flex: 1 1 70%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.mainHeader_mainHeader__199zy>*:nth-child(2){
    flex: 1 1 30%;
     display: flex;
     justify-content: right;
     align-items: center;
 }

.mainHeader_mainHeader__199zy h1{
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.test_duration__x7B8c{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 4rem;
    background-color: #FFFFFF;
    /* margin-right: -60px; */
}

.test_header__3FUFl{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid red;
}

.test_duration__x7B8c div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-left: 20px;
}

.test_duration__x7B8c div p{
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    color:#191919;
}

.test_duration__x7B8c span{
    font-size: 0.9rem;
    font-family: 'Open Sans';
    color:#636363;
    margin-top: 10px;
}

/* .buttonDiv{
    gap:3rem;
    border: 10px solid red;
} */

.test_buttonDiv__VVIfd div{
    height:2.9rem;
    width:6.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.test_button1__M4TZQ{
    border:3px solid #66ff66;
    background-color:#E7FDF1 ;
}

.test_button2__1muV_{
    border:3px solid #FF0D3B;
    background-color:#FFE7EB ;
}

.test_saveButton__3nlD0{
    background-color: #0086FF;
    width:10rem;
    height:2.5rem;
    border-radius:0.7rem;
    border:1px solid #0086FF;
    color:#ffffff;
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 800;
  }

 

  .test_bodyscr__1vesx{
    display: flex;
    flex-direction: column;
    height: 85vh;
    overflow-y: scroll;
    background-color: #ececec;

  }

  
  .test_bodyscr__1vesx::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  .test_bodyscr__1vesx::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .test_bodyscr__1vesx::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(179, 167, 167, 0.3);
  }
   
  .test_bodyscr__1vesx::-webkit-scrollbar-thumb {
    background-color: rgb(119, 112, 112);
    border-radius: 1rem;
    height:0.5rem;
  }


  .test_sectionDetails__2Hp-J{
    display: flex;
    flex-direction: row;
    grid-gap:0.1rem;
    gap:0.1rem;
    overflow-x: scroll;
    max-width: 97%;
    margin:1% 2.3%;
  }

  .test_sectionDetails__2Hp-J::-webkit-scrollbar {
    width: 0.1rem;
  }
  
  .test_sectionDetails__2Hp-J::-webkit-scrollbar {
    height: 0.2rem;
  }
   
  .test_sectionDetails__2Hp-J::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .test_sectionDetails__2Hp-J::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 0;
    height:0.2rem;
  }

  

  .test_sectionDetails__2Hp-J button{
    min-width: 30%;
    height: 2.7rem;
    background-color: #ffffff;
    border:1px solid #0086FF;
    color: #0086FF;
    font-weight: 700;
    font-size: 1.2rem;
  }

  /* .sectionDetails button:hover{
    background-color: #4daaff;
    border:1px solid #4daaff;
    color: #FFFFFF;
  } */

  .test_sectionDetails__2Hp-J .test_onClick__1mYNx{
    background-color: #0086FF;
    border:1px solid #0086FF;
    color: #FFFFFF;
  }


  .test_marks__J5EP1{
    display: flex;
    flex-direction: row;
    grid-gap:3%;
    gap:3%;
    justify-content: space-between;
  }

  .test_marks__J5EP1 >*{
    flex: 1 1 48%;
  }

  .test_name__30VzN{
    display: flex;
    flex-direction: column;
    justify-content: left;
   margin-bottom: 1.5%;
    width: 100%;
  }

  .test_name__30VzN >*{
    flex: 1 1 100%;
  }



  .test_addQuesModal__1Y7VF>*>*:nth-child(4){
    margin: 2rem 2.5rem;
    padding: 0 1rem 1rem auto;
  }

  .test_addQuesModal__1Y7VF>*>*:nth-child(4)>*{
    width: 8rem;
  }



.testList_tableStyle__2U44y{
    font-weight: 600;
}

.testList_headerName__2HflG{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
 align-items: center;
}

.testList_headerName__2HflG>*{
  margin: 0;
}



.testList_headerName__2HflG h1{
  font-size: 0.9rem;
  font-weight: bold;
  color: #6f6f6f;
}



.testList_searchDiv2__23-Vd{
  /* outline: #d1cfcf; */
  border: 0;
  border-radius: 5px;
}
.testList_searchDiv2__23-Vd >*{
  /* background-color: hsl(0, 5%, 96%); */
}

.testList_searchDiv2__23-Vd>*:nth-child(1)>*:nth-child(2){
  padding-left: 0.5rem;
  border-radius: 5px;
  max-height: 1.7rem;
} 

.testList_addDiv__1F5Bd{
  width: 20rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    grid-gap:2rem;
    gap:2rem;
  }
  
  .testList_addDiv__1F5Bd >*{
    flex:1 1 5%;
   
  }
  
  .testList_addDiv__1F5Bd >*:nth-child(7){
    flex:1 1 12%;
   
  }
  
  .testList_addDiv__1F5Bd button{
    background-color: #4daaff;
    width:10rem;
    height:3rem;
    border:0.4rem;
    color:#ffffff;
    font-size: 1rem;
  }
  
  .testList_addDiv__1F5Bd img{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

.testList_table__2jd1j{
   font-weight: 600;
   display: flex;
   width: 100%;
    
  }
  
   /* .table::-webkit-scrollbar {
    display: none;
  } */


  .testList_rowStyle__1qeuH{
    background-color: white;
   font-weight: 600;
  }
  
  .testList_scroll__1nKr-{
    max-height: 685px;
    overflow-y: scroll;
  }
.testList_scroller__3ZwzW::-webkit-scrollbar {
    display: none;
}
.testList_scroll__1nKr-::-webkit-scrollbar{
  display: none;
}

.testList_hr-text__rLPEo {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  width: "100%";
}
  /* .hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
} */

/* style={{overflowY:"scroll",height:"500px"}} */

.testList_secModal__ZI_Ci{
  height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding-inline: 0.5rem;
  margin-bottom: 1rem;
}

.testList_secModal__ZI_Ci::-webkit-scrollbar {
  width: 0.3rem;
}

.testList_secModal__ZI_Ci::-webkit-scrollbar {
  height: 0.3rem;
}
 
.testList_secModal__ZI_Ci::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}
 
.testList_secModal__ZI_Ci::-webkit-scrollbar-thumb {
  background-color: rgb(104, 92, 92);
  border-radius: 10px;
  height:0.2rem;
}

.testList_secDetailCard___XtiX{
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  padding: 1rem; 
  box-shadow: 0 2px 6px solid gray;
  outline: 1.3px solid rgb(215, 213, 213);
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 0.5rem;
}

.testList_secDetailCard___XtiX>*:nth-child(1){
  font-size: 1.2rem;
  font-weight: 700;
}

.testList_secDetailCard___XtiX>*:nth-child(2){
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.testList_secDetailCard___XtiX>*:nth-child(2)>*{
  padding: 0.6rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.1rem;
  gap: 1.1rem;
justify-content: center;
align-content: center;
}

.testList_secDetailCard___XtiX>*:nth-child(2)>*>*{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testList_secDetailCard___XtiX>*:nth-child(2)>*>*>*:nth-child(1){
  color:#BFBFBF;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.testList_secDetailCard___XtiX>*:nth-child(2)>*>*>*:nth-child(2){
  font-weight: bold;
}





src/pages/college-dashboard/style.css.ant-modal-content {
    border-radius: 15px;
  }
  
  .college-modal-field div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 10px/14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  .college-modal-field{
    padding: 10px 0 0 !important;
    margin: 0px;
  }

  .college-modal-field-sm div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  /* .college-modal-field-sm div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  } */

  .college-modal-field-sm .ant-form-item-label{
    padding: 10px 0 0 !important;
  }

  .college-modal-field-sm{
    margin: 0px  !important;
  }
  
  .sub-heading{
    text-align: left;
    font: normal normal bold 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #161616;
    opacity: 1;
    margin-left: 12px;
  }
  
  .college-modal-scroll{
    overflow: auto;
    min-height: 500px;
  }
  
  /* ::-webkit-scrollbar {
    width: 5px;
    scrollbar-width : 5px !important;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #fbdfdf; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #fbdfdf; 
  } */

  div.horizotal-scroll-container::-webkit-scrollbar{
    width: 5px !important;
    scrollbar-width : 10px !important;
    height: 8px !important;
  }

  div.horizotal-scroll-container::-webkit-scrollbar-thumb{
    background: black !important; 
  }



  div.horizotal-scroll-container {
    background-color: white !important;
    overflow: auto !important; 
    white-space: nowrap  !important;
    display: flex;
  }
    
  /* div.scrollmenu a:hover {
    background-color: #777;
  } */

  .number-container-css{
    padding: 6px;
    font-size: 9px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px;
  }

  .number-container-lable-css{
    height: 12px;
    width: 12px;
    display: inline-block;
    text-align: center;
  }

  .active-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid black;
    margin: 7px;
    border-radius: 50%;
  }


  .inactive-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid #acacb7;
    margin: 7px;
    border-radius: 50%;
  }



  .other-label-container-css{
    padding: 2px 10px 2px 10px;
    font-size: 13px;
    border-radius: 9px;
    border: 2px solid #dbb5b5;
    display: inline-block;
    margin: 10px;
  }

  #components-page-header-demo-content .image {
    display: flex;
    align-items: center;
    margin: 0 0 0 60px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .image {
    margin: 0 60px 0 0;
  }
  
  #components-page-header-demo-content .example-link {
    margin-right: 16px;
    line-height: 24px;
  }
  [data-theme='compact'] #components-page-header-demo-content .example-link {
    line-height: 20px;
  }
  #components-page-header-demo-content .example-link-icon {
    margin-right: 8px;
  }
  
  [data-theme='compact'] #components-page-header-demo-content .example-link-icon {
    width: 20px;
    height: 20px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .example-link {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  #components-page-header-demo-content .ant-page-header-rtl .example-link-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  
  @media (max-width: 768px) {
    #components-page-header-demo-content .image {
      flex: 100% 1;
      margin: 24px 0 0;
    }
  }


  .page-header{
    height: 140px;
  }

  .page-body{
    height: 100%;
    background: white !important;
  }

  .page{
    height: 100% !important;
    margin: 35px 46px 50px 10px;
  }

  .pdf-logo{
    height: 30px;
    width: 30px;
  }



  /* .ant-form-item .ant-upload.ant-upload-drag{
    background: #fafafa;
    width: fit-content;
  } */

  .file-upload-dragger span{
    padding: 5px !important ;
    margin-left: 25px  !important;
  }

  .file-upload div.ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content{
    display: block !important;
    flex: unset !important;
  }


  .pdf-icon-container{
    cursor: pointer;
    background: #E6F3FF;
    padding: 12px;
    border-radius: 13px;
  } 

  .pdf-icon-container div span{
    cursor: pointer;
  } 

  .text-center{
    text-align: center;
  }

  .text-align-end{
    text-align: end;
  }

  .ant-upload.ant-upload-select-picture-card{
    width: 135px !important;
    height: 157px !important;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 2px dashed #7c48d9 !important;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }

  .anticon-plus-circle svg{
    height: 30px !important;
    width: 30px !important;
  }

  .custom-search-icon svg{
    height: 20px;
    width: 20px;
    color: #9b9be5;
  }

  .tab{
    padding: 0px 10px 10px 10px;
    font: normal normal bold 18px/26px Open Sans;
    display: inline-block  !important;
    color : #6f6f6f;
    cursor: pointer;
  }

  .active{
    border-bottom : 2px solid #0086ff  !important;
    color : #0086ff !important;
  }

  .jd-custom-modal .ant-modal-content{
    border-radius: 4px;
    height:700px
  }
  .jdPdf{
    position: relative;
    width: 100%;
    padding-right: 4px;
    display: flex;
    align-self: center;
    background-color:#fff;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
   margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
    display: none !important;

}
.exclamatory-icon-css svg{
    color: #d3cd1a;
    height: 66px;
    width: 66px;
}

.modal .ant-modal-content{
    border-radius: 3px !important;
}
.dragAndDrop_container__3kxkZ{
   display: flex;
    max-height: 200px;
    overflow-y: scroll;
    flex-direction: column;
    margin:  1rem 0;
    font-weight: 600;
   
}

.dragAndDrop_buttonDiv__1Rv2R{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    justify-content: center;
    align-items: center;
}
.dragAndDrop_buttonDiv__1Rv2R button{
    border: 2px solid #0086FF;
    color: #0086FF;
    font-weight: 700;
    border-radius: 1rem;
    width: 6rem;
}

.dragAndDrop_buttonDiv__1Rv2R .dragAndDrop_apply__2VpqH{
    color:#ffffff;
    background-color:#0086FF ;
}







 .dragAndDrop_container__3kxkZ::-webkit-scrollbar {
    width: 0.13rem;
    height: 0px;
  }
  
 
   
  .dragAndDrop_container__3kxkZ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(242, 240, 240, 0.3);
  }
   
  .dragAndDrop_container__3kxkZ::-webkit-scrollbar-thumb {
    height: 56px;
    background-color: rgb(236, 230, 230);
    border-radius: 10px;
    
  }


.jdListExperienced_header__2zGOY{
  display: flex;
  align-items: center;
}

.jdListExperienced_header__2zGOY h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

  .jdListExperienced_container__3yyWx{
    display: flex;
    flex-direction: column;
    padding: 0% 2% 0% 2%;
  }

  .jdListExperienced_container__3yyWx>*:nth-child(1)
  {
    display: flex;
    flex-direction: row;
    grid-gap: 2%;
    gap: 2%;
    margin-top:0.5rem;
    align-items: center;
    min-height: 2.7rem;
  }
  .jdListExperienced_searchDiv__bj7nb{
    border: 0;
    border-radius: 10px;
    height: 2.1rem;
  }
  .jdListExperienced_searchDiv__bj7nb >*{
    width: 18rem; 
    border-radius: 5px;
  }

  .jdListExperienced_searchDiv__bj7nb>*:nth-child(1)>*:nth-child(2){
    padding-left: 0.5rem;
    border-radius: 5px;
    /* max-height: 1.7rem; */
  }
  .jdListExperienced_searchDiv2__KMVEd{
  height: 2.2rem;
  width:18rem;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  /*border: 1.5px solid #e0e0e0;*/
  
  }
  
  .jdListExperienced_searchDiv2__KMVEd input{
    width:30rem;
    min-height: 30px;
    border:0;
    color: #000000;
  }
  
  .jdListExperienced_searchDiv2__KMVEd input:active,
  .jdListExperienced_searchDiv2__KMVEd input:focus{
   outline: none;
   border:0;
  }
  
  .jdListExperienced_searchDiv2__KMVEd img{
    width:20px;
    height:20px;
    margin-left: 1rem;
    opacity: 0.6;
  }
  .jdListExperienced_clearBtn__1fUTW {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: #999;
  }
  
  .jdListExperienced_clearBtn__1fUTW:hover {
    color: #333;
  }
  

  .jdListExperienced_container__3yyWx>*:nth-child(1)>:nth-child(1){
    flex: 0 1 25%;
    display: flex;
  }

  .jdListExperienced_container__3yyWx>*:nth-child(1)> button{
    display: flex;
    justify-content: center;
    background-color: #0086ff;
    color: #ffffff;
    /* text-align: center;
    align-items: center; */
    font-weight: bold;
    /* height: 2.5rem; */
    border: 1px solid #0086ff;
    /* border-radius: 2px; */
    max-width: 9rem;
  }

  .jdListExperienced_container__3yyWx>*:nth-child(1)>:nth-child(2){
    display: flex;
    flex-direction: row;
    flex: 1 1 80%;
    grid-gap:2%;
    gap:2%;
    align-items: center;
    justify-content: right;
    align-items: center;
    height: 2.5rem;
  }

  /* .container>*:nth-child(1)>:nth-child(4){
  } */
 
  .jdListExperienced_container__3yyWx>*:nth-child(1)>:nth-child(2)>*:nth-child(5){
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #0086ff;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #0086ff;
    /* border-radius: 2px;
    height: 2.2rem; */
    width: 9rem;
    /* border: 2px solid red; */
  }



  .jdListExperienced_tableStyle__2vgIn{
    font-size: 15px;
    font-weight: 700;
    margin-top: 1rem;
    /* overflow-x: auto;
    overflow-y: auto; */
  }


  .jdListExperienced_headerName__3ltdg{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .jdListExperienced_headerName__3ltdg h1{
    font-size: 0.9rem;
    font-weight: bold;
    color: #6f6f6f;
  }

  .jdListExperienced_addButton__1FkhX{
    width: 7rem;
    height: 2.2rem;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    background-color: #0086ff;
    border: 1px solid #0086ff;
    border-radius: 2px;
  }


  .jdListExperienced_jobCard__39d3G{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap:1rem;
    gap:1rem;
    flex: 1 1 70%;
    margin-right: 3%;
    min-width: 53vw;
    margin:0;
  }

  .jdListExperienced_jobCard__39d3G>*{
    display: flex;
    flex-direction: column;
    flex: 1 1 33%;
  }

  .jdListExperienced_jobCard__39d3G>*>*:nth-child(1){
    font-size: 1.1rem;
    font-weight: 690;
    text-align: center;
  }

  
  .jdListExperienced_jobCard__39d3G>*>*:nth-child(2){
    max-height: 2.2rem;
    background-color: #ffffff;
    padding: 0.5rem;
    flex-grow: 1;
    border-radius: 3px;
    box-shadow: 2px 2px 5px #ACB1B75C;
    outline: 1px solid rgb(210, 209, 209);
  }

  .jdListExperienced_jobCard__39d3G>*:nth-child(2)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    font-weight: 600;
    font-size: 14px;
  }

  .jdListExperienced_jobCard__39d3G>*:nth-child(2)>*:nth-child(2)>*{
    min-width: 5.8rem;
   
  }

  .jdListExperienced_jobCard__39d3G>*:nth-child(1)>*:nth-child(2){
    display: flex;
    color: #D3D3D3;
    font-weight: 600;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .jdListExperienced_jobCard__39d3G>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color: #000000;
  }



  .jdListExperienced_jobCard__39d3G>*:nth-child(3)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
  }

  .jdListExperienced_container__3yyWx>*:nth-child(1){
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .jdListExperienced_container__3yyWx>*:nth-child(1)>*:nth-child(2){
    display: flex;
    align-items: center;
    height: 2.6rem;
  }

  .jdListExperienced_container__3yyWx>*:nth-child(1)>*:nth-child(2) input{
    height: 2.5rem;
  }

  .jdListExperienced_mobJobCard__3-wsw{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .jdListExperienced_mobJobCard__3-wsw>*{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .jdListExperienced_mobJobCard__3-wsw>*:nth-child(1){
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.8rem;
    border-bottom: 1.3px solid #CACACA;
  }
  .jdListExperienced_mobJobCard__3-wsw>*:nth-child(1)>:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
  }
  .jdListExperienced_mobJobCard__3-wsw>*:nth-child(n+2)>*{
    display: flex;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
  }
  .jdListExperienced_mobJobCard__3-wsw>*:nth-child(n+2)>*>:nth-child(1){
   font-size: 0.9rem;
   font-weight: 500;
   color: #808080;
  }
  .jdListExperienced_mobJobCard__3-wsw>*:nth-child(n+2)>*>:nth-child(2){
    font-size: 01rem;
    font-weight: 600;
   }
   .jdListExperienced_mobJobCard__3-wsw button{
    color: #fff;
    background-color: #1089FF;
    height: 2.4rem;
    outline: 0;
    border: 0;
    border-radius: 0.4rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-top: 0.4rem;
   }


 /* .jobFilter>*{
  flex: 1 1 49%
  display: flex;
  flex-direction: row;
  gap:0.3rem
 }

 .jobFilter>*>*
 }

 .jobFilter>*:nth-child(1)>*:nth-child(1){
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0086ff;
  margin-right: 1rem;

 } */

 

 @media(max-width:700px)
 {
  .jdListExperienced_header__2zGOY{
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }
  
  .jdListExperienced_header__2zGOY h1{
    font-size: 1.1rem;
   font-weight: 700;
    margin: 1% 0 0 2%;
  }
 }
/* .content{
    border:2px solid red;
} */

.addProfileModal_container__3wbwT {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-inline: 0.5rem;
}
.addProfileModal_scrollbody__2BdOc {
  max-width: 100%;
  overflow-y: scroll;
  max-height: 73vh;
  border-radius: 0.5rem;
  margin-bottom: 4%;
}

.addProfileModal_scrollbody__2BdOc::-webkit-scrollbar {
  width: 0.2rem;
}

.addProfileModal_scrollbody__2BdOc::-webkit-scrollbar {
  height: 0.5rem;
}
.addProfileModal_ListBodyContainer__2YHCn::-webkit-scrollbar{
  display: none;
}
.addProfileModal_scrollbody__2BdOc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}

.addProfileModal_scrollbody__2BdOc::-webkit-scrollbar-thumb {
  background-color: rgb(224, 220, 220);
  border-radius: 10px;
  height: 0.5rem;
}

.addProfileModal_addProfileForm__2NwA- .addProfileModal_ant-select__-p4Si{
  background: #fff;
  height: 2rem;
  box-shadow: 0px 1px 5px grey;
}

.addProfileModal_scrollbody__2BdOc h3 {
  font-weight: bold;
  font-size: 1rem;
  border-bottom: 2px solid rgb(124, 122, 122);
  margin-bottom: 1rem;
}

.addProfileModal_scrollbody__2BdOc input {
  height: 2.3rem;
}

.addProfileModal_scrollbody__2BdOc span {
  font-size: 12px;
}

.addProfileModal_itemHeader__20Ofx {
  color: #161616;
  font-weight: 500;
}

.addProfileModal_formHeader__1Zt2a {
  display: flex;
  margin-top: 0.2rem;
  justify-content: left;
}

.addProfileModal_formHeader__1Zt2a h1 {
  font-weight: bold;
  font-size: 24px;
}

.addProfileModal_jobRow__e8gs5 {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}
.addProfileModal_jobRow__e8gs5 select {
  height: 1rem;
}
/* .jobRow span{
    font-size: 13px;
} */
.addProfileModal_jobRow__e8gs5 > * {
  flex: 1 1 50%;
  margin-top: "2px";
}

.addProfileModal_select__340I3 {
  height: 2.3rem;
  border-radius: 2px;
  border: 1.3px solid rgb(215, 211, 211);
}

.addProfileModal_upload__3eL9p {
  display: flex;
  border: 1.3px solid rgb(214, 208, 208);
  border-radius: 3px;
  padding: 0.35rem;
}

.addProfileModal_upload__3eL9p span {
  color: rgb(214, 208, 208);
  font-size: 1rem;
  font-weight: 400;
}

.addProfileModal_resume__3fxuA {
  display: flex;
  width: 10rem;
  height: 2.45rem;
  border: 1.3px solid rgb(214, 208, 208);
  align-items: center;
  border-radius: 3px;
  margin-top: 1.4rem;
  cursor: pointer;
}

.addProfileModal_curCom__3vP1r {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}

.addProfileModal_curCom__3vP1r > * {
  flex: 1 1 100%;
}

.addProfileModal_resume__3fxuA span {
  margin-left: 0.6rem;
  color: rgb(214, 208, 208);
  font-size: 1rem;
  font-weight: 400;
}

.addProfileModal_buttonDiv__3HMBQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 40%;
  margin: 1rem auto;
  border: "1px solid black";
}

.addProfileModal_buttonDiv__3HMBQ > *:nth-child(1) {
  flex: 1 1 20%;
}

.addProfileModal_buttonDiv__3HMBQ > *:nth-child(2) {
  flex: 1 1 20%;
}

.addProfileModal_buttonDiv__3HMBQ button {
  width: 7rem;

  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.3rem;
  border: 2px solid #4daaff;
  color: #4daaff;
}

.addProfileModal_buttonDiv__3HMBQ .addProfileModal_addButton__2u-zE {
  background-color: #4daaff;
  color: #ffffff;
}

.addProfileModal_referedBy__3ttnM {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}
.addProfileModal_referedBy__3ttnM > *:nth-child(1) {
  flex: 1 1 80%;
}
.addProfileModal_referedBy__3ttnM span {
  font-size: 13px;
}

.addProfileModal_footer__38aJq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin-top: 1.4rem;
}

.addProfileModal_ant-select-selection-placeholder__S2htU {
  width: 100%;
  /* margin-top: 0.5rem; */
  color: #7e7e7e !important;
  text-align: left;
  /* border: 1px solid black; */
}

.addProfileModal_ant-select__-p4Si {
  background: #fff;
  height: 3rem;
  border-radius: 5px;
  /* box-shadow: 0px 1px 5px grey; */
}

.addProfileModal_preferredLoc__3gu0s{
  height: 2rem;
  border-radius: 2px;
  border: 1.3px solid rgb(215, 211, 211);
}

.addProfileModal_preferredLoc__3gu0s>*:nth-child(1) span{
  font-size: 0.88rem;
}

.active-dashboard-wrap {
  background-color: #e7eaed;
  color: #1890ff;
  text-align: center;
  padding: 0px 20px;
  border-radius: 10px;
  margin-left: 10px;
}

.dashboard-candidate-box {
  /* background-color: #fff;
  width: 250px;
  min-height: 89px;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 10px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feedback-left-content {
  font-weight: 700;
}

.candidate-details {
  text-align: center;
  background-color: #e7eaed;
  padding: 10px;
  
}

.candidate-dropdown  > * {
  max-height: 50vh;
  overflow: scroll;
  cursor: pointer;
}

.candidate-pointer {
  cursor: pointer;
}
.ListBodyContainer::-webkit-scrollbar{
  display: none;
}
.screen-type {
  margin-left: 5px;
  border: 2px solid yellow;
  padding: 0px 10px;
}

.interview-type > * {
  border-radius: 10px;
}

.on-modal-loader {
  display: block;
  margin-bottom: 20px;
}

.booked-slot {
  background-color: #f6f7f8;
  border-radius: 10px;
  padding:10px;
  color: #787b83;
}

.confirm-slot {
  text-align: center;
}

.candidate-timeline {
  margin-top: 10px;
  width: 200px;
}

.view-pdf > .ant-modal-content > .ant-modal-body {
  height: 90vh;
  padding: 0px;
}

.view-pdf-iframe {
  height: 95vh;
  width: 100%;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-dropdown {
  left: -90%;
}

.available-slots {
  max-height: 20vh;
  overflow:scroll;
  overflow-x: hidden;
}

.available-slots > * {
  margin-bottom: 10px;
  margin-left: 10px;
}
.text-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.candidate-modal-job-title-hover{
  background: #fff;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.candidate-modal-job-title-hover:hover{
  background: #e7eaed;
}

iframe{
  overflow:hidden;
}

.multiAgencySelect {
  max-height: 15vh;
  overflow-y: auto;
}

.candidate-title {
  /* width: 27.5rem; */
  height: 100%;
  /* padding: 5px; */
  margin-top: 0.5rem;
  width: 100%;
  /*margin-right: 0.5rem; */
  display: flex;
  flex-direction: column;
}

.dashboard-candidate-box .ant-modal-content {
  /* border-bottom-left-radius: 1%;
  border-bottom-right-radius: 1%; */
  border-radius: 0.5%;
}



.ant-modal-content>*{
  border-radius: 7px;
  background-color: #ffffff;
}

.dashboard-candidate-box .ant-modal-header{
  border-radius: 2%;
} */

.dashboard-candidate-box  .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 20% ;
}

.dashboard-candidate-box .ant-modal-close{
  margin: 1.2rem;
}

.dashboard-candidate-box-jobtitle .ant-select-selection-placeholder{
  width: 100%;
  /* margin-top: 0.5rem; */
  color: #000000 !important;
  text-align: left;
  font-size: smaller;
}
.dashboard-candidate-box-jobtitle .ant-select{
  background: #fff;
  height: 2rem;
  box-shadow: 0px 1px 3px grey;
}

.dashboard-candidate-box .ant-select-selection-placeholder{
  width: 100%;
  margin-top: 0.2rem;
  color: #d6d8da;
  text-align: left;
  font-size: smaller;
  /* border: 1px solid black; */
}

.dashboard-candidate-box .ant-select{
  background: #fff;
  height: 2rem;
  border-radius: 2px;
  box-shadow: 0px 1px 3px grey;
}

.sendInvititaion .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}
.sendInvititaion .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.candidateViewModal .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}
/* .candidateViewModal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
} */

.addCandidate .ant-modal-body{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.addCandidate .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}

.downloadModal .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
}
.downloadModal .ant-modal-body{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 0 0 5px 5px;
}
.sendInvititaion ::-webkit-scrollbar{
  width: 0.2rem;
  height: 0.1rem;

}

.sendInvititaion ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(234, 227, 227, 0.3);
}

.sendInvititaion ::-webkit-scrollbar-thumb {
  background-color: rgb(224, 220, 220);
  border-radius: 10px;
  height:0.5rem;
}
.question2{
  /* display: flex;
  flex-direction: column;
  font-weight: 600; */
  margin: 1% 3% 3% 3%;
  padding-left: 0.2rem;
  height: 60vh;
  overflow-y: scroll;
  border: 2px solid;
}

.question2::-webkit-scrollbar {
  /* width: 0.3rem; */
  display: none;
}

.question2::-webkit-scrollbar {
  height: 0.5rem;
  display: none;
}
 
.question2::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
}
 
.question2::-webkit-scrollbar-thumb {
  /* background-color: rgb(23, 20, 20); */
  /* border-radius: 10px;
  height:0.5rem; */
}
.jdPdf{
  position: relative;
  width: 100%;
  padding-right: 4px;
  display: flex;
  align-self: center;
  background-color:#fff;
  overflow-y: scroll;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.jdPdf .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
 margin-bottom: 5px;

}
.jdPdf .react-pdf__Page__annotations{
  display: none !important;

}
.jobTitleModal_container__Zhq1w{
    display: flex;
    flex-direction: column;
    margin: 2%;
}

.jobTitleModal_container__Zhq1w h1,h2,h3,span,h4,p{
    padding: 0;
    margin:0;
}

.jobTitleModal_container__Zhq1w span{
    color: #938c8c;
    font-size: 15px;
    font-weight: 500;
}

.jobTitleModal_container__Zhq1w>*{
    display: flex;
    flex-direction: row;
    grid-gap: 2%;
    gap: 2%;
    margin-bottom: 1rem;
}

.jobTitleModal_container__Zhq1w>*>*{
    flex: 1 1 50%;
}

.jobTitleModal_container__Zhq1w>*:nth-child(6){
    display: flex;
    flex-direction: column;
   
}

.jobTitleModal_container__Zhq1w>*:nth-child(1){
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    /* align-items: center; */
}

.jobTitleModal_container__Zhq1w>*:nth-child(1)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    grid-gap:1%;
    gap:1%; 
}

.jobTitleModal_container__Zhq1w>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color:#0086ff;
    font-size: 1.2rem;
    font-weight: bold;
}


.jobTitleModal_container__Zhq1w>*:nth-child(1)>*:nth-child(1){
    font-size: 0.7rem;
    font-weight: 700;
    color: #000000;
    /* flex: 1 1 3%; */
}

.jobTitleModal_container__Zhq1w >*:nth-child(5){
    display: flex;
    flex-direction: column;
}

.jobTitleModal_container__Zhq1w >*:nth-child(5)>*:nth-child(1){
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.4rem;
    
}

.jobTitleModal_container__Zhq1w >*:nth-child(5)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    grid-gap:10%;
    gap:10%;
}

.jobTitleModal_container__Zhq1w >*:nth-child(5)>*:nth-child(2)>*{
    display: flex;
    flex-direction: row;
}
.jobTitlePopover_container__343zs{
    display: flex;
    flex-direction: column;
}

.jobTitlePopover_container__343zs >*:nth-child(1){
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 1rem;
}

.jobTitlePopover_container__343zs >*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;

}

.jobTitlePopover_container__343zs >*:nth-child(1)>*>*:nth-child(1){
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #0086ff;

}

.jobTitlePopover_container__343zs >*:nth-child(1)>*:nth-child(2)>*:nth-child(1){  
    background-color: red;
}

.jobTitlePopover_listIem__2y9NS{ 
   font-size: 14px; 

}
.jobTitlePopover_listIem__2y9NS  li{
    list-style: none;
    padding-left: 0.5rem;
    border-left: 5px solid #0086ff ;
    margin-bottom: 0.3rem;
    cursor: pointer;

}

.jobTitlePopover_listIem__2y9NS .jobTitlePopover_inactive__3IcwT{
    border-left: 5px solid red ;
}

.jobTitlePopover_listIem__2y9NS li:hover{
    background-color: #caddee;
}

.institute_searchDiv__24d6-{
  height: 2.2rem;
  width:18rem;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
  }
  
  /* .searchDiv input{
    width:30rem;
    min-height: 30px;
    border:0;
  }
  
  .searchDiv input:active,
  .searchDiv input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv img{
    width:18px;
    height:18px;
    margin-left: 1rem;
  } */

  .institute_table__2EhRd{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .institute_table__2EhRd::-webkit-scrollbar {
    display: none;
  }


  .institute_rowStyle__1T7Ay{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }

  .institute_tableStyle__393Ow{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.institute_headerName__1cztM{
  flex-direction: row;
  flex-wrap: nowrap;
  display:flex;
  flex-direction:row;
}

.institute_headerName__1cztM span{
 
  /* font-size: 1rem; */
  font-weight: bold;
  /* color: #6f6f6f; */
}
  


.ant-modal-content {
  border-radius: 15px;
}

.college-modal-field div label{
  color:#6f6f6f !important;
  text-align: left;
  font: normal normal 400 10px/14px Open Sans!important;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

.sub-heading{
  text-align: left;
  font: normal normal bold 12px/14px Open Sans;
  letter-spacing: 0px;
  color: #161616;
  opacity: 1;
  margin-left: 12px;
}

.college-modal-scroll{
  overflow-y: "scroll";
  max-height: 400px;
}

/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

::-webkit-scrollbar-thumb {
  background: #fbdfdf; 
}

::-webkit-scrollbar-thumb:hover {
  background: #fbdfdf; 
} */

.donwload-icon-css{
  height: 25px;
  width: 25px;
}

.template-list-scroller{
  max-height: 400px;
  overflow-y: scroll;
}

.selected_template{
  border: 2px solid blue;
}

.cursor-pointer{
  cursor: pointer;
}

.custom-card .ant-card-body {
  padding: 0px !important;
}

.custom-card-footer{
  min-height: 93px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 11px;
}

.custom-card-bg{
  background: #ffe0e0;
}

#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}
.addCollege_scrollbody__4kdEB {
    max-width: 100%;
    overflow-y: scroll;
    max-height: 65.5vh;
    border-radius: 0.5rem;
    margin-top: -0.4rem;
  }
  
  .addCollege_scrollbody__4kdEB::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .addCollege_scrollbody__4kdEB::-webkit-scrollbar {
    height: 0.5rem;
  }
  
  .addCollege_scrollbody__4kdEB::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
  
  .addCollege_scrollbody__4kdEB::-webkit-scrollbar-thumb {
    background-color: rgb(224, 220, 220);
    border-radius: 10px;
    height: 0.5rem;
  }

  .addCollege_scrollbody__4kdEB h3 {
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 2px solid rgb(124, 122, 122);
    /* margin-bottom: 1rem; */
  }
  
  .addCollege_scrollbody__4kdEB input {
    height: 2.3rem;
  }
  
  .addCollege_scrollbody__4kdEB span {
    font-size: 12px;
  }
.attachment-link{
    padding: 5px 10px 5px 10px;
    color: #0086FF;
    background: #EEF7FF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin : 10px;
}

.email-modal .ant-modal-content{
    border-radius: 0px !important;
}

.quill-editor-menu{
    position: relative !important;
    display: flex !important;
    flex-direction: column-reverse  !important;
}

.quill-editor-menu div{
  border :0px !important;
}

.to-select-border .ant-select-selector{
    border: 0px !important;
}
.ql-editor a {
  margin-left: 10px; 
}

.subject-input-border{
    border: 0px !important;
}
.ql-custom-link {
  margin-left: 50px;
}
.custom-link {
  margin-left: 100px;
}

.vertical-align-middle{
    vertical-align: -webkit-baseline-middle;
    letter-spacing: 0px;
    color: #6F6F6F;
}

.custom-row-border{
    border-bottom: 1px solid #d0cfcf  !important;
}

.d-flow-root{
    display: flow-root;
}

.email-template-page-body{
  position: relative;
}

.email-template-page-body-scroller{
    max-height: 815px;
    overflow-y: scroll;
  }
  .email-template-page-body-scroller::-webkit-scrollbar {
    display: none;
}

.feedback-page-body-scroller{
  max-height: 780px;
  overflow-y: scroll;
}
.feedback-page-body-scroller::-webkit-scrollbar {
  display: none;
}

.card-subtitle{
 letter-spacing: 0px;
 color: #6F6F6F;
 opacity: 1;
 font: normal normal normal 14px/20px Open Sans;
}


embed::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
embed::-webkit-scrollbar-track {
    background: #f1f1f1  !important; 
  }
   
  /* Handle */
embed::-webkit-scrollbar-thumb {
    background: #fbdfdf; 
  }
  
  /* Handle on hover */
embed::-webkit-scrollbar-thumb:hover {
    background: #fbdfdf; 
  }

  embed::scrollbar {
    background: #fbdfdf; 
  }


  .d-none{
    display: none;
  }

.page-header{
  height: 90px !important;
}

.page {
  height: 100% !important;
  margin: 0px 45px 39px 22px  !important;
}

.cursor-pointer{
  cursor: pointer;
}

.custom-card .ant-card-body {
  padding: 0px !important;
}

.custom-card-footer{
  min-height: 93px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 11px;
}

.custom-card-bg{
  background: #ffe0e0;
}

.cross-css{
  height: 10px;
  width: 10px;
  vertical-align: super;
}

.emailTemplate_header__13hmj{
  display: flex;
  align-items: center;
}

.emailTemplate_header__13hmj h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

.emailTemplate_container__1zCBE{
  display: flex;
  flex-direction: column;
  height: 82vh;
  grid-gap: 3%;
  gap: 3%;
  padding: 0.7% 2% 2% 2%;
}

.emailTemplate_container__1zCBE > *:nth-child(1)
{
  flex:1 1 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 5%;
  grid-gap: 64%;
  gap: 64%;
}

.emailTemplate_container__1zCBE > *:nth-child(1) >*:nth-child(1)
{
  flex: 1 1 26%;
  height: 2.1rem;
  width: 18.5rem;
}

.emailTemplate_container__1zCBE > *:nth-child(1) >*:nth-child(2)
{
  flex: 1 1 12%;
  height: 100%;
  display: flex;
}

.emailTemplate_container__1zCBE > *:nth-child(1) >*:nth-child(2) button{
  width: 100%;
  height: 100%;
  font-weight: bold;
  border-radius: 4px;
}

.emailTemplate_container__1zCBE > *:nth-child(2)
{
  flex:1 1 94%;
  display: grid;
  grid-gap: 1.1rem;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: 20rem;
  overflow: auto;
  padding: 0.1rem;
}

.emailTemplate_container__1zCBE > *:nth-child(2)::-webkit-scrollbar {
  width: 0.3rem;
}

.emailTemplate_container__1zCBE > *:nth-child(2)::-webkit-scrollbar {
  height: 0.3rem;
}
 
.emailTemplate_container__1zCBE > *:nth-child(2)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
}
 
.emailTemplate_container__1zCBE > *:nth-child(2)::-webkit-scrollbar-thumb {
  background-color: rgb(174, 170, 170);
  border-radius: 10px;
  height:0.3rem;
}

.emailTemplate_container__1zCBE > *:nth-child(2)>*{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;  
   box-shadow: 2px 2px 24px #ACB1B75C;
   outline: 1px solid rgba(227, 224, 224, 0.2);
  padding: 1%;
  cursor: pointer;
  border-radius: 5px;
  min-width: 20rem;
  border: 0;
}

.emailTemplate_container__1zCBE > *:nth-child(2)>*:hover{
  background-color: rgb(247, 245, 245);
}

.emailTemplate_createCard__lSAMe > *:nth-child(1) img{
 margin: 2.1rem auto 1rem auto;
}
.emailTemplate_createCard__lSAMe > *:nth-child(2) h3{
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  margin: 0.5rem auto;
  
}

.emailTemplate_createCard__lSAMe > *:nth-child(2) span{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.emailTemplate_createCard__lSAMe > *:nth-child(3){
 font-weight: 800;
 margin-top: 1rem ;
}

.emailTemplate_createCard__lSAMe > *:nth-child(3) button{
  width: 7rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
  height: 2.3rem;
 }


.emailTemplate_spinStyle__QGuJH{
  margin: 20% auto;
}

.emailTemplate_deleteModal__28_uQ{
  display: flex;
  flex-direction: column;
  margin: 3%;
  justify-content: center;
  grid-gap: 10%;
  gap: 10%;
}

.emailTemplate_deleteModal__28_uQ >*:nth-child(1){
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3%;
}

.emailTemplate_deleteModal__28_uQ >*:nth-child(2){
  display: flex;
  flex-direction: row;
  grid-gap: 5%;
  gap: 5%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  width: 50%;
}

.emailTemplate_deleteModal__28_uQ >*:nth-child(2) button{
  width: 100%;
  font-weight: 700;
}

.emailTemplate_deleteModal__28_uQ >*:nth-child(2)>*:nth-child(2){
  border: 1.5px solid red;
  color: red;
}
.videos_searchDiv__3134a{
    width:18rem;
    height: 2.1rem;
    border-radius: 4px;
}
.videos_searchDiv__3134a input{
    height: 1.4rem;
    border:0;
} 
.videos_searchDiv__3134a input:active,
.videos_searchDiv__3134a input:focus{
   outline: none;
   border:0;
}
.videos_scrollDiv__JTERX{
    /* border: 2px solid red; */
    overflow-y: scroll;
    max-height: 80vh;
}
.videos_scrollDiv__JTERX::-webkit-scrollbar {
    display: none;
  }
.candidate_header__39Ixo{
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1% 0 -0.5% 2%;
}
.candidate_container__3stry{
   
    overflow-y: scroll;
    background-color: #F8F8F8;
    padding:0 2% 2% 2%;
    margin-top: 2%;
    width: 100%;
    box-sizing:border-box;
    height:80vh;
}

.candidate_container__3stry::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .candidate_container__3stry::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .candidate_container__3stry::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
    
   .candidate_container__3stry::-webkit-scrollbar-thumb {
    background-color: rgb(223, 221, 221);
    border-radius: 10px;
    height:0.5rem;
  } 
  
  .candidate_searchDiv__CQUMa{
    height: 2.3rem;
    width:17rem;
    border-radius: 4px;
    grid-gap:0.3rem;
    gap:0.3rem;
  }
  
  /* .searchDiv input{
    width:30rem;
    min-height: 30px;
    border:0;
  }
  
  .searchDiv input:active,
  .searchDiv input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv img{
    width:18px;
    height:18px;
    margin-left: 1rem;
  } */

.candidate_container__3stry >*:nth-child(2)
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 3rem;
    gap: 3rem;
    margin: 1rem 0;
}

.candidate_container__3stry >*:nth-child(2)>*:nth-child(1)
{
    flex: 1 1 15%;
}

.candidate_container__3stry >*:nth-child(2)>*:nth-child(2)
{
    flex: 1 1 15%;
}

.candidate_container__3stry >*:nth-child(2)>*:nth-last-child(1){
    display: flex;
    flex-direction: row;
    grid-gap: 1.7rem;
    gap: 1.7rem;
}


.candidate_filter__2KfXP{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 500;
}

.candidate_filter__2KfXP>*{
    min-width: 14rem;
}

.candidate_filter__2KfXP>*:nth-child(1){
    min-width: 14rem;
    max-height: 250px;
    padding: 1rem;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
    grid-gap: 0.7rem;
    gap: 0.7rem;  
}

.candidate_filter__2KfXP>*:nth-child(2){
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
}

.candidate_filter__2KfXP>*:nth-child(2)>*{
    flex: 1 1 50%;
    font-weight: 600;
}

.candidate_filter__2KfXP>*:nth-child(2)>*:nth-child(2)
{
    border: 0;
}

.candidate_export__2IvfR{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.candidate_export__2IvfR h2{
    margin: 0rem auto;
    font-weight: 700;
}

.candidate_export__2IvfR button{
    margin: 1rem auto;
    width: 6rem;
    font-weight: 600;
}
.checkSlot_container__J209Z{
    display: flex;
    flex-direction: column;
    margin:1.3rem;
    font-weight: 600;
    font-family: "Open Sans";
    color: #000000;
  

}

.checkSlot_container__J209Z h1{
    display: flex;
    margin: 1rem auto;
    color: #161616;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Open Sans";
}

.checkSlot_container__J209Z label{
    font-family: "Open Sans";
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
}

.checkSlot_slot__1EKDR{
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    flex-wrap: wrap;
   
}
 .checkSlot_container__J209Z .checkSlot_slot__1EKDR label{
    color:#6F6F6F;
    font-weight: 500;
} 
.checkSlot_slot__1EKDR > *{
    display: flex;
   min-width: 12rem;
  
    
} 

.checkSlot_timePicker__28XMC{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    align-items: flex-end;

}

.checkSlot_timePicker__28XMC >*{
    width: 100%;
}
.checkSlot_time__3sGry{
    width:3rem;
}

.checkSlot_addButton__15gW7{
    width:7rem;
    height:2.2rem;
    border:2px solid #0086FF;
    color: #ffffff;
    background-color: #0086FF;
    font-size: 1rem;
    font-family: "Open Sans";
    font-weight: 600;

}

.checkSlot_buttonDiv__NtN8L{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.checkSlot_buttonDiv__NtN8L button{
    width:7rem;
    height:2.2rem;
    border:2px solid #0086FF;
    color: #0086FF;
    font-size: 1rem;
    font-weight: 600;
}



.checkSlot_buttonDiv__NtN8L .checkSlot_select__3xRzf{
    color: #ffffff;
    background-color: #0086FF;
    
}
.schedule_container__1SxEL{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    font-weight: 600;
    color: #161616;
    overflow: auto;
}


.schedule_container__1SxEL h1{
    margin: 0 auto 0.3rem auto;
    font-weight: bold;
}

.schedule_container__1SxEL>*:nth-child(2){
    height:80vh;
    margin-bottom: 1rem;
    overflow-y: auto;
    /* border: 2px solid; */
}


.schedule_container__1SxEL>*:nth-child(2)::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .schedule_container__1SxEL>*:nth-child(2)::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .schedule_container__1SxEL>*:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .schedule_container__1SxEL>*:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: rgb(223, 221, 221);
    border-radius: 10px;
    height:0.5rem;
  }


.schedule_mode__K_4pv{
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap:1rem;
    gap:1rem;
}

.schedule_mode__K_4pv > *{
    width: 50%;
}

.schedule_picker__3rmjw{
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap:1rem;
    gap:1rem;
}

.schedule_picker__3rmjw > *{
    width: 100%;
}

.schedule_checkSlot__2ZZb2{
    display: flex;
    justify-content: right;
}

.schedule_checkSlot__2ZZb2 button{
    color:#1188f8  ;
    font-size: 1rem;
    font-weight: 700;
}

.schedule_buttonDiv__3_OSS{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.schedule_buttonDiv__3_OSS button{
    width:7.2rem;
    height:2.2;
    border:1.5px solid #1188f8 ;
    border-radius: 2px;
    color: #1188f8  ;
    font-weight: 600;
}

.schedule_buttonDiv__3_OSS .schedule_schedule__tmhkX{
    color: #ffffff;
    background-color: #1188f8  ;
}

.regLinkModal_container__3DPLN{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    grid-gap:1rem;
    gap:1rem;
}

.regLinkModal_container__3DPLN>*{
    flex: 1 1 100%;
}

.regLinkModal_container__3DPLN input{
    height: 2.5rem;
    margin-top: 1rem;
    border: 1.3px solid rgb(212, 210, 210);
    background-color: rgb(255, 255, 255);
}

.regLinkModal_container__3DPLN>*:nth-child(1)
{
    display: flex;
    flex-direction: column;
    grid-gap:0.8rem;
    gap:0.8rem;
}

.regLinkModal_container__3DPLN>*:nth-child(1)>*:nth-child(2)
{
    width: 7rem;
    margin-bottom: 1rem;
    color: #1890FF;
    font-size: 14px;
    font-weight: 600;
    border: 1.5px solid #1890FF;
}

.regLinkModal_container__3DPLN>*:nth-child(1)>*:nth-child(3)
{
    height: 10rem;
    border: 1.2px solid rgb(194, 189, 189);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: left;
    padding: 0.5rem;
    overflow-y:auto;
    font-weight: 600;
    font-size: 1rem;
    grid-gap:0.6rem;
    gap:0.6rem;
}

.regLinkModal_container__3DPLN>*:nth-child(1)>*:nth-child(3)>*>*{
    border:0;
    width: 3rem;
    height: 1.8rem;
    font-weight: 700;
}

.regLinkModal_container__3DPLN>*:nth-child(1)>*:nth-child(3)>*>*>*{
    font-weight: bold;
    color: red;
    width: 1rem;
    height: 1rem;
}

.regLinkModal_container__3DPLN>*:nth-child(2) button{
    background-color:#1890FF;
    color: #ffffff;
    border: 0;
    font-weight: 600;
    font-size: 1rem;
}

.regLinkModal_container__3DPLN>*:nth-child(2){
    display: flex;
    justify-content: right;
    align-items: center;
}
/* src/pages/college-dashboard/style.css.ant-modal-content {
    border-radius: 15px;
  } */
  
  .college-modal-field div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 10px/14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  .college-modal-field{
    padding: 10px 0 0 !important;
    margin: 0px;
  }

  /* .college-modal-field-sm div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  } */

  .college-modal-field-sm div label{
    color:black !important;
    text-align: left;
    font: normal normal 400 14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }

  .college-modal-field-sm .ant-form-item-label{
    padding: 10px 0 0 !important;
  }

  .college-modal-field-sm{
    margin: 0px  !important;
  }
  
  .sub-heading{
    text-align: left;
    font: normal normal bold 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #161616;
    opacity: 1;
    margin-left: 12px;
  }
  
  .college-modal-scroll{
    max-width: 100%;
    overflow-y: scroll;
    max-height: 10vh;
    border-radius: 0.5rem;
    margin-bottom: 4%;
  }
  
  /* ::-webkit-scrollbar {
    width: 5px;
    scrollbar-width : 5px !important;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; sort-popover
  }
   
  ::-webkit-scrollbar-thumb {
    background: #fbdfdf; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #fbdfdf; 
  } */

  div.horizotal-scroll-container::-webkit-scrollbar{
    width: 5px !important;
    scrollbar-width : 10px !important;
    height: 8px !important;
  }

  div.horizotal-scroll-container::-webkit-scrollbar-thumb{
    background: black !important; 
  }



  div.horizotal-scroll-container {
    background-color: white !important;
    overflow: auto !important; 
    white-space: nowrap  !important;
    display: flex;
  }
    
  /* div.scrollmenu a:hover {
    background-color: #777;
  } */

  .number-container-css{
    padding: 6px;
    font-size: 9px;
    border-radius: 50%;
    display: inline-block;
    margin: 8px;
  }

  .number-container-lable-css{
    height: 12px;
    width: 12px;
    display: inline-block;
    text-align: center;
  }

  .active-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid black;
    margin: 7px;
    border-radius: 50%;
  }


  .inactive-vacancy-option{
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    border: 2px solid #acacb7;
    margin: 7px;
    border-radius: 50%;
  }



  .other-label-container-css{
    padding: 2px 10px 2px 10px;
    font-size: 13px;
    border-radius: 9px;
    border: 2px solid #dbb5b5;
    display: inline-block;
    margin: 10px;
  }

  #components-page-header-demo-content .image {
    display: flex;
    align-items: center;
    margin: 0 0 0 60px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .image {
    margin: 0 60px 0 0;
  }
  
  #components-page-header-demo-content .example-link {
    margin-right: 16px;
    line-height: 24px;
  }
  [data-theme='compact'] #components-page-header-demo-content .example-link {
    line-height: 20px;
  }
  #components-page-header-demo-content .example-link-icon {
    margin-right: 8px;
  }
  
  [data-theme='compact'] #components-page-header-demo-content .example-link-icon {
    width: 20px;
    height: 20px;
  }
  
  #components-page-header-demo-content .ant-page-header-rtl .example-link {
    float: right;
    margin-right: 0;
    margin-left: 16px;
  }
  #components-page-header-demo-content .ant-page-header-rtl .example-link-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  
  @media (max-width: 768px) {
    #components-page-header-demo-content .image {
      flex: 100% 1;
      margin: 24px 0 0;
    }
  }


  .page-header{
    height: 140px;
  }

  .page-body{
    height: calc(100% - 140px);
    background: white !important;
  }

  .page{
    height: 100% !important;
    margin: 35px 46px 50px 10px;
  }

  .pdf-logo{
    height: 30px;
    width: 30px;
  }



  /* .ant-form-item .ant-upload.ant-upload-drag{
    background: #fafafa;
    width: fit-content;
  } */

  .file-upload-dragger span{
    padding: 5px !important ;
    margin-left: 25px  !important;
  }

  .file-upload div.ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content{
    display: block !important;
    flex: unset !important;
  }


  .pdf-icon-container{
    cursor: pointer;
    background: #E6F3FF;
    padding: 12px;
    border-radius: 13px;
  } 

  .pdf-icon-container div span{
    cursor: pointer;
  } 

  .text-center{
    text-align: center;
  }

  .text-align-end{
    text-align: end;
  }

  .ant-upload.ant-upload-select-picture-card{
    width: 135px !important;
    height: 157px !important;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 2px dashed #7c48d9 !important;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }

  .anticon-plus-circle svg{
    height: 30px !important;
    width: 30px !important;
  }

  .custom-search-icon svg{
    height: 20px;
    width: 20px;
    color: #9b9be5;
  }

  .tab{
    padding: 0px 10px 10px 10px;
    font: normal normal bold 18px/26px Open Sans;
    display: inline-block  !important;
    color : #6f6f6f;
    cursor: pointer;
  }

  .active{
    border-bottom : 2px solid #0086ff  !important;
    color : #0086ff !important;
  }

  .create-jd-custom-modal .ant-modal-footer{
    background-color: white;
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 5px 5px;
  }
  .create-jd-custom-modal .ant-modal-header{
    background-color: #ffff;
    display: flex;
    justify-content: flex-start;
    border-radius: 5px 5px 0 0;
  }

.jdViewModal_modalContent__bR5EQ{
    height: 400px;
    overflow-y: auto;
}
.createJdModal_scrollbody__3juee {
    max-width: 100%;
    overflow-y: scroll;
    max-height: 60vh;
    border-radius: 0.5rem;
    padding:0;
    margin-bottom: 1rem;
    padding-inline:1rem ;
  }
  
  .createJdModal_scrollbody__3juee::-webkit-scrollbar {
    width: 0.4rem;
  }
  /* .hide-upload-tooltip .ant-upload.ant-upload-drag .ant-upload-hint {
    display: none;
  } */
  
  .createJdModal_scrollbody__3juee::-webkit-scrollbar {
    height: 0.5rem;
  }
  
  .createJdModal_scrollbody__3juee::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(125, 121, 121, 0.3);
  }
  
  .createJdModal_scrollbody__3juee::-webkit-scrollbar-thumb {
    background-color: rgb(190, 184, 184);
    border-radius: 10px;
    height: 0.5rem;
  }

  .createJdModal_scrollbody__3juee h3 {
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 2px solid rgb(124, 122, 122);
    margin-bottom: 1rem;
  }
  
  .createJdModal_scrollbody__3juee input {
    height: 2.3rem;
  }
  
  .createJdModal_scrollbody__3juee span {
    font-size: 12px;
  }
.jobListFresher_jobCard__Yitmo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap:2rem;
    gap:2rem;
    flex: 1 1 70%;
    margin-right: 3%;
    min-width: 35rem;
   
  }

  .jobListFresher_jobCard__Yitmo>*{
    display: flex;
    flex-direction: column;
    flex: 0 0 35%;
  }

  .jobListFresher_jobCard__Yitmo>*>*:nth-child(1){
    font-size: 1.1rem;
    font-weight: 690;
    text-align: center;
   
  }

  
  .jobListFresher_jobCard__Yitmo>*>*:nth-child(2){
    min-height: 2rem;
    background-color: #ffffff;
    padding: 0.5rem;
    flex-grow: 1;
    border-radius: 8px;
    box-shadow: 0px 1px 5px grey;
    min-width: 5rem;
  }

  .jobListFresher_jobCard__Yitmo>*:nth-child(2)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    font-weight: 600;
    font-size: 14px;
  }

  .jobListFresher_jobCard__Yitmo>*:nth-child(2)>*:nth-child(2)>*{
    min-width: 5.8rem;
   
  }

  .jobListFresher_jobCard__Yitmo>*:nth-child(1)>*:nth-child(2){
    display: flex;
    color: #D3D3D3;
    font-weight: 600;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  .jobListFresher_jobCard__Yitmo>*:nth-child(1)>*:nth-child(2)>*:nth-child(1){
    color: #000000;
  }



  .jobListFresher_jobCard__Yitmo>*:nth-child(3)>*:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
  }

  
  .jobListFresher_searchDiv__3JByY{
    /* outline: #d1cfcf; */
    border: 0;
    border-radius: 5px;
  }
  .jobListFresher_searchDiv__3JByY >*{
    /* background-color: hsl(0, 5%, 96%); */
    width: 18rem;
    height: 2.1rem;
  }

  .jobListFresher_searchDiv__3JByY>*:nth-child(1)>*:nth-child(2){
    padding-left: 0.5rem;
    border-radius: 5px;
    max-height: 1.7rem;
  }

.jobTitlePopover_container__3Ajm5{
    display: flex;
    flex-direction: column;
}

.jobTitlePopover_container__3Ajm5 >*:nth-child(1){
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 1rem;
}

.jobTitlePopover_container__3Ajm5 >*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;

}

.jobTitlePopover_container__3Ajm5 >*:nth-child(1)>*>*:nth-child(1){
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #0086ff;

}

.jobTitlePopover_container__3Ajm5 >*:nth-child(1)>*:nth-child(2)>*:nth-child(1){  
    background-color: red;
}

.jobTitlePopover_listIem__3p-PZ{ 
   font-size: 14px; 

}
.jobTitlePopover_listIem__3p-PZ  li{
    list-style: none;
    padding-left: 0.5rem;
    border-left: 5px solid #0086ff ;
    margin-bottom: 0.3rem;
    cursor: pointer;

}

.jobTitlePopover_listIem__3p-PZ .jobTitlePopover_inactive__eehjq{
    border-left: 5px solid red ;
}

.jobTitlePopover_listIem__3p-PZ li:hover{
    background-color: #caddee;
}
.FeedBackManagement_container__MYFxJ {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 0 1.6rem;
  height: 80vh;
}

.FeedBackManagement_innerContainer__1buGx {
  width: 100%;
  height: 78vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.FeedBackManagement_navBar__1JyhJ {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 100%;
}

.FeedBackManagement_searchDiv__1IACi {
  width: 18rem;
  height: 2rem;
  justify-self: flex-end;
  margin-right: 1.5rem;
  height: 2.2rem;
}

.FeedBackManagement_firstPageCard__3T4-S {
  background: #ffffff;
  border-radius: 10px;
  margin: 2rem;
  width: 50rem;
  height: 70vh;
  box-shadow: 0 0.5rem 1rem #00000029;
}
.FeedBackManagement_firstPageCard__3T4-S h1 {
  margin-left: 7rem;
  margin-top: 1.5rem;
  font-weight: 900;
  font-size: 2rem;
}

/* .createFeedback .ant-modal-footer{
  background-color: white;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
}

.createFeedback .ant-modal-header{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px 5px 0 0;
} */
.FeedBackManagement_createFeedback__2HJ9y .FeedBackManagement_ant-modal-content__30t6U{
  background-color: #ffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
}



/* .searchDiv{
    display: flex;
    flex-direction: row;
    gap:2%;
    margin-bottom: 1.2rem;
}

.searchDiv >*:nth-child(1){
    flex: 1 1 20%;
    height: 2.3rem;
}
.searchDiv >*:nth-child(2){
    flex: 1 1 70%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    justify-content: right;

} */

.FeedBackManagement_searchDiv__1IACi > *:nth-child(2) button {
  width: 10rem;
  max-height: 2.5rem;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.FeedBackManagement_searchDiv__1IACi > *:nth-child(2) .FeedBackManagement_deleteBtn__2IfV4 {
  color: #ff9797;
  border: 1px solid #ff9797;
}

.FeedBackManagement_modalContainer__3BVRP {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  color: #161616;
  margin: 1.5rem;
}

.FeedBackManagement_modalContainer__3BVRP h1 {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.FeedBackManagement_feedback-page-body-scroller__1W7UA {
  max-height: 815px;
  overflow-y: scroll;
}
.FeedBackManagement_feedback-page-body-scroller__1W7UA::-webkit-scrollbar {
  display: none;
}

.FeedBackManagement_header__10T_2 {
  display: flex;
  align-items: center;
}

.FeedBackManagement_header__10T_2 h1 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 1.7rem;
}

.FeedBackManagement_buttonDiv__2K0Qf {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2.2rem;
  margin-bottom: 0.4rem;
  justify-content: center;
}

.FeedBackManagement_feedbackScroll__DyQoQ {
  height: 700px;
  display: flex;
}

.FeedBackManagement_feedbackScroll__DyQoQ::-webkit-scrollbar {
  display: none;
}

.FeedBackManagement_buttonDiv__2K0Qf button {
  border-radius: 0.5rem;
  width: 8rem;
  height: 2.5rem;
  border: 1.5px solid #4daaff;
  color: #4daaff;
  font-weight: bold;
  font-size: 1rem;
}

.FeedBackManagement_buttonDiv__2K0Qf .FeedBackManagement_createButton__2fwlk {
  background-color: #4daaff;
  border: 1.5px solid #4daaff;
  color: #ffffff;
}

.FeedBackManagement_creatFeedbackCard__3SR5w {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 1%;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  border-radius: 5px;
}

.FeedBackManagement_creatFeedbackCard__3SR5w > * {
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
/* 
.creatFeedbackCard > *:nth-child(1) {
  flex: 1 1 45%;
}

.creatFeedbackCard > *:nth-child(1) img {
  width: 28%;
  height: 70%;
}
.creatFeedbackCard > *:nth-child(2) {
  flex: 1 1 17%;
  font-weight: bold;
}
.creatFeedbackCard > *:nth-child(3) {
  flex: 1 1 20%;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
}
.creatFeedbackCard > *:nth-child(4) {
  flex: 1 1 18%;
} */

.FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(4) button {
  width: 40%;
  height: 90%;
  font-weight: bold;
}

.FeedBackManagement_feedbackDetailsCard__14G07 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* max-height: 60vh; */
  border-radius: 5px;
  padding-top: 0;
}

.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(1) {
  width: 95%;
  display: flex;
  flex-direction: column;
  height: 30vh;
  /* border:1px solid black */
}

.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(1) > *:nth-child(1) {
  /* flex: 1 1 30%; */
  display: flex;
  justify-content: right;
  padding: 1% 2% 0 0;
  width: 100%;
}
.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(1) > *:nth-child(2) {
  /* flex: 1 1 45%; */
  display: flex;
  height: 25vh;
  justify-content: center;
  align-items: center;
}

/* .feedbackDetailsCard > *:nth-child(1) > *:nth-child(2) img {
  width: 25vh;
  height: 20vh;
} */

.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) {
  flex: 1 1 28%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) Button {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  text-align: center;
}

.FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
}

.FeedBackManagement_cardContainer__2Dn_9 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-between; */
  max-height: 75vh;
  flex: 1 1 100%;
  overflow-y: scroll;
  grid-gap: 1rem;
  gap: 1rem;
}

.FeedBackManagement_cardContainer__2Dn_9 > * {
  flex: 0 1 32%;
  /* width: 20rem; */
  margin-bottom: 0.5rem;
  height: 50vh;
}

.FeedBackManagement_cardContainer__2Dn_9::-webkit-scrollbar {
  width: 0.3rem;
}


.FeedBackManagement_cardContainer__2Dn_9::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(234, 227, 227, 0);
}

.FeedBackManagement_cardContainer__2Dn_9::-webkit-scrollbar-thumb {
  background-color: rgb(234, 227, 227, 0);
  border-radius: 10px;
  height: 0.5rem;
}

@media (max-width: 996px) {
  /* .creatFeedbackCard{
          width: 213px;
           height: 213px;
      }
      
      .feedbackDetailsCard
  {
      width: 213px;
      height: 213px;   
  } */

  .FeedBackManagement_creatFeedbackCard__3SR5w h2 {
    font-size: 1.1rem;
  }

  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 997px) and (max-width: 1196px) {
  /* .creatFeedbackCard{
          width: 203.5px;
           height: 203.5px;
      }
      
      .feedbackDetailsCard
  {
      width: 203.5px;
      height: 203.5px;  
  } */

  .FeedBackManagement_creatFeedbackCard__3SR5w h2 {
    font-size: 1.1rem;
  }

  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(4) button {
    font-size: 0.7rem;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.6rem;
    margin: 0;
  }
}

@media (min-width: 1196px) and (max-width: 1493px) {
  /* .creatFeedbackCard{
        width: 242.5px;
         height: 242.5px;

    }
    
    .feedbackDetailsCard
{
    width: 242.5px;
    height: 242.5px;  
} */

  .FeedBackManagement_creatFeedbackCard__3SR5w h2 {
    font-size: 1.2rem;
  }

  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(3) {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 0.7rem;
    margin: 0;
  }
}

/* @media (min-width:1494px) and (max-width:1659px)
{

    .creatFeedbackCard{
        width: 275px;
         height: 275px;
         

    }
    .feedbackDetailsCard
    {
        width: 275px;
        height: 275px;
    }
} */

@media (min-width: 1660px) and (max-width: 1867px) {
  /* .creatFeedbackCard{
          width: 315px;
           height: 315px;
      }
      
      .feedbackDetailsCard
  {
      width: 315px;
      height: 315px;  
  } */

  .FeedBackManagement_creatFeedbackCard__3SR5w h2 {
    font-size: 1.4rem;
  }

  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}

@media (min-width: 1867px) {
  /* .creatFeedbackCard{
        width: 364.7px;
         height: 364.7px;
    }
    
    .feedbackDetailsCard
{
    width: 364.7px;
    height: 364.7px;  
} */

  .FeedBackManagement_creatFeedbackCard__3SR5w h2 {
    font-size: 1.4rem;
  }

  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
  }
  .FeedBackManagement_creatFeedbackCard__3SR5w > *:nth-child(4) button {
    font-size: 0.9rem;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0;
  }

  .FeedBackManagement_feedbackDetailsCard__14G07 > *:nth-child(2) h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
}

/* .input::-webkit-input-placeholder{
    color:black;
    font-weight: 700;
} */

.feedbackRound_questionsCol__1H-pF::-webkit-scrollbar{
    display:none;
}
.ql-editor {
    /* border-top: -50px; */
    border: 0px;
    /* width: 100%; */
    /* margin-right: -30px; */
    /* box-sizing: border-box; */
    box-shadow: none;
    /* margin-top: -10px; */
    border: 0.1px solid;
    min-height: 10px;
    /* margin-top: 70px; */


}


.ql-toolbar.ql-snow {
    border: 0px solid #ccc;
    /* margin-top: -30px; */
    margin-left: -13px;
    margin-bottom: 15px;



}
.candidateCard_container__artuF {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
  width: 100%;
}
.candidateCard_ListBodyContainer__1eP7d::-webkit-scrollbar{
  display: none;
}
.candidateCard_container__artuF > * {
  flex: 1 1 40%;
}
.candidateCard_container__artuF::-webkit-scrollbar{
  display: none;
}
@media(max-width:700px){
  .candidateCard_container__artuF {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0.5rem 0;
    border: 2px solid;
    width: 100%;
  }
  .candidateCard_ListBodyContainer__1eP7d::-webkit-scrollbar{
    display: none;
  }
  .candidateCard_container__artuF > * {
  }
}
/* .container > :nth-child(5)
{
    background-color: #ffffff;
  
}

.container > :nth-child(6)
{
    background-color: #ffffff;
  
}

.container > :nth-child(4)
{
    background-color: #ffffff;
  
} */

.candidateCard_container__artuF h3 {
  font-weight: bold;
}

.candidateCard_container__artuF h4 {
  font-weight: 700;
}

.candidateCard_jobTitleSelect__22mKs>*:nth-child(1)>*:nth-child(1){
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 10rem;
  overflow-x:auto;
}

.candidateViewModal_header__3xT35{
    display: flex;
    border:2px solid red;
    grid-gap:1rem;
    gap:1rem;
    flex-direction: row;
    
    align-items: center;
}

.candidateViewModal_header__3xT35 img{
    width:60px;
    height:60px;
}

.candidateViewModal_header__3xT35 h1{
    font-size: 1.5rem;
    font-weight: bold;
}


.candidateViewModal_formControl__2gLu6{
    margin:0;
    padding: 0 0.8rem;
    height:50vh;
    overflow-y:scroll;
}


.candidateViewModal_formControl__2gLu6::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .candidateViewModal_formControl__2gLu6::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .candidateViewModal_formControl__2gLu6::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .candidateViewModal_formControl__2gLu6::-webkit-scrollbar-thumb {
    background-color: rgb(215, 212, 212);
    border-radius: 10px;
    height:0.5rem;
  }
  .candidateViewModal_ListBodyContainer__1Znwt::-webkit-scrollbar{
    display: none;
  }

.candidateViewModal_scrollTab__jZUuD::-webkit-scrollbar{
  display: none;
}

.candidateViewModal_nameDiv__3v21o{
    display: flex;
    flex-direction: row;
    align-items: center;
    border:2px solid red;
    grid-gap:1rem;
    gap:1rem;
}

.candidateViewModal_nameDiv__3v21o span{
    font-weight: 600;
}

.candidateViewModal_edit__20mu_{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    font-weight: 600;
}
.newCandidate_candidateTable__3gxbb {
  width: 100%; 
  display: flex;
  flex-direction: column;
}

.newCandidate_candidateTable__3gxbb ::-webkit-scrollbar{
    width: 0.2rem;
    height: 0.2rem;
}
.newCandidate_ListBodyContainer__12CzK::-webkit-scrollbar{
  display: none;
}

.newCandidate_candidateTable__3gxbb ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }

  .newCandidate_candidateTable__3gxbb ::-webkit-scrollbar-thumb {
    background-color: rgb(224, 220, 220);
    border-radius: 10px;
    height:0.5rem;
  }
.feedback-form {
  margin-bottom: 5%;
  /* margin-top:5%; */
}

.feedback-left-affix {
  position: fixed;
  left:0%; 
  width: 50%; 
  text-align: center;
  padding: -10px;
  margin-top:0%;
}

.feedback-left-affix > * {
  margin: 20px;
  border: 2px solid #e7eaed;
  border-radius: 10px;
}

/* space between candidate Name and round */
.feedback-left-affix > * > * > * {
  padding: 5px 0px;
}

.feedback-left-content {
  font-weight: 700;
}

/* space between each value */
.feedback-left-content > * { 
  padding: 0px 0px;
}

.feedback-right-affix {
  position: fixed;
  right:0%; 
  width: 22.5%; 
  text-align: center;
  margin-top:3%;
}

/* space between previous round details */
.feedback-affix > * > * {
  padding: 2px 0px;
}

.feedback-affix {
  background-color: #fff;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 1% 22.5% 1% 22.5%;
  margin-bottom: 20px;
  height: 67%;
  overflow: hidden;
}

.feedback-form-item {
  border: 2px solid #e7eaed;
  margin: 2% 22.5%;
  padding: 20px;
  border-radius: 10px;
}

.feedback-SI {
  border-radius: 50%;
  background-color: #e7eaed;
  color: #1890ff;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.5em;
  text-align: center;
}

.feedback-input {
  outline: 0;
  border-bottom: 1px solid #b7b7b7;
}
.feedback-input:focus {
  border-bottom: 1px solid #1890ff;
}

.feedback-size {
  transform: scale(1.2);
  padding-left: 2%;
}

.on-page-loader {
  display: block;
  margin-top: 15%;
}

.submitted-page {
  padding-top: 10%;
  text-align: center;
}

.submitted-page > * {
  padding: 20px
}

.feedback-info {
  color: #1890ff
}
.ant-modal.Modalcontent {
  margin-left:10px;
  margin-right:10px;
  margin-top:0;
  width:90vw !important;
  height:90vh !important;
  padding-bottom: 100px !important;
} 
 .loading {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
} 
 .ant-modal.interview
{
  margin-left:10px;
  margin-right:10px;
  margin-top:0;
  width:90vw !important;
  height:90vh !important;
  padding-bottom: 100px !important;
}
.loadingModal .ant-modal-content {
background-color: transparent;
border: none;
box-shadow: none;
display: flex;
justify-content: center;
}

.candidate_container__MToxM {
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 1rem 1.5rem 0 1.5rem;
  overflow-y: auto;
  width: 100%;
}
@media(max-width:700px){
  .candidate_container__MToxM {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    overflow-y: auto;
    width: 100vw;
  }
  .candidate_container__MToxM h1 {
    font-size: 0.8rem;
    font-weight: bold;
  }
}
.candidate_container__MToxM h1 {
  font-size: 23px;
  font-weight: bold;
}

.candidate_tabSection__uwDMK {
  display: flex;
  flex-direction: row;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  align-items: center;
}

.candidate_ListBodyContainer__3QjDz::-webkit-scrollbar{
  display: none;
}
/* .tabSection > :nth-child(1)
{
    flex: 1 1 10%;
   
}

.tabSection > :nth-child(2)
{
    flex: 1 1 60%;
   
}
.tabSection > :nth-child(3)
{
    flex: 1 1 30%;
   
} */

.candidate_tabSection__uwDMK button {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}
.candidate_export__3aXgn{
  display: flex;
  flex-direction: column;
  margin: 1rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.candidate_export__3aXgn h2{
  margin: 0rem auto;
  font-weight: 700;
}

.candidate_export__3aXgn button{
  margin: 1rem auto;
  width: 6rem;
  font-weight: 600;
}


.dashboard-box {
  background-color: #fff;
  min-height: 89px;
  box-shadow: 0px 5px 15px -4px #b7b7b7;
  padding: 10px;
  margin-bottom: 1.2rem;
  /* min-width: 16.5rem; */
  border-radius: 5px;
}

.profile-modal-view {
  text-align: center;
  border-radius: 10px;
  padding: 10px 30px;
}

.profile-modal-view > * {
  padding: 10px 0px;
}

.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.avatar-uploader-edit > .ant-upload {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.allPanelist_scroll__2dTNQ{
    overflow-y: scroll;
    max-height:19rem ;

}

.allPanelist_scroll__2dTNQ::-webkit-scrollbar{
    display: none;
}

.allPanelist_tableContent__2R9Wm{
    font-weight: 600;
}

.allPanelist_headerName__26uUt{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .allPanelist_nameMargin__3y4uV{
    margin-left: 0.9rem;
  }
  
  .allPanelist_headerName__26uUt h1{
    font-size: 1rem;
    font-weight: bold;
    color: #6f6f6f;
  }
  
  .allPanelist_searchRow__2bARC{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .allPanelist_searchRow__2bARC >*:nth-child(1)
  {
    flex: 1 1 15%;
  }

  
.allPanelist_searchDiv2__7r1Cd{
  /* outline: #d1cfcf; */
  border: 0;
  border-radius: 5px;
}

.allPanelist_searchDiv2__7r1Cd>*:nth-child(1)>*:nth-child(2){
  padding-left: 0.5rem;
  border-radius: 5px;
  max-height: 1.7rem;
} 

  .allPanelist_searchRow__2bARC >*:nth-child(2)
  {
    flex: 1 1 70%;
    justify-content: right;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
.timeSlotModal_duration__2KxiR{
    display: flex;
    flex-direction: row;  
    grid-gap:1rem;  
    gap:1rem;
}

.timeSlotModal_duration__2KxiR > *{
    flex: 1 1 50%;
}
.timeSlotModal_assignSlot__3zaHZ{
    display: flex;
   grid-gap:0.5rem;
   gap:0.5rem;
   min-height:7rem;
   max-height:10rem;
   flex-wrap: wrap;
   overflow-y: scroll;
   margin: 0.5rem auto 1rem auto;
   box-sizing:border-box;
}

.timeSlotModal_assignSlot__3zaHZ::-webkit-scrollbar {
    width: 0.2rem;
  }
  
  .timeSlotModal_assignSlot__3zaHZ::-webkit-scrollbar {
    height: 0.3rem;
  }
   
  .timeSlotModal_assignSlot__3zaHZ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .timeSlotModal_assignSlot__3zaHZ::-webkit-scrollbar-thumb {
    background-color: rgb(153, 150, 150);
    border-radius: 10px;
    height:0.5rem;
  }

  .timeSlotModal_assignSlot__3zaHZ div{
    min-width: 48.5%;
    box-sizing:border-box;
  }
.createPanelistPool_container__1prEH{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-weight: bold;
}

.createPanelistPool_container__1prEH h1{
    font-size: 1.3rem;
    margin:0.1rem auto;
    font-weight: bold;
    font-family: "Open Sans";
}

.createPanelistPool_container__1prEH h2{
    font-size: 1.1rem;
    font-weight: bold;
    font-family: "Open Sans";
    color: #000000;
    margin:1rem auto;
}


.createPanelistPool_jdRow__1YRmF{
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}

.createPanelistPool_jdRow__1YRmF > *{
    flex: 1 1 100%;
}

.createPanelistPool_buttonDiv__29344{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap:0.3rem;
    gap:0.3rem;

}

.createPanelistPool_buttonDiv__29344 button{
    height:2.2rem;
    width:8rem;
    border-radius: 0.1rem;
    border:1.3px solid #4DAAFF;
    color: #4DAAFF;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 1rem;
}

.createPanelistPool_buttonDiv__29344 .createPanelistPool_create__1q60P{
   background-color: #4DAAFF;
   color: #ffffff;
   border:2px solid #4DAAFF;

}

.editPool_firstRow__1g0nz{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    margin-top: 1rem;

}

.editPool_firstRow__1g0nz >*{
    flex:1 1 100%;
}


.active-dashboard-wrap {
    background-color: #e7eaed;
    color: #1890ff;
    text-align: center;
    padding: 0px 20px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .dashboard-box {
    background-color: #fff;
    /* width: 250px; */
    min-height: 89px;
    box-shadow: 0px 5px 15px -4px #b7b7b7;
    padding: 10px;
  }
  
  .feedback-left-content {
    font-weight: 700;
  }
  
  .candidate-details {
    text-align: center;
    background-color: #e7eaed;
    padding: 10px;
    
  }
  
  .candidate-dropdown  > * {
    max-height: 50vh;
    overflow: scroll;
    cursor: pointer;
  }
  
  .candidate-pointer {
    cursor: pointer;
  }
  
  .screen-type {
    margin-left: 5px;
    border: 2px solid yellow;
    padding: 0px 10px;
  }
  
  .interview-type > * {
    border-radius: 10px;
  }
  
  .on-modal-loader {
    display: block;
    margin-bottom: 20px;
  }
  
  .booked-slot {
    background-color: #f6f7f8;
    border-radius: 10px;
    padding:10px;
    color: #787b83;
  }
  
  .confirm-slot {
    text-align: center;
  }
  
  .candidate-timeline {
    margin-top: 10px;
    width: 200px;
  }
  
  .view-pdf > .ant-modal-content > .ant-modal-body {
    height: 70vh;
    padding: 0px;
  }
  
  .view-pdf-iframe {
    height: 70vh;
    width: 100%;
  }
  
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .date-dropdown {
    left: -90%;
  }
  
  .available-slots {
    max-height: 20vh;
    overflow:scroll;
    overflow-x: hidden;
  }
  
  .available-slots > * {
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .text-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .candidate-modal-job-title-hover{
    background: #fff;
    border-radius: 100px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .candidate-modal-job-title-hover:hover{
    background: #e7eaed;
  }
  
  iframe{
    overflow:hidden;
  }
  
  .multiAgencySelect {
    max-height: 15vh;
    overflow-y: auto;
  }
.hrProcessCandidateCard_container__3Zjt3{
    display: flex;
    justify-content: left;
}

.hrProcessCandidateCard_container__3Zjt3>*{
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    width: 15%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    padding: 1%;
}

.hrProcessCandidateCard_container__3Zjt3>* h3{
    font-size: 1.3rem;
    font-weight: bold;
}

.hrProcessCandidateCard_container__3Zjt3>* span{
    font-weight: 600;
    font-size: 1.3rem;
}
.log_tableStyle__1jiO5{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;

}

.log_headerName__1_0re{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.log_nameMargin__1bLeD{
  margin-left: 0.9rem;
}

.log_headerName__1_0re span{
 
  font-size: 1rem;
  font-weight: bold;
  color: #6f6f6f;
}

.log_searchDiv__3rh_C{
  width:18rem;
  height: 2.5rem;
  border-radius: 4px;
}

.log_searchDiv__3rh_C input{
  height: 1.55rem;
  border:0;
} 

.log_searchDiv__3rh_C input:active,
.log_searchDiv__3rh_C input:focus{
 outline: none;
 border:0;
}

/* .searchDiv img{
  width:18px;
  height:18px;
  margin-left: 1rem;
} */

.log_table__3dDAY{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .log_table__3dDAY::-webkit-scrollbar {
    display: none;
  }


  .log_rowStyle__CkbaW{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }
  
.schedule_tableStyle__1wV0W{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.schedule_headerName__2Ztm9{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  /* align-items:center;
  display:flex;
  flex-direction:row; */
  justify-content:space-between;
}
.schedule_ListBodyContainer__1a9ym::-webkit-scrollbar{
  display: none;
}

.schedule_nameMargin__1x4Jt{
  margin-left: 0.9rem;
}

.schedule_headerName__2Ztm9 span{
  font-size: 1rem;
  font-weight: bold;
  color: #161616;
}


.schedule_table__3j9nQ{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    /* margin-top:0.5rem; */
  }
  
   .schedule_table__3j9nQ::-webkit-scrollbar {
    display: none;
  }


  .schedule_rowStyle__3hFr4{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
    border-bottom-color: #161616;
  }

  .schedule_others__1ajqU{
    display: flex;
    
    width: 100%;
    justify-content: center;
  }

  .schedule_others__1ajqU > *{
    flex: 1 1 100%;
    border: 2px solid red;
  }
  
.feedback_tableStyle__1JNoR{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 15px;
}

.feedback_headerName__3LjoW{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  /* align-items:center; */
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:space-between;
}

.feedback_nameMargin__3GRMH{
  margin-left: 0.9rem;
}

.feedback_headerName__3LjoW span{
  font-size: 1rem;
  font-weight: bold;
  color: #161616;
}

.feedback_searchDiv__38FYh{
  display: flex;
  flex-direction: row;
  min-height: 48px;
  background-color: #ffffff;
  width:18%;
  align-items: center;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
}

.feedback_searchDiv__38FYh input{
  width:20rem;
  min-height: 48px;
  border:0;
}

.feedback_searchDiv__38FYh input:active,
.feedback_searchDiv__38FYh input:focus{
 outline: none;
 border:0;
}

.feedback_searchDiv__38FYh img{
  width:15px;
  height:15px;
  margin-left: 1rem;
}

.feedback_table__2Boek{
    font-weight: 500;
    font-size: 16px;
  }
  
   


  .feedback_rowStyle__2wnv8{
    background-color: white;
    font-size: 15px;
  }

  .feedback_headIcon__3l0xB{
    display: inline;
    padding: 4px;
    cursor: pointer;
  }

  .feedback_ant-modal-wrap__3DrqD {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    overflow: hidden;
    outline: 0;
}

.feedback_container__2Otez{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  flex-grow: 1;
}

.feedback_container__2Otez >*{
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
}

.feedback_container__2Otez >*:nth-child(2){
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap:0.5rem;
  gap:0.5rem;
}

.feedback_description__2laZ0>*{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feedback_description__2laZ0>*:nth-child(1){
  margin-top: 0.3rem;
  grid-gap:0.3rem;
  gap:0.3rem;
  padding: 0;
}

.feedback_description__2laZ0>*:nth-child(1) span{
  color:#696969;
  font-weight: 500;

}

.feedback_description__2laZ0>*:nth-child(2){
  margin-top: 0.3rem;
  grid-gap:0.3rem;
  gap:0.3rem;
  padding: 0;
  color: #000000;
  font-weight: 700;
}


    .feedback_question__SAjA_{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin-left: 2.5%;
      /* margin-top: 1%; */
      margin-bottom: 3%;
      margin-right: 2%;
      height: 55vh;
      overflow-y: auto;
      border: 1px solid #D3D3D3;
    }
    .feedback_question__SAjA_::-webkit-scrollbar {
      width: 0.1rem;
      display: none;
    }
    
    .feedback_question__SAjA_::-webkit-scrollbar {
      max-height: 10%;
      display: none;
    }
    .feedback_question__SAjA_::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .feedback_question__SAjA_::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    .feedback_question__SAjA_ h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .feedback_question__SAjA_ span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     /* padding-left: 0.7rem; */
    }

    .feedback_question2__1PCZB{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin-left: 2.5%;
      /* margin-top: 1%; */
      margin-bottom: 3%;
      margin-right: 2%;
      height: 48vh;
      overflow-y: auto;
      border: 1px solid #D3D3D3;
    }

    .feedback_question2__1PCZB::-webkit-scrollbar {
      width: 0.1rem;
      display: none;
    }
    
    .feedback_question2__1PCZB::-webkit-scrollbar {
      max-height: 10%;
      display: none;
    }
     
    .feedback_question2__1PCZB::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .feedback_question2__1PCZB::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    
    .feedback_question2__1PCZB h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .feedback_question2__1PCZB span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     /* padding-left: 0.7rem; */
    }

    .feedback_questionsDiv__1JaBz{
      height: 56vh;
      overflow-y: scroll;
      margin-bottom: 0.7rem;
    }

    @media screen and (max-width: 699px){
      .feedback_question__SAjA_{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-left: -0.1%;
        /* margin-top: 1%; */
        margin-bottom: 0;
        margin-right: -0.1%;
        height: 55vh;
        overflow-y: auto;
        border: none;
      }
      .feedback_question2__1PCZB{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        margin-left: -0.1%;
        /* margin-top: 1%; */
        margin-bottom: 0;
        margin-right: -0.1%;
        height: 50vh;
        overflow-y: auto;
        border: none;
      }
      .feedback_question__SAjA_ h3{
        background-color:#FAFAFA;
        font-weight: 600;
        font-size: 1rem;
        padding: 0;
      }
      .feedback_question2__1PCZB h3{
        background-color:#FAFAFA;
        font-weight: 600;
        font-size: 1rem;
        padding: 0;
      }
      .feedback_question__SAjA_ span{
        font-weight: bold; 
        margin-bottom: 0.5rem;
      }
      .feedback_question2__1PCZB span{
        font-weight: bold; 
        margin-bottom: 0.5rem;
      }
    } 


    .feedback_defaultQues__-3u4O{
      display: flex;
      flex-direction: column;
      justify-content: left;
    }

    .feedback_mobFeedCard__IdJZ3{
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      grid-gap: 0.8rem;
      gap: 0.8rem;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-child(n+2){
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-child(1){
      display: flex;
      flex-direction: column;
      border-bottom: 1.3px solid #CACACA;
      padding-bottom: 0.8rem;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-child(1)>:nth-child(2){
      font-size: 0.85rem;
      color: #808080;
    }
     .feedback_mobFeedCard__IdJZ3>:nth-child(n+2)>*{
      flex:1 1 50%;
      display: flex;
      flex-direction: column;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-child(n+2)>*>*:nth-child(1){
      color: #808080;
      font-size: 0.85rem;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-child(n+2)>*>*:nth-child(2)
    {
      font-size: 1rem;
    }
    .feedback_mobFeedCard__IdJZ3>:nth-last-child(1)>*>*>*{
      font-size: 1rem;
    }

   
  
.interview_tableStyle__2yYUn{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.interview_headerName__17GE1{
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.interview_nameMargin__1qKrM{
  margin-left: 0.7rem;
}

.interview_headerName__17GE1 span{
  font-size: 1rem;
  font-weight: bold;
}

.interview_searchDiv__3lcDa{
  height: 2.2rem;
  width:17.5rem;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
}
/* 
.searchDiv input{
  width:20rem;
  min-height: 2rem;
  margin: 0;
  border:0;
} */
/*  
.searchDiv input:active,
.searchDiv input:focus{
 outline: none;
 border:0;
} */

/* .searchDiv img{
  width:15px;
  height:15px;
  margin-left: 1rem;
} */

.interview_table__3SJ7N{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .interview_table__3SJ7N::-webkit-scrollbar {
    display: none;
  }


  .interview_rowStyle__3kX9q{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }
  
.interviewFresher_tableStyle__29K3n{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.interviewFresher_headerName__2LQCh{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.interviewFresher_nameMargin__1Zhp0{
  margin-left: 0.9rem;
}

.interviewFresher_headerName__2LQCh span{
 
  font-size: 1rem;
  font-weight: bold;
  color: #6f6f6f;
}

.interviewFresher_searchDiv__1EVG8{
  height: 2.2rem;
  width:18rem;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
}

/* .searchDiv input{
  width:20rem;
  min-height: 48px;
  border:0;
} */

/* .searchDiv input:active,
.searchDiv input:focus{
 outline: none;
 border:0;
}

.searchDiv img{
  width:15px;
  height:15px;
  margin-left: 1rem;
} */

.interviewFresher_table__1KnNX{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .interviewFresher_table__1KnNX::-webkit-scrollbar {
    display: none;
  }


  .interviewFresher_rowStyle__3647K{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }
.job-summary_scrollItem__3Eyls{
    overflow-y: scroll;
    max-height: 29vh;
    margin-bottom: 4%;
  }

   .job-summary_scrollItem__3Eyls::-webkit-scrollbar {
    width: 5px;
  }
  
  .job-summary_scrollItem__3Eyls::-webkit-scrollbar-track {
    background: #F8F8F8; 
  }
   
  .job-summary_scrollItem__3Eyls::-webkit-scrollbar-thumb {
    background: #8B8B8B14;
  }
  
  .job-summary_scrollItem__3Eyls::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }
.dashboard_container__3C7jt{
    display: flex;
    flex-direction: column;
    height:90vh;
    padding: 2%;
    overflow-y: auto;
}


.dashboard_container__3C7jt::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .dashboard_container__3C7jt::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .dashboard_container__3C7jt::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .dashboard_container__3C7jt::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 10px;
    height:0.5rem;
  }

body{
    background-color: #ffffff;
}
.dashboard_container__3C7jt span,h3,h4,p{
    font-weight: 600;
    font-size: 0.9rem;
}

.dashboard_header__3ewFq{
    width: 100%;
    display: flex;
    height: 5rem;
    background-color: #ffffff;
}

.dashboard_title__2rFBy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.dashboard_title__2rFBy h1{
    font-size: 1.5rem;
    font-weight: bold;

}

/* .title >*:nth-child(1)
{
    flex: 1 1 55%;
}

.title >*:nth-child(2)
{
    flex: 1 1 110%;
}
  */
.dashboard_title__2rFBy div{
    display: flex;
    flex-direction: column; 
    /* margin-bottom: 0.1rem; */

} 

.dashboard_title__2rFBy div div{
    display: flex;
    align-items: center;
    /* gap: 1%; */
    flex-direction: row;
    
}

.dashboard_title__2rFBy div div span{
    text-align: right;
    
}
.dashboard_title__2rFBy div div > *:nth-child(1){
  /* flex: 1 1 10%; */
  
}


.dashboard_title__2rFBy div div > *:nth-child(2){
    /* flex: 1 1 20%; */
    color: #0086FF;
    font-weight: 700;
  }


.dashboard_fiveItems__2nTT0{
    display: flex;
    flex-direction: row;
    grid-gap: 2%;
    gap: 2%;
    margin: 0.4% 0 0 0;
}

.dashboard_fiveItems__2nTT0 >*{
    flex: 1 1 20%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
}

.dashboard_fiveItems__2nTT0 >* h2{
    font-weight: 700;
}

.dashboard_scheduled__37ryZ{
    display: flex;
}

.dashboard_sheduled__1PiZL h2{
    font-weight: 700;
    margin-top: 2%;
}

.dashboard_sheduleDiv__27VI-{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

.dashboard_sheduleDiv__27VI- >*:nth-child(1)
{
    flex: 1 1 85%;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 60rem;
    grid-gap:1.5%;
    gap:1.5%;
    overflow-x: auto;
    width:"90%";
    height: 23rem;
}

.dashboard_sheduleDiv__27VI- div{
    display: flex;
    flex-direction: row; 
    
}

.dashboard_sheduleDiv__27VI- div >*{
    flex: 1 1 33%; 
}

.dashboard_sheduleDiv__27VI- div div{
    display: flex;
    flex-direction: column;
   
}
 


.dashboard_sheduleDiv__27VI- >*:nth-child(2)
{
    flex: 1 1 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   font-size: 1.25rem;
   font-weight: 700;
   color: #000000;
   text-decoration: underline;
}


.dashboard_feedbacks__26LVm{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;   
}

.dashboard_feedbacks__26LVm >*:nth-child(1)
{
    flex: 1 1 85%;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 60rem;
    grid-gap:1.5%;
    gap:1.5%;
    overflow-x: auto;
    width:"90%";
    height: 23rem;
}

.dashboard_feedbacks__26LVm div{
    display: flex;
    flex-direction: row; 
    
}

.dashboard_feedbacks__26LVm div >*{
    flex: 1 1 33%; 
}

.dashboard_feedbacks__26LVm div div{
    display: flex;
    flex-direction: column;
   
}
 


.dashboard_feedbacks__26LVm >*:nth-child(2)
{
    flex: 1 1 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   font-size: 1.25rem;
   font-weight: 700;
   color: #000000;
   text-decoration: underline;
}

.dashboard_ant-carousel__1Z84w .dashboard_slick-dots__34acj li button{
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: #b23434;
    font-size: 0;
    background: red !important;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s;
}


@media(max-width:700px)
{
    .dashboard_title__2rFBy{
        flex-direction: column;
        justify-content: left;
        /* border: 2px solid red; */
        align-items: flex-start;
        grid-gap: 1rem;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    .dashboard_fiveItems__2nTT0{
     /* border: 2px solid red; */
     width: 90vw;
     overflow-y: auto;
     height: 8rem;
     min-height: 8rem;
     margin: 0 auto;
     grid-gap: 1rem;
     gap: 1rem;
    }
    
    .dashboard_fiveItems__2nTT0 >*{
        flex: none;
       width: 15rem;
    }
 

.dashboard_sheduleDiv__27VI-{
    flex-direction: column;
    flex-wrap: wrap;  
    
}
.dashboard_sheduleDiv__27VI->:nth-child(1){
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
.dashboard_sheduleDiv__27VI->:nth-child(2){
    max-height: 3rem;
    margin-bottom: 1rem;
}

.dashboard_feedbacks__26LVm{
    flex-direction: column;
    flex-wrap: wrap;   
    margin-bottom: 3rem;
   
}

.dashboard_feedbacks__26LVm >*:nth-child(1)
{
    max-width: 100%;
    grid-gap:1.5%;
    gap:1.5%;
    overflow-x: hidden;
    height: auto;
    overflow-y: auto;
}
}
.feedbackItem_container__1Rgg7{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5%;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    min-width: 15rem;
    
}

.feedbackItem_container__1Rgg7 h4{
    font-size: 0.7rem;
    color: #BFBFBF;
    margin-top: 1%;
}

.feedbackItem_container__1Rgg7 a{
    text-align: left;
}
.sheduleItem_container__3OabV {
    display: flex;
    flex-direction: column;
    padding: 5%;
    background-color: #F2F7F2;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    min-width: 15rem;
    margin:10px

}
.sheduleItem_container__3OabV a{
    text-align: left;
    margin-bottom: 2%;
}

.sheduleItem_container__3OabV h4{
    font-size: 0.7rem;
    color: #BFBFBF;
    margin-top: 1%;
}

.sheduleItem_container__3OabV button{
    background-color: #0086FF;
    color: #ffffff;
    font-weight: bold;
    border:1 px solid #0086FF;
    max-height: 2.3rem;
}

.sheduleItem_container__3OabV button:hover{
    background-color: #0086FF;
    color: #ffffff;
    border:1 px solid #0086FF;
}



.ant-tabs-tab-btn{font-size:1rem;font-weight:600}.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#1890ff;font-size:1rem;font-weight:600;text-shadow:0 0 .25px currentcolor}.ant-table-thead .ant-table-cell{font-weight:500;background-color:#fff;height:3rem;color:#6f6f6f;border-bottom:1.5px solid #e4e4e4;border-bottom:1.5px solid #f9f5f5}.ant-table-tbody .ant-table-cell{font-weight:600;background-color:#fff;font-size:14px;border-bottom:1.5px solid #f9f5f5}.ant-table-body{overflow:scroll}
.home_header__3KKzW{
  font-size: 1.5rem;
  font-weight: bold;
  }
  

  .home_containerHome__2dGGR{
    display: flex;
    flex-direction: column;
    padding: 1.1% 2% 0 2%;
  }

  .home_tabContent__QyAmh{
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    max-width: 80rem;
  }
  
  .home_tabContent__QyAmh>*:nth-child(1){
    flex: 1 1 100%;
    display: flex;
    width: 17rem;
  }

  .home_tabContent__QyAmh >*{
    height:2.1rem;
  }

  .home_tabContent__QyAmh button{
    background-color: #0086FF;
    color: #ffffff;
    font-size: 650;
    border: 0;
  }

  .home_tabContent__QyAmh button:hover{
    background-color: #0086FF;
    color: #ffffff;

  }


  .home_editBtn__w4z1w button{
    border: 1.5px solid #1890FF;
    width: 5.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 680;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home_editBtn__w4z1w button:focus{
    background-color: #1890FF;
    color: #ffffff;
  }

  .home_editBtn__w4z1w button:hover{
    background-color: #1890FF;
    color: #ffffff;
  }

  .home_title__2Cq8c{
    font-weight: 680;
    letter-spacing: 0.5px;
    color: gray;
  }

  .home_spin__1pNsR{
    font-size: 2rem;
    margin:15% auto;
  }

.addAndEditLoc_container__3_TRM{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.addAndEditLoc_container__3_TRM >*:nth-child(1){
   width: 6rem;
   height: 6rem;
    margin: auto;
}

.addAndEditLoc_container__3_TRM >*:nth-child(1) img{
    width: 100%;
    height: 100%;
}

.addAndEditLoc_container__3_TRM >*:nth-child(2){
   margin: auto;
    font-size: 1.4rem;
    font-weight: 700;
}

.addAndEditLoc_container__3_TRM >*:nth-child(3){
    margin:auto;
    font-weight: 600;
    font-size: 14px;
    color: #636363;
}

.addAndEditLoc_container__3_TRM >*:nth-child(4){
  /* margin: 0.5rem 0 0 0; */
  padding: 0;
}

.addAndEditLoc_container__3_TRM >*:nth-child(4)>*:nth-child(2){
    display: flex;
    flex: 1 1 100%;
    margin: 0;
   
}

.addAndEditLoc_container__3_TRM >*:nth-child(4)>*:nth-child(2)>*:nth-child(1){
    flex-grow: 1;
    border: 0;
    margin-bottom: 0;
    
}

.addAndEditLoc_container__3_TRM >*:nth-child(4)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    grid-gap:0.2rem;
    gap:0.2rem;
    margin-bottom: 0.5rem; 
    
}

.addAndEditLoc_container__3_TRM >*:nth-child(4)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.addAndEditLoc_container__3_TRM input{
    background-color: #FAFAFA;
    border: 1.2px solid #E4E4E4;
    outline: none;
    height: 2.4rem;
    box-shadow: none;
    width: 100%;
}



.addAndEditLoc_container__3_TRM>*:nth-child(5)>*:nth-child(1)>*>*>*>*>*{
    grid-gap:2rem;
    gap:2rem;
    display: flex;
}

.addAndEditLoc_container__3_TRM>*:nth-child(5)>*:nth-child(1)>*>*>*>*>*>*>*:nth-child(1)>*{
    border:1.4px solid rgb(196, 191, 191);
    width: 1.2rem;
    height: 1.2rem;
}

.addAndEditLoc_container__3_TRM >*:nth-child(6){
    margin:0 0 0.5rem 0;
}

.addAndEditLoc_container__3_TRM >*:nth-child(6)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    grid-gap:0.2rem;
    gap:0.2rem;
    margin-bottom: 0.5rem;
}

.addAndEditLoc_container__3_TRM >*:nth-child(6)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.addAndEditLoc_container__3_TRM >*:nth-child(7){
    border: 2px solid;
    text-align: center;
    margin: auto;
    background-color: #2580EC;
    color: #ffffff;
    width: 8rem;
    height: 2.3rem;
    font-weight: 600;
    border: 0;
}





.addAndEditDept_container__38KnR{
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
   
}

.addAndEditDept_container__38KnR >*:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: #EFEEF8;
    margin: auto;
}

.addAndEditDept_container__38KnR >*:nth-child(1) img{
    width: 60%;
    height: 60%;
}

.addAndEditDept_container__38KnR >*:nth-child(2){
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
}

.addAndEditDept_container__38KnR >*:nth-child(3){
    text-align: center;
    font-weight: 600;
    color: #636363;
    font-size: 14px;
}

.addAndEditDept_container__38KnR >*:nth-child(4){
    margin: 1rem 0;
}

.addAndEditDept_container__38KnR >*:nth-child(4)>*:nth-child(1){
    display:flex;
    font-size: 14px;
    font-weight: 650;
    grid-gap:0.2rem;
    gap:0.2rem;
    margin-bottom: 0.5rem;
}

.addAndEditDept_container__38KnR >*:nth-child(4)>*:nth-child(1) p{
    font-weight: bold;
    color: red;
}

.addAndEditDept_container__38KnR >*:nth-child(4)>*:nth-child(2){
    background-color: #FAFAFA;
    border: 1.2px solid #E4E4E4;
    box-shadow: none;
    height: 2.4rem;
}

.addAndEditDept_container__38KnR >*:nth-child(5){
    border: 2px solid;
    text-align: center;
    margin: auto;
    background-color: #2580EC;
    color: #ffffff;
    width: 8rem;
    height: 2.3rem;
    font-weight: 600;
    border: 0;
}

.dashboard_headerName__1UzF4{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.dashboard_nameMargin__1_4B5{
  margin-left: 0.9rem;
}

.dashboard_headerName__1UzF4 span{
 
  /* font-size: 1rem; */
  font-weight: bold;
  color: #6f6f6f;
}

.dashboard_searchDiv__IZ_Lj{
  display: flex;
  flex-direction: row;
  min-height: 25%;
  background-color: #ffffff;
  width:28%;
  align-items: center;
  border-radius: 4px;
  grid-gap:0.3rem;
  gap:0.3rem;
}

.dashboard_searchDiv__IZ_Lj input{
  width:30rem;
  min-height: 30px;
  border:0;
}

.dashboard_searchDiv__IZ_Lj input:active,
.dashboard_searchDiv__IZ_Lj input:focus{
 outline: none;
 border:0;
}

.dashboard_searchDiv__IZ_Lj img{
  width:18px;
  height:18px;
  margin-left: 1rem;
}

.dashboard_table__DYn78{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .dashboard_table__DYn78::-webkit-scrollbar {
    display: none;
  }


  .dashboard_rowStyle__3rQuO{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }
  


.ant-modal-content {
    border-radius: 15px;
  }
  
  .college-modal-field div label{
    color:#6f6f6f !important;
    text-align: left;
    font: normal normal 400 10px/14px Open Sans!important;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
  }
  
  .sub-heading{
    text-align: left;
    font: normal normal bold 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #161616;
    opacity: 1;
    margin-left: 12px;
  }
  
  .college-modal-scroll{
    overflow-y: "scroll";
    max-height: 400px;
  }
  
  .donwload-icon-css{
    height: 25px;
    width: 25px;
  }
  
  .template-list-scroller{
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .selected_template{
    border: 2px solid blue;
  }
  
  .cursor-pointer{
    cursor: pointer;
  }
  
  .custom-card .ant-card-body {
    padding: 0px !important;
  }
  
  .custom-card-footer{
    min-height: 93px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 11px;
  }
  
  .custom-card-bg{
    background: #ffe0e0;
  }
  
  #components-dropdown-demo-arrow .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 8px;
  }
.overAllDashboard{
    display: flex;
    flex-direction: column;
    padding: 1.4% 2% 2% 2%;
    height: 89vh;
}

.overAllDashboard h1{
    font-weight: 700;
    font-size: 1.5rem;
}


.panelist_dashboardPan__3L-Xk{
    display: flex;
    flex-direction: column;
    height: 100%;
   grid-gap: 0.7rem;
   gap: 0.7rem;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(1){
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(1) h3{
    font-weight: 800;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(1) span{
    font-size: 13px;
}
.panelist_dashboardPan__3L-Xk>*:nth-child(1) h2{
    font-weight: 400;
    color: white;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 5%;
    gap: 5%;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 15%;
    /* max-width: 17rem; */
}

.panelist_dashboardPan__3L-Xk>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 20%;
    margin-right: 5%;
   
}
.panelist_dashboardPan__3L-Xk>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(1){
    max-width: 15.5rem;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.panelist_dashboardPan__3L-Xk>*:nth-child(2)>*:nth-child(2)>*>*:nth-child(1){
    max-width: 19.5rem;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.panelist_dashboardPan__3L-Xk>*:nth-child(2)>*:nth-child(3){
    flex: 1 1 20%;
}

.panelist_dashInfo__1vAwx{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 12.5rem; 
    grid-auto-rows: 24rem;
    grid-gap: 2rem;
    /* overflow: auto; */
}

.panelist_dashInfo__1vAwx>*{
    /* overflow-y: scroll; */
    /* min-height: 70vh; */
    background-color: #FCFCFC;
   border: 1.2px solid rgb(241, 238, 238);
}

.panelist_dashInfo__1vAwx>*:nth-child(1){
    grid-column: 1/3;
    margin-bottom: 3rem;
    min-height: 12.5rem; 
    background-color: #FCFCFC;
}

.panelist_dashInfo__1vAwx>*:nth-child(3){
    grid-column: 1/-1;
    display: flex;
    flex-direction: row;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    background-color: #ffffff;
    padding: 1rem;
    height: 27rem;   
}

.panelist_dashInfo__1vAwx>*:nth-child(3)>*:nth-child(1){
    flex: 1 1 75%;
    max-width: 52rem;
    overflow-y: auto;
}

.panelist_dashInfo__1vAwx>*:nth-child(3)>*:nth-child(1)>*:nth-child(2){
    max-width: 52rem;
    overflow-x: auto;
}

*::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  *::-webkit-scrollbar {
    height: 0.3rem;
  }
   
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  *::-webkit-scrollbar-thumb {
    background-color: rgb(160, 156, 156);
    border-radius: 10px;
    height:0.3rem;
  }

.panelist_dashInfo__1vAwx>*:nth-child(3)>*:nth-child(1)>*:nth-child(2)>*>*>*{
    min-width: 8.5rem;
}
       
.panelist_dashInfo__1vAwx>*:nth-child(3)>*:nth-child(2){
    flex: 1 1 25%;
    border-left: 1.5px solid #E3E3E3;
}

.panelist_dashInfo__1vAwx>*:nth-child(1){
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

.panelist_dashInfo__1vAwx>*:nth-child(1)>*:nth-child(1){
    padding: 1rem;
    outline: 1px solid rgb(241, 238, 238);
    background-color: #ffffff;
}

.panelist_dashInfo__1vAwx>*:nth-child(1)>*:nth-child(1)>*:nth-child(1){
    font-weight: 700;
}

.panelist_dashInfo__1vAwx>*:nth-child(2){
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.panelist_dashInfo__1vAwx>*:nth-child(2)>*:nth-child(1){
    font-weight:700;
}

.panelist_summery__ER4XY{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap:1rem;
    gap:1rem;
    height: 9rem;
    overflow-y: auto;
}

.panelist_summery__ER4XY>*{
    width: 23.5%; 
    display: flex;
    margin-top: 0.4rem;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2rem; 
}

.panelist_summery__ER4XY>*>:nth-child(1){
    flex: 1 1 60%;
    display: flex;
    /* border: 1.5px solid #E3E3E3; */
    /* box-shadow: 0px 1px 3px rgb(205, 200, 200); */
    background-color: #FCFCFC;
    outline: 1.2px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.8rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
}

.panelist_summery__ER4XY>*>:nth-child(2){
    box-shadow: 0 1px 5px gray;
    border-radius: 50%;
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 90%;
    background-color: #ffffff;
    z-index: 1;
}

.panelist_summery__ER4XY>*>:nth-child(2) h4{
    font-size: 1rem;
    font-weight: 700;  
}

.panelist_dashboardPan__3L-Xk>*:nth-child(3)>*:nth-child(2){
    flex: 1 1 35%;
}

.panelist_slotInfoDes__36lzG{
    display: flex;
    grid-gap:0.5rem;
    gap:0.5rem;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 1.2rem; 
}

.panelist_slotInfoDes__36lzG>*:nth-child(1){
    font-size: 14px;
    font-weight: 700;
}

.panelist_slotInfoDes__36lzG>*:nth-child(2){
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 500;
  color:#AFB2BB;
  align-items: center;
  margin-left: 3rem;
}
td{
    min-width: 8.5rem;
}

.panelist_activeSlot__3L1LF{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#CADAF9;
    margin: 0.5rem;
}
.panelist_cancelledSlot__e1Ogn{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#dc741e;
    margin: 0.5rem;
}
.panelist_completedSlot__2rzK8{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#96dc1e;
    margin: 0.5rem;
}

.panelist_feedPending__hyCL1{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#834a85;
    margin: 0.5rem;
}
.panelist_skippedSlot__3jnVj{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#2b333d;
    margin: 0.5rem;
}

.panelist_scheduleSlot__1V6Ct{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#90EE90;
    margin: 0.5rem;
}

.panelist_inActiveSlot__3HEvs{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#F0F0F0;
    margin: 0.5rem;
}

.panelist_scheduleInterview__3rWUn{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#FFDEAD;
    margin: 0.5rem;
}

.panelist_completedInterview__U5nSV{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#3CB371;
    margin: 0.5rem;
}

.panelist_cancelledInterview__1eH5J{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#FA8072;
    margin: 0.5rem;
}

.panelist_dashboardPan__3L-Xk table,.panelist_dashboardPan__3L-Xk tr, .panelist_dashboardPan__3L-Xk td{
    text-align: center;
}

.panelist_onHover__r79RI{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.panelist_onHover__r79RI h4{
    font-weight: 600;
}

.panelist_onHover__r79RI div{
    background-color: #F2F7F2;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    min-width: 14rem;
}

.panelist_onHover__r79RI div h4{
    color:#BFBFBF;
    font-size: 13px;
    font-weight: 600;
    padding: 1rem;
    margin: 0;
    padding: 0;
}

.panelist_onHover__r79RI div span{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}






.targetModal_targetModal__3404d{
    display:flex;
    flex-direction: column;
    padding:1.2rem;
}
.targetModal_targetModal__3404d >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}
.targetModal_targetModal__3404d >*:nth-child(2){
    max-height: 40vh;
    /* overflow-y: auto; */
    margin-bottom: 1rem;
}
.targetModal_targetModal__3404d >*:nth-child(1) h2{
    font-weight: bold;
}
.targetModal_header__1w0CU{
    font-size: 14px;
    font-weight: 700;
    color:#000000;
}

.targetModal_modal__27cRt>*{
    border-radius: 5px;
    background-color: #F8F8F8;
}
.head-icon{
    display: inline;
    padding: 4px;
    cursor: pointer;
  }

  .ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    overflow: hidden;
    outline: 0;
}

.description{
  font-weight: 600;
  display: flex;
  flex-direction: column;
}
.description h4{
  color:#696969;
  font-weight: 600;
  margin-bottom: 0.37rem;
}

.description span{
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.4rem;
}
    .question{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin: 3%;
      height: 51vh;
      /* margin-bottom: 3%; */
      overflow-y: scroll;
      border: 1px solid #D3D3D3;
    }

    .question::-webkit-scrollbar {
      /* width: 0.3rem; */
      width: 0.1rem;
      display: none;
    }
    
    .question::-webkit-scrollbar {
      height: 0.5rem;
      width: 0.1rem;
      display: none;
    }
     
    .question::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
      width: 0.1rem;
    }
     
    .question::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }
  
    .question h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem;
    }

    .question span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
     padding-left: 0.7rem;
    }
    .question h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
    }

    /* .question span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
    } */

    .questionsDiv{
      height: 51vh;
      overflow-y: scroll;
      margin-bottom: 0.7rem;
    }

    .question2{
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin: 1% 3% 3% 3%;
      height: 40vh;
      overflow-y: scroll;
      border: 1px solid #D3D3D3;
    }

    .question2::-webkit-scrollbar {
      /* width: 0.3rem; */
      display: none;
    }
    
    .question2::-webkit-scrollbar {
      height: 0.5rem;
      display: none;
    }
     
    .question2::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3); */
    }
     
    .question2::-webkit-scrollbar-thumb {
      /* background-color: rgb(23, 20, 20); */
      border-radius: 10px;
      height:0.5rem;
    }

    .question2 h3{
      background-color:#FAFAFA ;
      font-weight: 600;
      font-size: 1rem;
    }

    .question2 span{
      font-weight: 600; 
      margin-bottom: 0.5rem;
    }
.viewScreenshotModal_container__3FOG5{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    width: 30rem;
    height: 30rem;
}
.viewScreenshotModal_container__3FOG5 img{
    width: 100%;
    height: 100%;
}
.jobProfile_title__A-oQA{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 98%;
  /* gap:1rem; */

}
.jobProfile_title__A-oQA h3{
  font-weight: 750;
  font-size: 17px;
}
/* .title span{
  margin-top: 0.3rem;
} */

.jobProfile_selectRow__CJQrR{
   display: flex;
   grid-gap:1rem;
   gap:1rem;
   flex-direction: row;
   justify-content:space-between;
   width:98%;

}

.jobProfile_selectDiv__3VCeR{
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    width:18rem;
    align-items: center;
    font-size: 14px;
    border: 0.1px solid #D9D9D9;
  }

  .jobProfile_jobTitle__1tEyL{
    font-weight:bold;
    font-size:15px;
    margin-top:-0.4rem;
    margin-left: 0.5rem;
  }

  .jobProfile_cardsHead__3nUOP{
    display:flex;
    flex-direction:row;
    grid-gap:2rem;
    gap:2rem;
    flex-wrap:nowrap;
    margin-top:0.5rem;
  }

.jobProfile_cards__WjRUz{
    display:flex;
    flex-direction:row;
    width:14.5rem;
    grid-gap:2rem;
    gap:2rem;
    margin-bottom:0.2rem;
    height:5rem;
    background-color:  #FCFCFC;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.jobProfile_cardDiv__diqfX{
   display:flex;
   flex-direction:row;
   align-items:center;
   text-align:center;
   grid-gap:1rem;
   gap:1rem;
   flex-wrap:nowrap;

}

.jobProfile_gridStyle__2LTvl{
    width: 3.4rem;
    height:3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    background-color:#E8EBFF;
  };

  .jobProfile_cardSide1__1yNi6{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
  }

  .jobProfile_cardSide2__gUUiy{
    display: flex;
    justify-content: right;
    align-items: right;
    flex-direction: column;
    text-align: right;
  }
  .jobProfile_cardSide2__gUUiy p{
    color: #A7A7A7;
    font-size: 11px;
  }

  .jobProfile_cardSide2__gUUiy h2{
    font-size: 1rem;
  }

  .jobProfile_cardSide2__gUUiy span{
    color:#161616;
    font-size: 12px;
    font-weight: bold;
    font-style: normal normal normal 12px/22px Open Sans;
  }

  .jobProfile_table__2vw9p{
    overflow: scroll;
    font-family: "Open Sans";
    font-size: 14px;
    
  }
  
  .jobProfile_table__2vw9p::-webkit-scrollbar {
    display: none;
  }

  .jobProfile_rowStyle__12ewO{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 14px;
  
  }
  .jobProfile_rows__2Dlv-{
   /* color: #CCCCCC;
   display: flex;
   flex-direction: row;
   /* gap: 10rem; */
   /* margin-left: 0.5rem;
   margin-top: 0.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    grid-gap: 5%;
    gap: 5%; 
    width: 100%;
    color: #CCCCCC;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .jobProfile_rows__2Dlv- p{
    width: 13rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #CCCCCC;
  }

  .jobProfile_rows2__3mt-8{
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    width: 100%;
    grid-gap: 21.5%;
    gap: 21.5%; 
   }
   .jobProfile_rows2__3mt-8 h3{
    font-weight: bold;
   }

   .jobProfile_rows2__3mt-8>*:nth-child(1){
    display: flex;
    flex-direction: column;
    width: 10rem;
   }

   .jobProfile_rows2__3mt-8>*:nth-child(2){
    display: flex;
    flex-direction: column;
    width: 10rem;
   }

   .jobProfile_secondRows__2FfaX{
    color: #CCCCCC;
    display: flex;
    flex-direction: row;
    /* gap: 9rem; */
    justify-content: left;
    justify-content: space-between;
    width: 80%;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
   }

   .jobProfile_secondRows2__1pCWu{
    display: flex;
    flex-direction: row;
    justify-content: left;
    justify-content: space-between;
    width: 95%;
   }

   .jobProfile_scroll__2WA1I{
    overflow-y: auto;
    max-height: 10rem;
    padding-right: 3.5rem;
    overflow-x: none;
   }

   .jobProfile_scroll__2WA1I>*{
      padding: 0.5rem;
      border-radius: 3px;
   }
   .jobProfile_scroll__2WA1I>*:hover{
    background-color: #F8F8F8;
   }
   .jobProfile_scroll__2WA1I::-webkit-scrollbar {
    width: 5px;
  }
  
  .jobProfile_scroll__2WA1I::-webkit-scrollbar-track {
    background: #F8F8F8;
  }
   
  .jobProfile_scroll__2WA1I::-webkit-scrollbar-thumb {
    background:#8B8B8B14; 
  }
  
  .jobProfile_scroll__2WA1I::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }

  .jobProfile_scroll2__3liZb{
    overflow-y: auto;
    max-height: 10rem;
    padding-right: 8.3rem;
   }

   .jobProfile_scroll2__3liZb>*{
      padding: 0.5rem;
      border-radius: 3px;
   }
   .jobProfile_scroll2__3liZb>*:hover{
    background-color: #F8F8F8;
   }
   .jobProfile_scroll2__3liZb::-webkit-scrollbar {
    width: 5px;
  }
  
  .jobProfile_scroll2__3liZb::-webkit-scrollbar-track {
    background: #F8F8F8; 
  }
   
  .jobProfile_scroll2__3liZb::-webkit-scrollbar-thumb {
    background: #8B8B8B14;
  }
  
  .jobProfile_scroll2__3liZb::-webkit-scrollbar-thumb:hover {
    background:#8B8B8B14;
  }

  .jobProfile_summary__2eah5{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap:2.5rem;
    gap:2.5rem;
    width:99%;
    margin-top: 1.2rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    max-height: 10.5rem;
    overflow-y: auto;
}

.jobProfile_summary__2eah5>*{
    width: 15%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.jobProfile_summary__2eah5>*>:nth-child(1){
    flex: 1 1 62%;
    display: flex;
    background-color: #FCFCFC;
    outline: 1.5px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.8rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
    /* border: 2px solid red; */
}

.jobProfile_summary__2eah5>*>:nth-child(2){
    box-shadow: 0 1px 2.5px gray;
    border-radius: 50%;
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 92%;
    background-color: #ffffff;
    z-index: 1;
    /* border: 2px solid red; */
}

.jobProfile_summary__2eah5>*>:nth-child(2) h4{
    font-size: 1rem;
    font-weight: 700;  
}

.jobProfile_roundSummary__3oqvR{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap:2rem;
  gap:2rem;
  width:98%;
  margin-top: 2rem;
  margin-left: 0.5rem;
  margin-bottom: 1.2rem;
}

.jobProfile_roundSummary__3oqvR>*{
  width: 19%; 
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  background-color: #FCFCFC;
  box-shadow: 0px 1px 2px grey;

}

.jobProfile_roundSummary__3oqvR>*>:nth-child(1){
  display: flex;
  outline: 1.5px solid rgb(241, 238, 238);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: -1rem;
  height: 2.3rem;
  width: 8.5rem;
  color: #161616;
  font-size: 14px;
  font-weight: 600;
}

.jobProfile_roundSummary__3oqvR>*>:nth-child(2){
  /* margin-left: 1rem; */
  font-size: 1rem;
  font-weight: 600;
}

.jobProfile_roundSummary__3oqvR>*>:nth-child(3){
  /* margin-left: 1rem; */
  font-size: 1rem;
  font-weight: 600;  
  margin-bottom: 2rem;
}

.jobProfile_conditionsum__Bsp3q{
  font-size:  11px;
  margin-left: 43%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.jobProfile_conditionsum2__1apri{
  font-size:  11px;
  margin-left: 35%;
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

.jobProfile_modalDesign__1tfbj>*{
  border-radius: 5px;
  background-color: #F8F8F8;
}

.jobProfile_modalView__20J4-{
  display:flex;
  flex-direction: column;
  padding: 1rem;
}

.jobProfile_modalView__20J4- >*:nth-child(1){
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.jobProfile_modalView__20J4- >*:nth-child(1) h2{
  font-weight: bold;
}



.recruiter_container__2-lqH {
    width: 100%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    overflow-y: scroll;
  }
  .recruiter_container__2-lqH::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  .recruiter_container__2-lqH::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(88, 87, 87, 0);
  }
  
  .recruiter_container__2-lqH::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    height: 1rem;
  }
  
  .recruiter_filterBlock__GSuKX {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
  }
  
  .recruiter_chartBlock__1jLJp {
    width: 100%;
    max-height: 100vh;
    display: flex;
  }
  
  .recruiter_summaryBlock__3h9h- {
    width: 100%;
    max-height: 50vh;
    padding: 1rem;
    background: #ffffff;
  }
  .recruiter_canType__K9ywb{
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
    align-content: center;
    align-items: center;
    grid-gap:0.5rem;
    gap:0.5rem;
    margin-top: 0.7rem;
    font-size: 14px;
    font-weight: 600;
  }

  .recruiter_canType__K9ywb>*:nth-child(1),.recruiter_canType__K9ywb>*:nth-child(3){
    width: 12px;
    height: 12px;
    border: 1.5px solid gray ;
    border-radius: 50%;
  }

  .recruiter_canType__K9ywb>*:nth-child(2){
    margin-right: 0.8rem;
  }

  .recruiter_canType__K9ywb>*:nth-child(1).recruiter_activeCircle__RKw2S,  .recruiter_canType__K9ywb>*:nth-child(3).recruiter_activeCircle__RKw2S{
    border: 1.5px solid #286eaa;
    padding: 2px;
    background-color: #0086FF;
  }

  .recruiter_textColor__2uE2C{
    color: #0086FF;
  }

  .recruiter_fresherSearch__1uHo5{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .recruiter_fresherSearch__1uHo5>*{
    flex: 1 1 18%;
   
  }

  .recruiter_fresherSearch__1uHo5>*:nth-child(5){
    flex: 1 1 25%;
  }


  .recruiter_dropdown__2buMW>*:nth-child(2)>*>*:nth-child(1),.recruiter_dropdown__2buMW>*:nth-child(3)>*>*:nth-child(1),.recruiter_dropdown__2buMW>*:nth-child(4)>*>*:nth-child(1),.recruiter_fresherSearch__1uHo5>*:nth-child(2)>*>*:nth-child(1){
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: auto;
    max-height: 5rem;
    max-width: 10rem;
  }
  .recruiter_modalDesign__WIdll>*{
    border-radius: 5px;
    background-color: #F8F8F8;
  }

  .recruiter_modalView__vg3yC{
    display:flex;
    flex-direction: column;
    padding: 1.2em;
  }
  
  .recruiter_modalView__vg3yC >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .recruiter_modalView__vg3yC >*:nth-child(1) h2{
    font-weight: bold;
  }

  .recruiter_radio__3QYun{
    margin-top: 0.9rem;
  }

  .recruiter_radio__3QYun>*>:nth-child(1){
    font-weight: 600;
  }
  .recruiter_radio__3QYun>*>:nth-child(2){
    font-weight: 600;
  }

.recuiterTabModal_modalDesign__1ERnE>*{
    border-radius: 5px;
    background-color: #F8F8F8;
  }

  .recuiterTabModal_modalView__3WqGK{
    display:flex;
    flex-direction: column;
    padding: 1.2em;
  }
  
  .recuiterTabModal_modalView__3WqGK >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .recuiterTabModal_modalView__3WqGK >*:nth-child(1) h2{
    font-weight: bold;
  }
.HRRound_title__ktdRd{
    display: flex;
    flex-direction: row;
    /* gap:1rem; */
    justify-content: space-between;
    width: 96.5%;
    margin-left: 1rem;
  }
  .HRRound_title__ktdRd h3{
    font-weight: 750;
    font-size: 17px;
  }
  /* .title span{
    font-weight: 490;
    font-style: italic;
    color: #B7B7B7;
    font-size: 13px;
    margin-top: 0.3rem;
  } */

  .HRRound_radio__UeV5u{
    margin-top: 0.9rem;

  }

  .HRRound_radio__UeV5u>*>:nth-child(1){
    font-weight: 600;
  }
  .HRRound_radio__UeV5u>*>:nth-child(2){
    font-weight: 600;
  }
  .HRRound_selectDiv__2DUZd{
    background-color: #ffffff;
    width:18rem;
    height: 2.1rem;
    align-items: center;
    font-size: 14px;
    border: 0.1px solid #D9D9D9;
    margin-top: 0.2rem;
  }

  .HRRound_jobTitle__RVJUP{
    font-weight:bold;
    font-size:15px;
    /* margin-top:-0.4rem; */
    margin-left: 0.5rem;
    /* margin-bottom: 2rem; */
  }

  .HRRound_summary__12t6R{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap:2.5rem;
    gap:2.5rem;
    /* width:95%; */
    margin-top: 1.2rem;
    /* margin-bottom: 1.5rem; */
    overflow-y: auto;
    max-height: 8rem;
}

.HRRound_summary__12t6R>*{
    width: 18%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2rem;
}

.HRRound_summary__12t6R>*>:nth-child(1){
    flex: 1 1 60%;
    display: flex;
    background-color: #FCFCFC;
    outline: 1.5px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.5rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
}

.HRRound_summary__12t6R>*>:nth-child(2){
    box-shadow: 0 1px 2.5px gray;
    border-radius: 50%;
    width: 3.3rem;
    height: 3.3rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 90%;
    background-color: #ffffff;
    z-index: 1;
}

.HRRound_summary__12t6R>*>:nth-child(2) h4{
    font-size: 1.3rem;
    font-weight: 700;  
}


.HRRound_searchDiv__3y7Bv{
    display: flex;
    flex-direction: row;
    min-height: 2.3rem;
    background-color: #ffffff;
    width:31%;
    align-items: center;
    border-radius: 4px;
    grid-gap:0.3rem;
    gap:0.3rem;
  }
  
  .HRRound_searchDiv__3y7Bv input{
    width:20rem;
    min-height: 2.3rem;
    border:0;
  }
  
  .HRRound_searchDiv__3y7Bv input:active,
  .HRRound_searchDiv__3y7Bv input:focus{
   outline: none;
   border:0;
  }
  
  .HRRound_searchDiv__3y7Bv img{
    width:15px;
    height:15px;
    margin-left: 1rem;
  }

.HRRound_headerName__vTCDe{
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-size: 16px;
}

.HRRound_headerName__vTCDe span{
  font-size: 0.9rem;
  font-weight: bold;
}

.HRRound_piechart__3wNf3{
  display:flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  max-height: 8.5rem;
  /* border: 2px solid; */
  /* margin-right; */
}



.modalDesign>*{
    border-radius: 5px;
    background-color: #F8F8F8;
}

.modalView{
    display:flex;
    flex-direction: column;
    padding:2rem;
}

.modalView >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
}

.modalView >*:nth-child(2){
    max-height: 20vh;
    overflow-y: auto;
    margin-bottom: 1rem;
}

.modalView >*:nth-child(1) h2{
    font-weight: 700;
    color:#000000;

}



.MainPage_mainpage__1RypB{
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    grid-gap: 1rem;
    gap: 1rem;
    /* border: 2px solid red; */
}

.MainPage_header__2hsy7{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.MainPage_header__2hsy7>*:nth-child(1){
   display: flex;
   flex-direction: column;
}
.MainPage_header__2hsy7>*:nth-child(1)>*:nth-child(1){
    font-size: 1.3rem;
    font-weight: 700;
}

.MainPage_store__m0IfN{
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.MainPage_store__m0IfN>*:nth-child(1){
    flex: 1 1 20%;
}
.MainPage_store__m0IfN>*:nth-child(2){
    flex: 1 1 40%;
}
.MainPage_store__m0IfN>*:nth-child(3){
    flex: 1 1 40%;
}
.MainPage_store__m0IfN>*:nth-child(1){
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
   
}
.MainPage_store__m0IfN>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(1){
    display: flex;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    align-items: center;
}
.MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(1)>:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.MainPage_store__m0IfN>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    background-color: #FED330;
}
.MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(1)>:nth-child(2){
    font-size: 1.5rem;
    font-weight: 700;
}
.MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(2){
   font-weight: 700;
}
.MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .MainPage_store__m0IfN>*:nth-child(1)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .MainPage_store__m0IfN>*:nth-child(1)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }

 .MainPage_playStore__3vTnc{
    display: flex;
    /* border: 2px solid red; */
    overflow-x: auto;
    border-radius: 0.5rem;
 }
 .MainPage_appStore__1eMay{
    display: flex;
    /* border: 2px solid red; */
    overflow-x: auto;
    border-radius: 0.5rem;
 }


 .MainPage_users__3mj8D{
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.MainPage_users__3mj8D>*:nth-child(1){
    flex: 1 1 40%;
}
.MainPage_users__3mj8D>*:nth-child(2){
    flex: 1 1 20%;
}
.MainPage_users__3mj8D>*:nth-child(3){
    flex: 1 1 40%;
}
.MainPage_users__3mj8D>*:nth-child(2){
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
   
}
.MainPage_users__3mj8D>*:nth-child(2)>*{
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(1){
    display: flex;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    align-items: center;
}
.MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(1)>:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.MainPage_users__3mj8D>*:nth-child(2)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1){
    background-color: #FED330;
}
.MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(1)>:nth-child(2){
    font-size: 1.5rem;
    font-weight: 700;
}
.MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(2){
   font-weight: 700;
}
.MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .MainPage_users__3mj8D>*:nth-child(2)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .MainPage_users__3mj8D>*:nth-child(2)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }


 
 .MainPage_overview__2LR3K{
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.MainPage_overview__2LR3K>*:nth-child(1){
    flex: 1 1 20%;
}
.MainPage_overview__2LR3K>*:nth-child(2){
    flex: 1 1 40%;
}
.MainPage_overview__2LR3K>*:nth-child(3){
    flex: 1 1 40%;
}
.MainPage_overview__2LR3K>*:nth-child(1){
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
   
}
.MainPage_overview__2LR3K>*:nth-child(1)>*{
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;

}
.MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(1){
    display: flex;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    align-items: self-end;
}
.MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(1)>:nth-child(1){
   font-size: 1.5rem;
   font-weight: 700;
}

.MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(1)>:nth-child(2){
    font-size: 0.8rem;
    font-weight: 500;
}
.MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(2){
   font-weight: 700;
}
.MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(3){
    font-weight: 400;
    font-size: 0.8rem;
 }
 .MainPage_overview__2LR3K>*:nth-child(1)>*>*:nth-child(4){
    font-weight: 600;
    color: #FC5C65;
 }
 .MainPage_overview__2LR3K>*:nth-child(1)>*:nth-child(2)>*:nth-child(4){
    color: #26DE81;
 }
.StoreGraph_storeGraph__ZJeKD{
    border-radius: 1rem;
    height: 100%;
    background-color: #fff;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    /* width: max-content; */
 
}
*{
    box-sizing: border-box;
}
h2,h3,h4,h1,span{
    padding: 0;
    margin: 0;
    text-align: left;
}
.StoreGraph_storeGraph__ZJeKD>*{
    color: #161616;
}
.StoreGraph_chartDesc__1xErM{
    display: flex;
   grid-gap: 2rem;
   gap: 2rem;
   margin-top: 1rem;
}
.StoreGraph_chartDesc__1xErM>*{
    display: flex;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    align-items: center;
}
.StoreGraph_chartDesc__1xErM>*>*:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #FD9644;
}
.StoreGraph_chartDesc__1xErM>*:nth-child(2)>:nth-child(1){
    background-color: #FED330;
}
.StoreGraph_chartDesc__1xErM>*>*:nth-child(2){
    color: #5E5E5E;
    font-weight: 500;
    font-size: 0.8rem;
}
.StoreGraph_graph__2fCjb{
    display: flex;
    height: 100%;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-x: auto; */
    width:100%;
    position: relative;
}
.StoreGraph_yAxis__Gwgfo{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    min-width: -webkit-max-content;
    min-width: max-content;
    min-width: 100%;
}
.StoreGraph_yAxis__Gwgfo>:nth-child(2)
{
    border-bottom: 1.5px solid #CACACA;
    border-bottom-style: dashed;
     width: 100%;
}
.StoreGraph_xAxis__2b6Cb{
    display: flex;
    margin-left: 8vw;
    /* gap: 5%; */
    width:auto;
    position: absolute;
    bottom: -1.5rem;
    height: calc(100% + 1.5rem);
    /* border: 2px solid red; */
    
   
  
}
.StoreGraph_xAxis__2b6Cb>*{

    width: 8vw;
    height: 100%;
}
.StoreGraph_bar__1cyPv>*:nth-child(1){
    display: flex;
    height: calc(100% - 1.5rem);
    /* margin-left: 0.3rem; */
}
.StoreGraph_eachBar__2ikV1{
    /* width:100%; */
    display: flex;
   align-items: self-end;
   margin-top: 1.1rem;
}
.StoreGraph_eachBar__2ikV1>*{
    width: 1rem;
    height: 100%;
    border-radius: 1rem;
}

.StoreGraph_bar__1cyPv>:nth-child(2){
    display: flex;
}
.StoreGraph_tooltip__Jjsqg{
    display: flex;
    flex-direction: column;
}
.StoreGraph_tooltip__Jjsqg>*{
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
}
.StoreGraph_tooltip__Jjsqg>*>*:nth-child(1){
    display: flex;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}
.StoreGraph_tooltip__Jjsqg>*>*:nth-child(1)>*:nth-child(1){
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
}

.EngagementGraph_engGraph__1pKEp{
    display: flex;
    height: 100%;
    flex: 1 1 100%;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;

}
.EngagementGraph_engGraph__1pKEp>:nth-child(1){
    font-size: 1.1rem;
}
.EngagementGraph_engGraph__1pKEp>:nth-child(2){
    font-size: 0.8rem;
}
.EngagementGraph_graphPart__miw3a{
    display: flex;
    height: 100%;
    margin-top: 0.5rem;
    position: relative;
}
.EngagementGraph_graphPart__miw3a>:nth-child(1){
    flex: 1 1 25%;
}
.EngagementGraph_graphPart__miw3a>:nth-child(2){
    flex: 1 1 75%;
}
.EngagementGraph_xAxis__3NzIA{
    display: flex;
}
.EngagementGraph_xAxis__3NzIA>*{
    width: 20%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.EngagementGraph_xAxis__3NzIA>*>*:nth-child(1){
    border-left: 1.5px solid #CACACA;
    border-left-style:dashed;
    height: calc(100% - 1.5rem);
}
.EngagementGraph_xAxis__3NzIA>*>*:nth-child(2)
{
    height: 1.5rem;
}
.EngagementGraph_yAxis__20ovA{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: space-evenly;
    padding-bottom: 1rem;
}
.EngagementGraph_yAxis__20ovA>*{
    display: flex;
}
.EngagementGraph_yAxis__20ovA>*>*:nth-child(1){
    display: flex;
    width: 25%;
    color: #5E5E5E;
}
.EngagementGraph_yAxis__20ovA>*>*:nth-child(2){
    width: 61%;
    display: flex;
    align-items: center;
  
}


.response_container__2M-bV{
    display: flex;
    flex-direction: column;
    color: #161616;
    justify-content: left;
    margin-right: 2%;
}

.response_spinStyle__fHqE2{
  margin: 15% auto;
}

.response_container__2M-bV h1{
    font-size: 2rem;
    font-weight: bold;
    margin:1.3% 0 1% 0;
    padding-left: 2.2%;
}

.response_header__faY_8{
  display: flex;
  align-items: center;
}

.response_header__faY_8 h1{
  font-size: 1.5rem;
 font-weight: bold;
  margin: 1% 0 0 2%;
}

.response_searchDiv__1goK4{
  display: flex;
  flex-direction: row;
  grid-gap:1%;
  gap:1%;
  margin-bottom: 1%;
  height: 2.2rem;
  flex: 1 1 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}
/* .searchDiv >*:nth-child(1){
  border: 2px solid red;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 40rem;
}
.searchDiv >*:nth-child(1) input{
  width: 10rem;
} */

.response_searchDiv__1goK4 >*:nth-child(2)
{
  display: flex;
  flex-direction: row;
  grid-gap: 2%;
  gap: 2%;
  justify-content: right;
}
/* .searchDiv >*:nth-child(3)
{
  flex: 1 1 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
} */
/* .searchDiv >*:nth-child(3) img{
  width: 1.3rem;
  height: 1.3rem;
} */

.response_headerName__1Ad_o{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .response_headerName__1Ad_o h1{
    font-size: 1rem;
    font-weight: bold;
    color: #6f6f6f;
  }

  .response_tableStyle__1fDJw{
    font-weight: 600;
    /* margin-top: 0.1rem; */
  }
  

.candidate-detail-upload_wrapper__15t-j{
    display: flex;
    height: 100vh;

}

.candidate-detail-upload_container__2VvNu{
    display:flex;
    flex-direction: row;
    width: 78%;
    height: 92vh;
    margin: auto;
    border: 1.5px solid #E4E4E4;
}

.candidate-detail-upload_container__2VvNu span{
    font-weight: 700;
}

.candidate-detail-upload_container__2VvNu>*:nth-child(1){
    display: flex;
    flex:1 1 45%;
    flex-direction: column;
}

.candidate-detail-upload_container__2VvNu>*:nth-child(1) img{
    width: 100%;
    height:100%;
}

.candidate-detail-upload_container__2VvNu>*:nth-child(2){
    display: flex;
    flex:1 1 55%;
    flex-direction: column;
    padding: 2rem;
}

.candidate-detail-upload_uploadPhoto___hu4E{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
}

.candidate-detail-upload_uploadPhoto___hu4E>*>*:nth-child(1)>*:nth-child(1)>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
}

.candidate-detail-upload_uploadPhoto___hu4E>*>*:nth-child(1)>*:nth-child(1)>* div{
    
    width: 11rem;
    height: 11rem;
    border: 1.5px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.candidate-detail-upload_uploadPhoto___hu4E>*>*:nth-child(1)>*:nth-child(1)>* span{
    margin-top: -2rem;
}

.candidate-detail-upload_uploadPhoto___hu4E>*>*:nth-child(1) button{
    display: flex;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left:30%;
    justify-content: center;
    bottom: 25%;
    border: 0;
    align-items: center;
}

.candidate-detail-upload_uploadPhoto___hu4E>*>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.candidate-detail-upload_mobileDiv__5vynl{
    border: 1.3px solid #E4E4E4;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-inline: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    height: 4rem;
    align-items: center;
}

.candidate-detail-upload_mobileDiv__5vynl button{
    width: 8rem;
    height: 2.4rem;
    background-color: #2580EC;
    border:1px solid #2580EC;
    font-weight: bold;
    color:#ffffff;
}

.candidate-detail-upload_mobileDiv__5vynl button:hover{
    background-color: #2580EC;
    border:1px solid #2580EC;
    color:#ffffff;
}

.candidate-detail-upload_mobileDiv__5vynl input{
    height: 2.4rem;
}

.candidate-detail-upload_resumeUpload__iyHop{
    display: flex;
    flex-direction: column;
    border: 1.5px solid #E4E4E4;
    justify-content: center;
    border-radius: 4px;
    height: 12rem;
    align-items: center;
    background-color: #FAFAFA;
    
}

.candidate-detail-upload_resumeUpload__iyHop>*{
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    margin: 0;
   
}

.candidate-detail-upload_resumeUpload__iyHop>*>*:nth-child(1)>*>*{
    height: 90%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    border:0;

}

.candidate-detail-upload_resumeUpload__iyHop>*>*:nth-child(1)>*>* h4{
    font-size: 12px;
    font-weight: 700;
    margin-top: 0.5rem;
}

.candidate-detail-upload_resumeUpload__iyHop>*>*:nth-child(1)>*>* span{
    font-size: 12px;
    font-weight: 500;
    color:#6F6F6F;
   
}

.candidate-detail-upload_submitButton__1UMDE{
    display: flex;
}
.candidate-detail-upload_submitButton__1UMDE button{
    width: 9rem;
    height: 2.5rem;
    border-radius: 1rem;
    margin: 0.5rem auto;
    background-color: #2580EC;
    color: #ffffff;
}

.candidate-detail-upload_submitButton__1UMDE button:hover {
    background-color: #2169c1;
    color: #ffffff;
}

.candidate-detail-upload_verified__3Yos1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.candidate-detail-upload_verified__3Yos1>*:nth-child(1){
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

.candidate-detail-upload_note__14LIU{
    color: gray;
    font-size: 14px;
    font-weight: 400;
    
}
  

.feedback_container__3Gxl0{
    display: flex;
    flex-direction: column;
    margin:2rem auto;
    width: 60%;
}

body{
    background-color: #FFFFFF;
}



.feedback_mainHeader__3yUOT{
    display: flex;
    background-color: #FFFFFF;
    height: 6rem;
    align-items: center;
}

.feedback_header__13XZV{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 1rem;
    margin:0 2%;
    padding: 2rem;
}

.feedback_userProfile__39UVh{
    display: flex;
    flex-direction: row;
    grid-gap:2rem;
    gap:2rem;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 3px solid #e5e5e5;
    margin-bottom: 2rem;
    justify-content: space-between;
}
.feedback_userProfile__39UVh div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap:1rem;
    gap:1rem;
}

.feedback_userProfile__39UVh div div{
    display: flex;
    flex-direction: column;
    grid-gap:0;
    gap:0;
}

.feedback_userProfile__39UVh img{
    width:69px;
    height:69px;
    border-radius: 50%;
}

.feedback_userProfile__39UVh span{
    color: #6F6F6F;
    font-size: 16px;
}

.feedback_userProfile__39UVh h1{
    font-size: 32px;
    font-weight: bold;
}

.feedback_basicInfo__2wSx2{
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
    flex-wrap: nowrap;
    justify-content: space-between;
 
}


.feedback_basicInfo__2wSx2 h4{
    font-size: 16px;
    font-weight: 700;
}

.feedback_basicInfo__2wSx2 span{
    font-size: 16px;
    font-weight: 500;
}

.feedback_basicInfo__2wSx2 div div{
    display: flex;
    flex-direction: row;
    grid-gap:0.7rem;
    gap:0.7rem;
}

.feedback_basicInfo__2wSx2 div div div{
    grid-gap:0.9rem;
    gap:0.9rem;
}

.feedback_mainHeader__3yUOT img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

.feedback_formControl__2STyn{
    max-height:600px;
    overflow-y: auto;
    padding: 0;
    width: 97%;
    height:80vh !important;
    margin: 1rem auto;
}

.feedback_formControl__2STyn::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
   
  .feedback_formControl__2STyn::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .feedback_formControl__2STyn::-webkit-scrollbar-thumb {
    background-color: rgb(23, 20, 20);
    border-radius: 10px;
    height:0.2rem;
  }

/* .formControl::-webkit-scrollbar{
    display: none;
} */

.feedback_wrapper__2gcDV{
    display: flex;
    flex-direction: column;
   
    padding: 2rem;
    border-radius: 1rem;
    background-color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
}

.feedback_wrapper__2gcDV input{
    height:2.5rem;
    border-radius: 0.75rem;
    border: 2px solid #e5e5e5;
}

.feedback_buttonDiv__iLiH4{
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    grid-gap:1rem;
    gap:1rem;
}

.feedback_buttonDiv__iLiH4 button{
    width:9rem;
    height:3rem;
    border-radius: 0.25rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    background-color: #4DAAFF;
    border-radius: 8px;
}

.feedback_attachButton__2wsF5{
    width:10rem;
    height:3rem;
    border-radius: 0.75rem;
    border: 2px solid #e5e5e5;
   margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;   
}

.feedback_options__3zSrk{
    display: flex;
    flex-direction: column;
    grid-gap:1rem;
    gap:1rem;
}

.feedback_options__3zSrk div{
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
   height: 2.5rem;
    border:2px solid #e5e5e5;
    border-radius: 0.75rem;
}

.feedback_rate__3HYTc{
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    height: 2.5rem;
    border:2px solid #e5e5e5;
   border-radius: 0.75rem;
}


.feedback_canFeedback__3svVL{
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    margin-inline: 1rem;
    grid-gap:2rem;
    gap:2rem;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 2px 2px 24px #ACB1B75C;;
  }

  .feedback_canFeedback__3svVL>*:nth-child(1){
    display: flex;
    padding: 1rem;
    flex: 1 1 30%;
  }
  .feedback_canFeedback__3svVL>*:nth-child(1) img{
    width: 100%;
    height: 100%;
  }
 
    

  .feedback_canFeedback__3svVL>*:nth-child(2) h1{
    font-weight: bold;
    font-size: 1.5rem;
  }

  .feedback_canFeedback__3svVL>*:nth-child(2) span{
    font-weight: 600;
    color: gray;
  }

  .feedback_canFeedback__3svVL>*:nth-child(2)
  {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }






@media (max-width: 1000px) {
    body {
      background-color:#ffffff;
    }

    .feedback_formControl__2STyn::-webkit-scrollbar {
        width: 0.2rem;
       
      }
       
      .feedback_formControl__2STyn::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
      }
       
      .feedback_formControl__2STyn::-webkit-scrollbar-thumb {
        background-color: rgb(199, 197, 197);
        border-radius: 10px;
        max-height: 10px;
        
      }
      .feedback_formControl__2STyn{
        overflow-y:auto
      }


    .feedback_mainHeader__3yUOT{
        display: flex;
        background-color: #FFFFFF;
        height: 3rem;
        align-items: center;
        margin:0;
    }

    .feedback_header__13XZV{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin:0 auto;
        width: 100%;
        padding:0.5rem;
        border-radius: 0;
    }
    
    .feedback_mainHeader__3yUOT img{
        width: 5.5rem;
        height:1.5rem;
        margin-left: 2rem;
    }

    .feedback_userProfile__39UVh{
        display: flex;
        flex-direction: row;
        grid-gap:2rem;
        gap:2rem;
        align-items: center;
        padding-bottom: 0.3rem;
        border-bottom: 1.5px solid #e5e5e5;
        margin-bottom: 0.3rem;
        justify-content: space-between;
    }
    .feedback_userProfile__39UVh div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap:0.5rem;
        gap:0.5rem;
    }
    
    .feedback_userProfile__39UVh div div{
        display: flex;
        flex-direction: column;
        grid-gap:0;
        gap:0;
        height: 3.5rem;
    }
   
    
    .feedback_userProfile__39UVh h1{
        font-size: 20px;
        font-weight: 700;
        font-family: "Open Sans";
        margin: 0;
        padding: 0;
    }
    
    .feedback_userProfile__39UVh img{
        width:3rem;
        height:3rem;
        border-radius: 50%;
    }
 
    
    .feedback_userProfile__39UVh span{
        color: #6F6F6F;
        font-size: 12px;
    }

    .feedback_progess__Qz4Xd{
        width: 40px;
        height: 40px;
        margin-right: 3rem;
    }

    .feedback_basicInfo__2wSx2{
        grid-gap:0;
        gap:0;
        display: flex;
        padding: 0.5rem;
        overflow-x: scroll;
        flex-direction: column;
        max-width: 95%;
       margin: 0 0 0 0.5rem;
    }
  
    .feedback_basicInfo__2wSx2 h4{
        font-size: 14px;
        font-weight: 700;
        color: #000000;
    }

    .feedback_basicInfo__2wSx2 span{
        font-size: 14px;
    }
    .feedback_selectIcon__2r-QW{
        width:0.9rem;
        height:0.9rem;
        margin: 0 auto;
    }

    .feedback_canFeedback__3svVL{
        margin-inline:0.5rem;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .feedback_canFeedback__3svVL>*:nth-child(2) h1{
        font-size: 1.2rem;
        font-weight: 700;
    }

    .feedback_canFeedback__3svVL>*:nth-child(2) span{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .feedback_container__3Gxl0{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin:0.5rem auto;
        font-size: 14px;
        font-weight: 500;
        color: #000000;  
    }

    .feedback_options__3zSrk div{
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
       height: 2rem;
        border:1px solid #e5e5e5;
        border-radius: 0.5rem;
    }

    .feedback_rate__3HYTc{
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        height: 2rem;
        border:1px solid #e5e5e5;
        border-radius: 0.5rem;
    }

    .feedback_attachButton__2wsF5{
        width:8rem;
        height:2rem;
        border-radius: 0.25rem;
       margin-top: 1rem;
        font-size: 14px;
        font-weight: 400;   
        margin-bottom: 1rem;
    }

    .feedback_wrapper__2gcDV input{
        height:2rem;
        border-radius: 0.5rem;
    }

    .feedback_buttonDiv__iLiH4 button{
        width:6rem;
        height:2.1rem;
        border-radius: 1rem;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        background-color: #4DAAFF;
    }

    .feedback_wrapper__2gcDV{
        display: flex;
        flex-direction: column;
        margin:0;
        padding: 0 0.5rem;
        border-radius: 0;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
    }
  }

.previousFeedback_pageControl__32xFY{
    max-height:22rem;
    overflow-y: auto;
    /* padding: 2.5rem; */
    width: 97%;
    /* height:80vh !important; */
    /* margin: 1rem auto; */
    margin: 1.5rem;
}

.previousFeedback_pageControl__32xFY::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
   
.previousFeedback_pageControl__32xFY::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
.previousFeedback_pageControl__32xFY::-webkit-scrollbar-thumb {
    background-color: rgb(206, 205, 205);
    border-radius: 10px;
    height:0.2rem;
  }
.fresherRegistration_mainHeader__2jdi5{
    display: flex;
    background-color: #FFFFFF;
    height: 5rem;
    align-items: center;
}

.fresherRegistration_mainHeader__2jdi5 img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

body{
    background-color: #F8F8F8;
}

.fresherRegistration_wrapper__3KwcP{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1% auto;
    box-shadow: 0px 1px 7px rgb(200, 197, 197);
}

.fresherRegistration_wrapper__3KwcP>*:nth-child(1){
flex: 1 1 25%;
}

.fresherRegistration_wrapper__3KwcP>*:nth-child(1) img{
    height: 100%;
    width: 100%;
}

.fresherRegistration_wrapper__3KwcP>*:nth-child(2){
    flex: 1 1 45%;
    background-color: #ffffff;
    }

.fresherRegistration_container__2yLsh{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 710px;
    overflow-y: scroll;
    padding: 1% 2%;
}

.fresherRegistration_container__2yLsh::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .fresherRegistration_container__2yLsh::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .fresherRegistration_container__2yLsh::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .fresherRegistration_container__2yLsh::-webkit-scrollbar-thumb {
    background-color: rgb(157, 151, 151);
    border-radius: 10px;
    height:0.5rem;
  }

.fresherRegistration_container__2yLsh  h3{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.fresherRegistration_container__2yLsh >*:nth-child(1){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1%;
}

.fresherRegistration_container__2yLsh >*:nth-child(1) h1{
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.fresherRegistration_container__2yLsh >*:nth-child(1) span{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: #636363;
}

.fresherRegistration_container__2yLsh >*:nth-child(2){
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}


.fresherRegistration_uploadPhoto__3kdUq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    font-weight: 650;
}

.fresherRegistration_uploadPhoto__3kdUq>*>*:nth-child(1)>*:nth-child(1)>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
}

.fresherRegistration_profileImg__IBWmY{ 
    width: 11rem;
    height: 11rem;
    border: 2px solid #2580EC;
    border-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #EAF5FF;
}

.fresherRegistration_profileImg__IBWmY img{
    width: 95%;
    height: 95%;
    border-radius: 50%;
}



.fresherRegistration_uploadPhoto__3kdUq>*>*:nth-child(1)>*:nth-child(1)>* span{
    margin-top: -2rem;
}

.fresherRegistration_uploadPhoto__3kdUq>*>*:nth-child(1) button{
    display: flex;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left:30%;
    justify-content: center;
    bottom: 25%;
    border: 0;
    align-items: center;
    box-shadow: 0 1px 5px gray;
}

.fresherRegistration_uploadPhoto__3kdUq>*>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.fresherRegistration_attachResume__ZHPJG{
    display: flex;
    border: 1.2px solid #9c979729;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    background-color: #EAF5FF;
}
.fresherRegistration_attachResume__ZHPJG>*:nth-child(1){
   height: 5rem;
}

.fresherRegistration_drag__1ViMm{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;   
}

.fresherRegistration_attachResume__ZHPJG span{
    text-align: center;
    font-size: 13px;
    font-weight: 680;
}

.fresherRegistration_attachResume__ZHPJG button{
    background-color: #4DAAFF;
    color: #ffffff;
    width: 6.8rem;
    height: 1.8rem;
    border-radius: 1rem;
    border: 0;
    font-size: 13px;
    box-shadow: none;
}

.fresherRegistration_container__2yLsh >*:nth-child(4){
    display: flex;
    flex-direction: column;
}

.fresherRegistration_container__2yLsh >*:nth-child(4) span{
    font-weight: 600;
}

.fresherRegistration_container__2yLsh >*:nth-child(4) input {
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-shadow: none;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(17)>*>*:nth-child(2)>*>*>*{
  
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-shadow: none;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(16){
   display: flex;
   flex-direction: row;
   grid-gap: 0.5rem;
   gap: 0.5rem;
   margin-bottom: 1rem;
   align-items: center;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(17)>*:nth-child(1)>*>*{
    border: 1.5px solid #E4E4E4;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 1 4px solid gray;
}


.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(3)>*:nth-child(2){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 14px;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



.fresherRegistration_upload__38u6b{
    display: flex;
    flex-direction: column;
}

.fresherRegistration_upload__38u6b>*:nth-child(1){
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: #EFEEF8;
    display: flex;
    justify-content: center;
    align-items: center;
   z-index: 0;
   box-sizing: content-box;
   border: 1.5px solid #2580EC ;
}

.fresherRegistration_upload__38u6b>*:nth-child(1) >*:nth-child(1)
{
    z-index: 0;
    margin: 3 auto;
    margin: 0 0 0 25%;
}

.fresherRegistration_upload__38u6b>*:nth-child(1) button{
    z-index: 10;
    position: relative;
   right: 18%;
   top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    width: 2.2rem;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;  
}

.fresherRegistration_upload__38u6b>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.fresherRegistration_upload__38u6b>*:nth-child(2){
    margin-top: 5%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.fresherRegistration_upload__38u6b>*:nth-child(2)>*:nth-child(1){
    font-weight: bold;
}

.fresherRegistration_upload__38u6b>*:nth-child(2)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
}

.fresherRegistration_upload__38u6b>*:nth-child(2)>*:nth-child(2) span{
    color:#2580EC ;
    font-weight: 700;
}

.fresherRegistration_submitBtn__rlJW2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.fresherRegistration_submitBtn__rlJW2 button{
    background-color: #2580EC;
    font-weight:700 ;
    width: 7.5rem;
    border-radius: 1rem;
    height: 2.2rem;
    color: #ffffff;
    border: 0;
    margin: 1rem 0;
    box-shadow: none;
}

.fresherRegistration_submitBtn__rlJW2 button:hover{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.fresherRegistration_submitBtn__rlJW2 button:active{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.fresherRegistration_submitBtn__rlJW2 button:focus{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6) input{
    border: none;
    height: 2.8rem;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6) button{
    color:#1089FF ;
    font-style: italic;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6) {
 margin-bottom: 0.3rem;
}

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(7) {
    margin-bottom: 1rem;
    color: gray;
    font-size: 14px;
    font-weight: 500;
   }

.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    height: 3rem;
    flex-wrap: nowrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-sizing: border-box;
 }


 .fresherRegistration_verified__3Cs-Q{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-right: 0.5rem;
}

.fresherRegistration_verified__3Cs-Q>*:nth-child(1){
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

@media (max-width: 996px){
    .fresherRegistration_wrapper__3KwcP{
        width: 100%;
        box-shadow: none;
    }
    .fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6) input{
        height: 1.8rem;
    }
    .fresherRegistration_container__2yLsh >*:nth-child(4) input {
        height: 2rem;
    }  
.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(3)>*:nth-child(2){
    height: 2rem;
}
.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    box-sizing: border-box;
 }
 .fresherRegistration_container__2yLsh >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
}
.expRegistration_mainHeader__3QUtX{
    display: flex;
    background-color: #FFFFFF;
    height: 5rem;
    align-items: center;
}

.expRegistration_mainHeader__3QUtX img{
    width: 8.5rem;
    height:2.5rem;
    margin-left: 4rem;
}

body{
    background-color: #F8F8F8;
}

.expRegistration_wrapper__1qIN8{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1% auto;
    box-shadow: 0px 1px 7px rgb(200, 197, 197);
}
.expRegistration_wrapperRes__2QCKs{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 1% auto;
    box-shadow: 0px 1px 7px rgb(200, 197, 197);
}

.expRegistration_wrapper__1qIN8>*:nth-child(1){
flex: 1 1 25%;
}

.expRegistration_wrapper__1qIN8>*:nth-child(1) img{
    height: 100%;
    width: 100%;
}

.expRegistration_wrapper__1qIN8>*:nth-child(2){
    flex: 1 1 45%;
    background-color: #ffffff;
    }

.expRegistration_container__xX0XI{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 710px;
    overflow-y: scroll;
    padding: 1% 2%;
}
.expRegistration_containerRes__3CXVg{
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 710px;
    overflow-y: scroll;
    padding: 1% 2%;
}
.expRegistration_container__xX0XI::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .expRegistration_container__xX0XI::-webkit-scrollbar {
    height: 0.5rem;
  }
   
  .expRegistration_container__xX0XI::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  .expRegistration_container__xX0XI::-webkit-scrollbar-thumb {
    background-color: rgb(157, 151, 151);
    border-radius: 10px;
    height:0.5rem;
  }

.expRegistration_container__xX0XI  h3{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.expRegistration_container__xX0XI >*:nth-child(1){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    margin-bottom: 1%;
}

.expRegistration_container__xX0XI >*:nth-child(1) h1{
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.expRegistration_container__xX0XI >*:nth-child(1) span{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: #636363;
}

.expRegistration_container__xX0XI >*:nth-child(2){
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}


.expRegistration_uploadPhoto__1IT6U{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    font-weight: 650;
}

.expRegistration_uploadPhoto__1IT6U>*>*:nth-child(1)>*:nth-child(1)>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
}

.expRegistration_uploadPhoto__1IT6U>*>*:nth-child(1)>*:nth-child(1)>* div{
    
    width: 11rem;
    height: 11rem;
    border: 2px solid #2580EC;
    border-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #EAF5FF;
}

.expRegistration_uploadPhoto__1IT6U>*>*:nth-child(1)>*:nth-child(1)>* span{
    margin-top: -2rem;
}

.expRegistration_uploadPhoto__1IT6U>*>*:nth-child(1) button{
    display: flex;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left:30%;
    justify-content: center;
    bottom: 25%;
    border: 0;
    align-items: center;
    box-shadow: 0 1px 5px gray;
}

.expRegistration_uploadPhoto__1IT6U>*>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.expRegistration_attachResume__248ct{
    display: flex;
    border: 1.2px solid #9c979729;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    background-color: #EAF5FF;
}
.expRegistration_attachResume__248ct>*:nth-child(1){
   height: 5rem;
}

.expRegistration_drag__2XL_R{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; 
}

.expRegistration_attachResume__248ct span{
    text-align: center;
    font-size: 13px;
    font-weight: 680;
}

.expRegistration_attachResume__248ct button{
    background-color: #4DAAFF;
    color: #ffffff;
    width: 6.8rem;
    height: 1.8rem;
    border-radius: 1rem;
    border: 0;
    font-size: 13px;
    box-shadow: none;
}

.expRegistration_container__xX0XI >*:nth-child(4){
    display: flex;
    flex-direction: column;
}

.expRegistration_container__xX0XI >*:nth-child(4) span{
    font-weight: 600;
}

.expRegistration_container__xX0XI >*:nth-child(4) input {
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-shadow: none;
}

.expRegistration_upload__34a3H{
    display: flex;
    flex-direction: column;
}

.expRegistration_upload__34a3H>*:nth-child(1){
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: #EFEEF8;
    display: flex;
    justify-content: center;
    align-items: center;
   z-index: 0;
   box-sizing: content-box;
   border: 1.5px solid #2580EC ;
}
.expRegistration_profileImg__5rhOw{ 
    width: 11rem;
    height: 11rem;
    border: 2px solid #2580EC;
    border-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #EAF5FF;
}

.expRegistration_profileImg__5rhOw img{
    width: 95%;
    height: 95%;
    border-radius: 50%;
}

.expRegistration_upload__34a3H>*:nth-child(1) >*:nth-child(1)
{
    z-index: 0;
    margin: 3 auto;
    margin: 0 0 0 25%;
}

.expRegistration_upload__34a3H>*:nth-child(1) button{
    z-index: 10;
    position: relative;
   right: 18%;
   top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    width: 2.2rem;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;  
}

.expRegistration_upload__34a3H>*:nth-child(1) button img{
    width: 1rem;
    height: 1rem;
}

.expRegistration_upload__34a3H>*:nth-child(2){
    margin-top: 5%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.expRegistration_upload__34a3H>*:nth-child(2)>*:nth-child(1){
    font-weight: bold;
}

.expRegistration_upload__34a3H>*:nth-child(2)>*:nth-child(2){
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
}

.expRegistration_upload__34a3H>*:nth-child(2)>*:nth-child(2) span{
    color:#2580EC ;
    font-weight: 700;
}

.expRegistration_submitBtn__2vctu{
    display: flex;
    justify-content: center;
    align-items: center;
}

.expRegistration_submitBtn__2vctu button{
    background-color: #2580EC;
    font-weight:700 ;
    width: 7.5rem;
    border-radius: 1rem;
    height: 2.2rem;
    color: #ffffff;
    border: 0;
    margin: 1rem 0;
    box-shadow: none;
}

.expRegistration_submitBtn__2vctu button:hover{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.expRegistration_submitBtn__2vctu button:active{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.expRegistration_submitBtn__2vctu button:focus{
    background-color: #2580EC;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
}


.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6){
    margin-bottom: 0.5rem;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(11){
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4rem;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(11)>*:nth-child(1){
    color: red;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(12){
    display: flex;
    flex-direction: row;
    grid-gap:1rem;
    gap:1rem;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(12)>*{
    flex: 1 1 50%;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6) input{
    border: none;
    height: 2.8rem;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6) button{
    color:#1089FF ;
    font-style: italic;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    height: 3rem;
    flex-wrap: nowrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    box-sizing: border-box;
 }

 .expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(7) {
    margin-bottom: 1rem;
    color: gray;
    font-size: 14px;
    font-weight: 500;
   }


 .expRegistration_verified__2dDsC{
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    
}

.expRegistration_verified__2dDsC>*:nth-child(2){
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

 .expRegistration_container__xX0XI >*:nth-child(4)>*:nth-last-child(2)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 3rem;
    background-color: #FAFAFA;
    border: 1.5px solid #E4E4E4;
    display: flex;
    box-shadow: none;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-last-child(2)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 996px){
    .expRegistration_wrapper__1qIN8{
        width: 100%;
        box-shadow: none;
    }
    .expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6) input{
        height: 1.8rem;
    }
    .expRegistration_container__xX0XI >*:nth-child(4) input {
        height: 2rem;
    }  
.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(3)>*:nth-child(2){
    height: 2rem;
}
.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(4)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}
.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(6)>*:nth-child(1)>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    box-sizing: border-box;
 }
 .expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*>*:nth-child(1){
    height: 2rem;
}

.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(1)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 2rem;
}
.expRegistration_container__xX0XI >*:nth-child(4)>*:nth-child(18)>*>*:nth-child(2)>*:nth-child(1)>*:nth-child(1)>*{
    height: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.resetPassword_recovery__161-_{
    display: flex;
    height: 100vh;
}
.resetPassword_recovery__161-_>*:nth-child(1){
  flex: 1 1 60%;
}
.resetPassword_recovery__161-_>*:nth-child(2){
    flex: 1 1 60%;
    padding: 10%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
  }

  .resetPassword_recovery__161-_>*:nth-child(2)>*:nth-child(2){
    background-color: #ffffff;
    outline: 1.2px solid rgb(188, 187, 187);
    padding: 2rem;
    border-radius: 5px;
  }
@media(max-width:700px){
    .resetPassword_recovery__161-_{
        flex-direction: column;
        padding: 0.6rem;
        background-color: #ffffff;
        height: 100%;
    }
    .resetPassword_recovery__161-_>*:nth-child(2){
        padding: 0;
        grid-gap: 0rem;
        gap: 0rem;
      }
      .resetPassword_recovery__161-_>*:nth-child(2)>*:nth-child(2){
        outline: none;
        padding: 0;
        border-radius:0;
      }
    .resetPassword_recovery__161-_>*:nth-child(1){
     min-height: 15rem;
      }
      .resetPassword_recovery__161-_>*:nth-child(2)>*:nth-child(1){
        margin:1rem  auto;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 2rem;
      }
      .resetPassword_recovery__161-_>*:nth-child(2)>*:nth-child(1) img{
        width: 100%;
        height: 100%;
      }   
      .resetPassword_moveIcon__tclEQ{
        background-color: rgba(0, 102, 255, 0.829);
      }
}
.thankYou_container___wsvT{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.thankYou_wrapper__301D_{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% auto;
    padding: 5%;
    background-color: #ffffff;
    box-shadow: 2px 2px 24px #ACB1B75C;
    border-radius: 0.8rem;
}
.thankYou_wrapper__301D_>*{
    text-align: center;
}

@media (max-width:800px){
    .thankYou_wrapper__301D_{
       margin: 0.5rem;
        background-color: #ffffff;
        box-shadow:none;
    }

}

