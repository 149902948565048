.dashboardPan{
    display: flex;
    flex-direction: column;
    height: 100%;
   gap: 0.7rem;
}

.dashboardPan>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap:1rem;
}

.dashboardPan>*:nth-child(1) h3{
    font-weight: 800;
}

.dashboardPan>*:nth-child(1) span{
    font-size: 13px;
}
.dashboardPan>*:nth-child(1) h2{
    font-weight: 400;
    color: white;
}

.dashboardPan>*:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5%;
}

.dashboardPan>*:nth-child(2)>*:nth-child(1){
    flex: 1 1 15%;
    /* max-width: 17rem; */
}

.dashboardPan>*:nth-child(2)>*:nth-child(2){
    flex: 1 1 20%;
    margin-right: 5%;
   
}
.dashboardPan>*:nth-child(2)>*:nth-child(1)>*>*:nth-child(1){
    max-width: 15.5rem;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.dashboardPan>*:nth-child(2)>*:nth-child(2)>*>*:nth-child(1){
    max-width: 19.5rem;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dashboardPan>*:nth-child(2)>*:nth-child(3){
    flex: 1 1 20%;
}

.dashInfo{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 12.5rem; 
    grid-auto-rows: 24rem;
    grid-gap: 2rem;
    /* overflow: auto; */
}

.dashInfo>*{
    /* overflow-y: scroll; */
    /* min-height: 70vh; */
    background-color: #FCFCFC;
   border: 1.2px solid rgb(241, 238, 238);
}

.dashInfo>*:nth-child(1){
    grid-column: 1/3;
    margin-bottom: 3rem;
    min-height: 12.5rem; 
    background-color: #FCFCFC;
}

.dashInfo>*:nth-child(3){
    grid-column: 1/-1;
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    background-color: #ffffff;
    padding: 1rem;
    height: 27rem;   
}

.dashInfo>*:nth-child(3)>*:nth-child(1){
    flex: 1 1 75%;
    max-width: 52rem;
    overflow-y: auto;
}

.dashInfo>*:nth-child(3)>*:nth-child(1)>*:nth-child(2){
    max-width: 52rem;
    overflow-x: auto;
}

*::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  *::-webkit-scrollbar {
    height: 0.3rem;
  }
   
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(234, 227, 227, 0.3);
  }
   
  *::-webkit-scrollbar-thumb {
    background-color: rgb(160, 156, 156);
    border-radius: 10px;
    height:0.3rem;
  }

.dashInfo>*:nth-child(3)>*:nth-child(1)>*:nth-child(2)>*>*>*{
    min-width: 8.5rem;
}
       
.dashInfo>*:nth-child(3)>*:nth-child(2){
    flex: 1 1 25%;
    border-left: 1.5px solid #E3E3E3;
}

.dashInfo>*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.dashInfo>*:nth-child(1)>*:nth-child(1){
    padding: 1rem;
    outline: 1px solid rgb(241, 238, 238);
    background-color: #ffffff;
}

.dashInfo>*:nth-child(1)>*:nth-child(1)>*:nth-child(1){
    font-weight: 700;
}

.dashInfo>*:nth-child(2){
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.dashInfo>*:nth-child(2)>*:nth-child(1){
    font-weight:700;
}

.summery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
    height: 9rem;
    overflow-y: auto;
}

.summery>*{
    width: 23.5%; 
    display: flex;
    margin-top: 0.4rem;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-right: 2rem; 
}

.summery>*>:nth-child(1){
    flex: 1 1 60%;
    display: flex;
    /* border: 1.5px solid #E3E3E3; */
    /* box-shadow: 0px 1px 3px rgb(205, 200, 200); */
    background-color: #FCFCFC;
    outline: 1.2px solid rgb(241, 238, 238);
    padding: 0.7rem;
    z-index: 0;
    height: 2.8rem;
    color: #161616;
    font-size: 14px;
    font-weight: 600;
}

.summery>*>:nth-child(2){
    box-shadow: 0 1px 5px gray;
    border-radius: 50%;
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 90%;
    background-color: #ffffff;
    z-index: 1;
}

.summery>*>:nth-child(2) h4{
    font-size: 1rem;
    font-weight: 700;  
}

.dashboardPan>*:nth-child(3)>*:nth-child(2){
    flex: 1 1 35%;
}

.slotInfoDes{
    display: flex;
    gap:0.5rem;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 1.2rem; 
}

.slotInfoDes>*:nth-child(1){
    font-size: 14px;
    font-weight: 700;
}

.slotInfoDes>*:nth-child(2){
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 500;
  color:#AFB2BB;
  align-items: center;
  margin-left: 3rem;
}
td{
    min-width: 8.5rem;
}

.activeSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#CADAF9;
    margin: 0.5rem;
}
.cancelledSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#dc741e;
    margin: 0.5rem;
}
.completedSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#96dc1e;
    margin: 0.5rem;
}

.feedPending{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#834a85;
    margin: 0.5rem;
}
.skippedSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#2b333d;
    margin: 0.5rem;
}

.scheduleSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#90EE90;
    margin: 0.5rem;
}

.inActiveSlot{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#F0F0F0;
    margin: 0.5rem;
}

.scheduleInterview{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#FFDEAD;
    margin: 0.5rem;
}

.completedInterview{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#3CB371;
    margin: 0.5rem;
}

.cancelledInterview{
    display: flex;
    width: 2.4rem;
    height: 1.1rem;
    background-color:#FA8072;
    margin: 0.5rem;
}

.dashboardPan table,.dashboardPan tr, .dashboardPan td{
    text-align: center;
}

.onHover{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.onHover h4{
    font-weight: 600;
}

.onHover div{
    background-color: #F2F7F2;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    min-width: 14rem;
}

.onHover div h4{
    color:#BFBFBF;
    font-size: 13px;
    font-weight: 600;
    padding: 1rem;
    margin: 0;
    padding: 0;
}

.onHover div span{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}





