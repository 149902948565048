.container{
    display: flex;
    flex-direction: column;
}

.container >*:nth-child(1){
    display: flex;
    flex-direction: row;
    gap:1rem;
    margin-bottom: 1rem;
}

.container >*:nth-child(1)>*{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    gap: 0.3rem;

}

.container >*:nth-child(1)>*>*:nth-child(1){
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #0086ff;

}

.container >*:nth-child(1)>*:nth-child(2)>*:nth-child(1){  
    background-color: red;
}

.listIem{ 
   font-size: 14px; 

}
.listIem  li{
    list-style: none;
    padding-left: 0.5rem;
    border-left: 5px solid #0086ff ;
    margin-bottom: 0.3rem;
    cursor: pointer;

}

.listIem .inactive{
    border-left: 5px solid red ;
}

.listIem li:hover{
    background-color: #caddee;
}