.header{
  font-size: 1.5rem;
  font-weight: bold;
  }
  

  .containerHome{
    display: flex;
    flex-direction: column;
    padding: 1.1% 2% 0 2%;
  }

  .tabContent{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    max-width: 80rem;
  }
  
  .tabContent>*:nth-child(1){
    flex: 1 1 100%;
    display: flex;
    width: 17rem;
  }

  .tabContent >*{
    height:2.1rem;
  }

  .tabContent button{
    background-color: #0086FF;
    color: #ffffff;
    font-size: 650;
    border: 0;
  }

  .tabContent button:hover{
    background-color: #0086FF;
    color: #ffffff;

  }


  .editBtn button{
    border: 1.5px solid #1890FF;
    width: 5.5rem;
    height: 1.6rem;
    font-size: 14px;
    font-weight: 680;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .editBtn button:focus{
    background-color: #1890FF;
    color: #ffffff;
  }

  .editBtn button:hover{
    background-color: #1890FF;
    color: #ffffff;
  }

  .title{
    font-weight: 680;
    letter-spacing: 0.5px;
    color: gray;
  }

  .spin{
    font-size: 2rem;
    margin:15% auto;
  }
