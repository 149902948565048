
.searchDiv{
  height: 2.2rem;
  width:18rem;
  border-radius: 4px;
  gap:0.3rem;
  }
  
  /* .searchDiv input{
    width:30rem;
    min-height: 30px;
    border:0;
  }
  
  .searchDiv input:active,
  .searchDiv input:focus{
   outline: none;
   border:0;
  }
  
  .searchDiv img{
    width:18px;
    height:18px;
    margin-left: 1rem;
  } */

  .table{
    overflow: scroll;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    
  }
  
   .table::-webkit-scrollbar {
    display: none;
  }


  .rowStyle{
    background-color: white;
    font-family: 'Open Sans';
    font-size: 16px;
  
  }

  .tableStyle{
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
}

.headerName{
  flex-direction: row;
  flex-wrap: nowrap;
  display:flex;
  flex-direction:row;
}

.headerName span{
 
  /* font-size: 1rem; */
  font-weight: bold;
  /* color: #6f6f6f; */
}
  