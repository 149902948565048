.scroll{
    overflow-y: scroll;
    max-height:19rem ;

}

.scroll::-webkit-scrollbar{
    display: none;
}

.tableContent{
    font-weight: 600;
}

.headerName{
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
  }
  
  .nameMargin{
    margin-left: 0.9rem;
  }
  
  .headerName h1{
    font-size: 1rem;
    font-weight: bold;
    color: #6f6f6f;
  }
  
  .searchRow{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .searchRow >*:nth-child(1)
  {
    flex: 1 1 15%;
  }

  
.searchDiv2{
  /* outline: #d1cfcf; */
  border: 0;
  border-radius: 5px;
}

.searchDiv2>*:nth-child(1)>*:nth-child(2){
  padding-left: 0.5rem;
  border-radius: 5px;
  max-height: 1.7rem;
} 

  .searchRow >*:nth-child(2)
  {
    flex: 1 1 70%;
    justify-content: right;
    display: flex;
    align-items: center;
    gap: 1rem;
  }