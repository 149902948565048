.container {
    width: 100%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;
  }
  .container::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  .container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(88, 87, 87, 0);
  }
  
  .container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    height: 1rem;
  }
  
  .filterBlock {
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
  }
  
  .chartBlock {
    width: 100%;
    max-height: 100vh;
    display: flex;
  }
  
  .summaryBlock {
    width: 100%;
    max-height: 50vh;
    padding: 1rem;
    background: #ffffff;
  }
  .canType{
    display: grid;
    grid-auto-flow: column;
    justify-content: left;
    align-content: center;
    align-items: center;
    gap:0.5rem;
    margin-top: 0.7rem;
    font-size: 14px;
    font-weight: 600;
  }

  .canType>*:nth-child(1),.canType>*:nth-child(3){
    width: 12px;
    height: 12px;
    border: 1.5px solid gray ;
    border-radius: 50%;
  }

  .canType>*:nth-child(2){
    margin-right: 0.8rem;
  }

  .canType>*:nth-child(1).activeCircle,  .canType>*:nth-child(3).activeCircle{
    border: 1.5px solid #286eaa;
    padding: 2px;
    background-color: #0086FF;
  }

  .textColor{
    color: #0086FF;
  }

  .fresherSearch{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    gap: 1rem;
  }

  .fresherSearch>*{
    flex: 1 1 18%;
   
  }

  .fresherSearch>*:nth-child(5){
    flex: 1 1 25%;
  }


  .dropdown>*:nth-child(2)>*>*:nth-child(1),.dropdown>*:nth-child(3)>*>*:nth-child(1),.dropdown>*:nth-child(4)>*>*:nth-child(1),.fresherSearch>*:nth-child(2)>*>*:nth-child(1){
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: auto;
    max-height: 5rem;
    max-width: 10rem;
  }
  .modalDesign>*{
    border-radius: 5px;
    background-color: #F8F8F8;
  }

  .modalView{
    display:flex;
    flex-direction: column;
    padding: 1.2em;
  }
  
  .modalView >*:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  
  .modalView >*:nth-child(1) h2{
    font-weight: bold;
  }

  .radio{
    margin-top: 0.9rem;
  }

  .radio>*>:nth-child(1){
    font-weight: 600;
  }
  .radio>*>:nth-child(2){
    font-weight: 600;
  }